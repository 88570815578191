import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import actions from "@/actions";
import Modal from "@/components/Modal";
import { Spacer } from "@/components/common";
import { CheckBox } from "@/components/common/CheckBox";
import { SubmitButton } from "@/components/common/SubmitButton";
import { ToggleButtonGroup } from "@/components/common/ToggleButtonGroup";
import { MatrixColumnKey } from "@/models/matrix";
import { ConfigType, ScreenName } from "@/models/screenConfig";
import { RootState } from "@/reducers/types";

type Props = {
  onClose: () => void;
  isChiba: boolean;
  onInvalidRowsFlgChanged: (flg: boolean) => void;
};

export const MatrixScreenConfigModal = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { configColumns, configHideInvalidRows } = useSelector((state: RootState) => state.matrix);

  const [columns, setColumns] = useState<Map<string, boolean>>(new Map(Object.entries(configColumns ?? {})));
  const [hideInvalidRows, setHideInvalidRows] = useState<boolean>(configHideInvalidRows);

  useEffect(() => {
    // デフォルトは設定なし=すべて表示
    !configColumns && handleCheckAll();
  }, []);

  const handleChange = (key, checked) => {
    const v = new Map(columns);
    v.set(key, checked);
    setColumns(v);
  };

  const filterChiba = (key): boolean =>
    props.isChiba || (key !== MatrixColumnKey.SpecialWork && key !== MatrixColumnKey.Protection);

  const handleCheckAll = () => {
    setColumns(
      new Map(
        Object.values(MatrixColumnKey)
          .filter((key) => filterChiba(key))
          .map((key) => [key, true])
      )
    );
  };

  const handleUncheckAll = () => {
    setColumns(
      new Map(
        Object.values(MatrixColumnKey)
          .filter((key) => key !== MatrixColumnKey.Item && filterChiba(key))
          .map((key) => [key, false])
      )
    );
  };

  const handleSubmit = () => {
    const columnsParam: { [key: string]: 0 | 1 }[] = [];
    const values = new Map(columns);
    values.set(MatrixColumnKey.Item, true);
    values.forEach((value, key) => columnsParam.push({ [key]: value ? 1 : 0 }));
    const params: { columns: { [key: string]: 0 | 1 }[]; hide_invalid_rows: 0 | 1 } = {
      columns: columnsParam,
      hide_invalid_rows: hideInvalidRows ? 1 : 0,
    };
    dispatch(
      actions.screenConfig.updateScreenConfig(
        {
          screen_name: ScreenName.MATRIX,
          config_type: ConfigType.COLUMN,
          config: JSON.stringify(params),
        },
        (response) => {
          if (configHideInvalidRows !== hideInvalidRows) {
            props.onInvalidRowsFlgChanged(hideInvalidRows);
          }
          dispatch(actions.matrix.setFetchedColumnConfig(response));
          props.onClose();
        }
      )
    );
  };

  return (
    <Modal title={t("matrix_screen_config")} closeHandler={props.onClose}>
      <div className="modal-body">
        <div>
          <div className="text-center">{t("matrix_screen_config_description")}</div>
          <div className="mt-[12px] mx-[36px]">
            <div
              className="grid gap-x-[12px] gap-y-[16px] my-[16px]"
              style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr" }}
            >
              <CheckBox
                checked={!!columns.get(MatrixColumnKey.No)}
                label="No"
                onChange={(checked) => handleChange(MatrixColumnKey.No, checked)}
              />
              <CheckBox
                checked={!!columns.get(MatrixColumnKey.DeliveryFlg)}
                label={t("delivery")}
                onChange={(checked) => handleChange(MatrixColumnKey.DeliveryFlg, checked)}
              />
              <CheckBox
                checked={!!columns.get(MatrixColumnKey.ItemComplete)}
                label={t("complete_process")}
                onChange={(checked) => handleChange(MatrixColumnKey.ItemComplete, checked)}
              />
              <CheckBox
                checked={!!columns.get(MatrixColumnKey.Totaltest)}
                label={t("airtight_preparation")}
                onChange={(checked) => handleChange(MatrixColumnKey.Totaltest, checked)}
              />
              <CheckBox
                checked={!!columns.get(MatrixColumnKey.Area)}
                label={t("area")}
                onChange={(checked) => handleChange(MatrixColumnKey.Area, checked)}
              />
              <CheckBox
                checked={!!columns.get(MatrixColumnKey.Device)}
                label={t("device")}
                onChange={(checked) => handleChange(MatrixColumnKey.Device, checked)}
              />
              <CheckBox
                checked={!!columns.get(MatrixColumnKey.PrimaryCharge)}
                label={t("primary_charge")}
                onChange={(checked) => handleChange(MatrixColumnKey.PrimaryCharge, checked)}
              />
              <CheckBox
                checked={!!columns.get(MatrixColumnKey.DocumentNo)}
                label={t("construction_specification") + "No"}
                onChange={(checked) => handleChange(MatrixColumnKey.DocumentNo, checked)}
              />
              <CheckBox
                checked={true} // 件名は必須
                label={`${t("title")}（${t("required")}）`}
                onChange={() => handleChange(MatrixColumnKey.Item, true)}
                disabled={true}
              />
              {props.isChiba && (
                <>
                  <CheckBox
                    checked={!!columns.get(MatrixColumnKey.SpecialWork)}
                    label={t("special_work")}
                    onChange={(checked) => handleChange(MatrixColumnKey.SpecialWork, checked)}
                  />
                  <CheckBox
                    checked={!!columns.get(MatrixColumnKey.Protection)}
                    label={t("protection")}
                    onChange={(checked) => handleChange(MatrixColumnKey.Protection, checked)}
                  />
                </>
              )}
              <CheckBox
                checked={!!columns.get(MatrixColumnKey.Regulation)}
                label={t("law")}
                onChange={(checked) => handleChange(MatrixColumnKey.Regulation, checked)}
              />
              <CheckBox
                checked={!!columns.get(MatrixColumnKey.Systems)}
                label={t("system")}
                onChange={(checked) => handleChange(MatrixColumnKey.Systems, checked)}
              />
              <CheckBox
                checked={!!columns.get(MatrixColumnKey.Note)}
                label={t("note")}
                onChange={(checked) => handleChange(MatrixColumnKey.Note, checked)}
              />
              <CheckBox
                checked={!!columns.get(MatrixColumnKey.User)}
                label={t("assignee")}
                onChange={(checked) => handleChange(MatrixColumnKey.User, checked)}
              />
            </div>
            <div className={clsx("flex justify-end my-[16px]", props.isChiba ? "" : "mt-[-32px]")}>
              <div className="flex">
                <a onClick={handleCheckAll}>{t("check_all")}</a>
                <Spacer x="12px" />
                <a onClick={handleUncheckAll}>{t("uncheck_all")}</a>
              </div>
            </div>
            <Spacer y="16px" />
            <div className="flex items-center">
              {t("hide_row_with_no_process")}
              <Spacer x="8px" />
              <ToggleButtonGroup
                value={hideInvalidRows ? "on" : "off"}
                options={[
                  { label: "ON", value: "on" },
                  { label: "OFF", value: "off" },
                ]}
                onChange={(value) => setHideInvalidRows(value === "on")}
                optionWidth="50px"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          data-test-id="button-search-panel-cancel"
          type="button"
          className="btn btn-gray"
          onClick={props.onClose}
        >
          {t("cancel")}
        </button>
        <SubmitButton onClick={handleSubmit} />
      </div>
    </Modal>
  );
};
