import moment from "moment";
import PropTypes from "prop-types";
import React, { Component, createRef } from "react";
import { withTranslation } from "react-i18next";

import AssetSearchArea from "../../containers/asset/AssetSearchAreaContainer";
import { addHeightResizeListener, removeHeightResizeListener, getTableBodyHeight } from "../../lib/common";
import * as util from "../../lib/common";
import restoreState from "../../lib/restoreState";
import storageManager from "../../lib/storageManager";
import ConfirmDialog from "../ConfirmDialog";
import Modal from "../Modal";

import AssetGridHeader from "./AssetGridHeader";
import AssetGridRow from "./AssetGridRow";

import { FooterPager } from "@/components/common/FooterPager";
import { AssetSearchParams } from "@/models/asset";

const autoload = false; // IEの二重リクエスト対策フラグ

class Asset extends Component {
  searchBox;

  constructor(props) {
    super(props);
    this.searchBox = createRef();
    const urlState = restoreState();
    const notFirst =
      storageManager.getConstructionItem("assetSearchParams") !== null || (urlState && urlState.hasQuery);
    this.state = {
      showSearch: true,
      showTable: false,
      isEmpty: false,
      tableBodyMaxHeight: window.innerHeight - 420,
      notFirst,
      heightListener: false,
      totalNum: 0,
      requestParam: [],
      downloadUrl: "",
      downloadName: "",
      downloading: false,
      visibleZipFileConfirm: false,

      currentPage: 1,
      maxPage: 0,
      limit: 20,
      start: 1,
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.showZipFileConfirm = this.showZipFileConfirm.bind(this);
    this.hideZipFileConfirm = this.hideZipFileConfirm.bind(this);
    this.showZipFileResult = this.showZipFileResult.bind(this);
    this.hideZipFileResult = this.hideZipFileResult.bind(this);
    this.handleConfirmZipFile = this.handleConfirmZipFile.bind(this);

    this.handleSearchBoxHeightChange = this.handleSearchBoxHeightChange.bind(this);
    this.isScrollable = this.isScrollable.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.fillList = this.fillList.bind(this);
  }

  componentDidMount() {
    this.resizeTimer = 0;
    window.addEventListener("resize", this.handleResize);

    const urlState = restoreState();
    const { validated } = this.props;
    if (
      validated &&
      (storageManager.getConstructionItem("assetSearchParams") !== null || (urlState && urlState.hasQuery))
    ) {
      this.handleSearch();
    }

    setTimeout(() => {
      this.handleSearchBoxHeightChange();
      addHeightResizeListener(this.searchBox.current, this.handleSearchBoxHeightChange);
    }, 500);
  }

  componentDidUpdate(prevProps) {
    const { validated } = this.props;
    if (!validated) {
      return;
    }

    const urlState = restoreState();
    const notFirst =
      storageManager.getConstructionItem("assetSearchParams") !== null || (urlState && urlState.hasQuery);
    if (!prevProps.validated && validated && notFirst) {
      this.handleSearch();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    removeHeightResizeListener(this.searchBox.current);

    this.props.revertLocalCondition();
  }

  handleSearchBoxHeightChange() {
    const maxHeight = getTableBodyHeight("asset", this.searchBox.current, this.theader);
    this.setState({ tableBodyMaxHeight: maxHeight });
  }

  handleResize() {
    if (this.resizeTimer > 0) {
      clearTimeout(this.resizeTimer);
    }

    const callback = () => {
      this.handleSearchBoxHeightChange();
      this.fillList();
    };

    this.resizeTimer = setTimeout(callback, 200);
  }

  toggleSearch() {
    const callback = () => {
      this.handleSearchBoxHeightChange();
      addHeightResizeListener(this.searchBox.current, this.handleSearchBoxHeightChange);
      this.fillList();
    };
    this.setState({ showSearch: !this.state.showSearch }, () => setTimeout(callback, 100));
  }

  showTable(callback) {
    this.setState({ showTable: true }, () => {
      if (callback !== undefined) {
        setTimeout(callback, 100);
      }
    });
  }

  isScrollable() {
    const container = this.tbody;

    if (!container) {
      return false;
    }

    const diff = container.clientHeight - container.scrollHeight;

    return diff !== 0;
  }

  fillList() {
    const { showTable, end } = this.state;

    if (!showTable || end) {
      return;
    }
  }

  handleSearch(option?: { params?: AssetSearchParams }) {
    this.setState({ isEmpty: true });

    const { categoryIds, fileName, insertStartDate, insertEndDate, uploadTarget, search } = this.props;

    const params = {
      start: 1,
      limit: this.state.limit,
      ...(option?.params || {
        categoryIds,
        fileName,
        insertStartDate,
        insertEndDate,
        uploadTarget,
      }),
    };

    if (uploadTarget.length > 0) {
      option?.params && this.props.setConditions(params);
      search(params, (data) => {
        this.setState(
          {
            start: data.length + 1,
            end: data.length < this.state.limit,
            isEmpty: data.total_num === 0,
            totalNum: data.total_num,
            requestParam: {
              category_id: params.categoryIds,
              file_name: params.fileName,
              insert_start_date: params.insertStartDate,
              insert_end_date: params.insertEndDate,
              upload_target: params.uploadTarget,
              total_num: data.total_num,
            },
            maxPage: Math.ceil(data.total_num / this.state.limit),
            currentPage: Math.ceil(params.start / this.state.limit),
          },
          () => {
            this.showTable(this.fillList);
          }
        );
      });
    }
  }

  handleClear() {
    const target = {
      categoryIds: [],
      fileName: "",
      insertStartDate: null,
      insertEndDate: null,
      uploadTarget: [1, 2, 3],
    };
    this.props.clearSearch(target);
    this.setState({ start: 1, showTable: false });

    const params = {
      categoryIds: [],
      fileName: "",
      insertStartDate: null,
      insertEndDate: null,
      uploadTarget: [1, 2, 3],
      start: 1,
      limit: this.state.limit,
    };

    this.props.search(params, (data) => {
      this.setState(
        {
          start: data.length + 1,
          end: data.length < this.state.limit,
          isEmpty: data.length === 0,
          totalNum: data.total_num,
          requestParam: {
            category_id: params.categoryIds,
            file_name: params.fileName,
            insert_start_date: params.insertStartDate,
            insert_end_date: params.insertEndDate,
            upload_target: params.uploadTarget,
            total_num: data.total_num,
          },
          maxPage: Math.ceil(data.total_num / this.state.limit),
          currentPage: Math.ceil(params.start / this.state.limit),
        },
        () => {
          this.showTable(this.fillList);
        }
      );
    });
  }

  showDropdown(name) {
    this.setState({ activeButton: name });
  }

  hideDropdown() {
    this.setState({ activeButton: "" });
  }

  handleDelete(file_id, upload_target, file_name) {
    const params = {
      file_id: file_id,
      upload_target: upload_target,
      file_name: file_name,
    };

    this.props.deleteItem(params, () => {
      this.handleSearch();
    });
  }

  download(filetype) {
    this.setState({ downloading: true });

    const { requestParam } = this.state;
    const params = {
      categoryIds: requestParam.category_id,
      fileName: requestParam.file_name,
      insertStartDate: requestParam.insert_start_date,
      insertEndDate: requestParam.insert_end_date,
      uploadTarget: requestParam.upload_target,
    };

    const fallback = () => this.setState({ downloading: false });

    const constId = util.getConstructionId();
    const userId = util.getUserId();

    this.props.download(
      params,
      filetype,
      (blob, fileName) => {
        const fname = !fileName
          ? "asset_" + userId + "_" + constId + "_" + moment().format("YYYY-MM-DD_HHmmssSSS") + ".xlsx"
          : fileName;

        this.setState({ downloadUrl: URL.createObjectURL(blob), downloadName: fname }, () => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fname);
          } else {
            const evt = util.createClickEvent();
            this.btnDownload.dispatchEvent(evt);
          }
          this.setState({ downloading: false });
        });
      },
      fallback
    );
  }

  handleSearchPager(startPos = 1) {
    this.setState({ isEmpty: true });
    const { search } = this.props;

    const { requestParam } = this.state;
    const params = {
      categoryIds: requestParam.category_id,
      fileName: requestParam.file_name,
      insertStartDate: requestParam.insert_start_date,
      insertEndDate: requestParam.insert_end_date,
      uploadTarget: requestParam.upload_target,
      start: startPos,
      limit: this.state.limit,
    };

    search(params, (data) => {
      this.setState(
        {
          isEmpty: data.list.length === 0,
          maxPage: Math.ceil(data.total_num / this.state.limit),
          currentPage: Math.ceil(params.start / this.state.limit),
        },
        () => {
          this.showTable(this.fillList);
        }
      );
    });
  }

  showZipFileConfirm() {
    this.setState({ visibleZipFileConfirm: true });
  }

  hideZipFileConfirm() {
    this.setState({ visibleZipFileConfirm: false });
  }

  showZipFileResult() {
    this.setState({ visibleZipFileResult: true });
  }

  hideZipFileResult() {
    this.setState({ visibleZipFileResult: false });
  }

  handleConfirmZipFile() {
    const { createZip, isCreate } = this.props;

    !isCreate &&
      createZip(this.state.requestParam, () => {
        this.showZipFileResult();
        this.hideZipFileConfirm();
      });
  }

  handleNextPage() {
    const _currentPage = this.state.currentPage;

    if (_currentPage < this.state.maxPage) {
      const startPos = 1 + _currentPage * this.state.limit;
      this.handleSearchPager(startPos);
    }
  }

  handlePrevPage() {
    const _currentPage = this.state.currentPage;

    if (_currentPage > 1) {
      const startPos = 1 + (_currentPage - 2) * this.state.limit;
      this.handleSearchPager(startPos);
    }
  }

  handleJumpPage(page) {
    const startPos = 1 + (page - 1) * this.state.limit;
    this.handleSearchPager(startPos);
  }

  render() {
    const { items, t, fetching, isError, downloadList } = this.props;

    const titleClassName = `toggle icon-keyboard_arrow_up ${!this.state.showSearch ? "closed" : ""}`;

    return (
      <div>
        <div className="contents asset">
          <div className="inner">
            <h1 className="page-ttl">
              {t("asset_list")}
              <span
                data-test-id="button-qr-toggle-search"
                className={titleClassName}
                onClick={() => this.toggleSearch()}
              />
            </h1>
            {this.state.showSearch && (
              <AssetSearchArea ref={this.searchBox} handleSearch={this.handleSearch} handleClear={this.handleClear} />
            )}
            <div className={`tbl-asset ${fetching ? "loading loading--list" : ""}`}>
              {this.state.isEmpty
                ? this.state.showTable && !isError && !fetching && <p className="empty-message">{t("no_data")}</p>
                : this.state.showTable &&
                  !isError && (
                    <React.Fragment>
                      <div className="tbl-top-area chiba clearfix relative">
                        <div className="tbl-top-left">
                          <div className="btn-dropdown-area" onMouseLeave={() => this.hideDropdown()}>
                            <button
                              data-test-id="button-summary-file-export"
                              className="btn btn-light-blue btn-dropdown"
                              onClick={() => this.showDropdown("list")}
                            >
                              <span className="icon icon-get_app" />
                              {t("file_export")}
                            </button>
                            {this.state.activeButton === "list" && (
                              <ul
                                className="dropdown-menu"
                                style={{ dispkay: "none" }}
                                onClick={() => this.hideDropdown()}
                              >
                                <li data-test-id="button-summary-list" onClick={() => this.download(1)}>
                                  <img src="./img/icon_xls.svg" alt="XLS" className="icon-file" />
                                  {t("table")}
                                </li>
                                <li data-test-id="button-summary-list" onClick={() => this.showZipFileConfirm()}>
                                  <img src="./img/icon_zip.svg" alt="XLS" className="icon-file" />
                                  {t("zipファイル")}
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>
                        <div className={`${this.state.downloading ? "loading-small loading-small-download" : ""}`} />
                      </div>
                      <div
                        className="tbl-area tbl-asset-header tbl-head-adjusted"
                        ref={(node) => (this.theader = node)}
                      >
                        <AssetGridHeader />
                      </div>
                      <div
                        className={`tbl-area tbl-asset-body ${!this.state.showSearch ? "is-large" : ""}`}
                        ref={(node) => (this.tbody = node)}
                        style={{ maxHeight: this.state.tableBodyMaxHeight, overflowY: "scroll" }}
                      >
                        <table className="tbl-basic tbl-data asset-table-row">
                          <tbody className="tbl-area">
                            {items.map((item, index) => (
                              <AssetGridRow
                                key={index}
                                item={item}
                                handleDelete={this.handleDelete}
                                download={downloadList}
                              />
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </React.Fragment>
                  )}
            </div>
          </div>
          <a
            className="d-n"
            download={this.state.downloadName}
            href={this.state.downloadUrl}
            ref={(node) => (this.btnDownload = node)}
          >
            download
          </a>
        </div>
        {this.state.maxPage > 0 && (
          <FooterPager
            currentPage={this.state.currentPage}
            maxPage={this.state.maxPage}
            onPrev={() => this.handlePrevPage()}
            onNext={() => this.handleNextPage()}
            onJump={(page) => this.handleJumpPage(page)}
          />
        )}
        {this.state.visibleZipFileConfirm && (
          <ConfirmDialog
            title="confirmation"
            messages={["confirm_create_asset_file"]}
            okClickHandler={this.handleConfirmZipFile}
            cancelClickHandler={this.hideZipFileConfirm}
          />
        )}
        {this.state.visibleZipFileResult && (
          <Modal title={t("output_asset")} closeHandler={this.hideZipFileResult}>
            <div className="modal-body modal-inazuma-confirm">
              <p>
                {t("output_asset_1")}
                <br />
                {t("output_asset_2")}
              </p>
              <p>
                <br />
                {t("output_asset_3")}
                <br />
                {t("output_asset_4")}
                <br />
                {t("output_asset_5")}
              </p>
            </div>
            <div className="modal-footer">
              <button
                data-test-id="button-comment-editor-cancel"
                type="button"
                className="btn btn-blue"
                onClick={this.hideZipFileResult}
              >
                {t("close")}
              </button>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

Asset.propTypes = {
  categoryIds: PropTypes.array.isRequired,
  masters: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  search: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  setConditions: PropTypes.func.isRequired,
};

export default withTranslation()(Asset);
