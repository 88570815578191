import { connect } from "react-redux";

import actions from "../../actions/index";

import AssetSearchArea from "@/components/asset/AssetSearchArea";

const mapStateToProps = (state) => {
  return {
    masters: state.construction.masters,
    categoryIds: state.asset.categoryIds,
    insertStartDate: state.asset.insertStartDate,
    insertEndDate: state.asset.insertEndDate,
    fileName: state.asset.fileName,
    uploadTarget: state.asset.uploadTarget,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCategory: (item) => dispatch(actions.asset.changeCategory(item)),
    changeFilename: (fileName) => dispatch(actions.asset.changeFilename(fileName)),
    changeUploadTarget: (uploadId) => dispatch(actions.asset.changeUploadTarget(uploadId)),
    changeDateFrom: (params, callback) => dispatch(actions.asset.changeDateFrom(params, callback)),
    changeDateTo: (params, callback) => dispatch(actions.asset.changeDateTo(params, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AssetSearchArea);
