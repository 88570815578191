export type ApprovalSearchParams = {
  areaIds?: number[];
  deviceIds?: number[];
  categoryId?: number;
  facilityManagementIds?: number[];
  constructionManagementIds?: number[];
  primaryChargeIds?: number[];
  otherIds?: number[];
  processMajorClassIds?: number[];
  scheduleEndDate?: string;
  checkpointIds?: number[];
  companyIds?: number[];
  processMiddleClassIds?: number[];
  itemText?: string;
  processText?: string;
  filter?: number[];
};

export const ApprovalSearchKey = {
  Area: "areaIds",
  Device: "deviceIds",
  Category: "categoryId",
  FacilityManagement: "facilityManagementIds",
  ConstructionManagement: "constructionManagementIds",
  PrimaryCharge: "primaryChargeIds",
  Other: "otherIds",
  ProcessMajorClass: "processMajorClassIds",
  ScheduleEndDate: "scheduleEndDate",
  Checkpoint: "checkpointIds",
  Company: "companyIds",
  ProcessMiddleClass: "processMiddleClassIds",
  ItemText: "itemText",
  ProcessText: "processText",
  NotStarted: "notStarted",
  Coming: "coming",
  Expired: "expired",
  Completed: "completed",
  PreworkCompletionReport: "preworkCompletionReport",
} as const;

export type ApprovalSearchKeyType = (typeof ApprovalSearchKey)[keyof typeof ApprovalSearchKey];
