import { connect } from "react-redux";

import actions from "../../actions";
import Import from "../../components/import/Import";

import { ImportSearchParams } from "@/models/import";

const mapStateToProps = (state) => {
  let categories = [];
  let primaryCharges = [];
  let users = [];

  if (state.construction.masters) {
    categories = state.construction.masters.categories;
    primaryCharges = state.construction.masters.primary_charges;
    users = state.construction.masters.users;
  }

  return {
    userId: state.dataimport.userId,
    categoryId: state.dataimport.categoryId,
    fileName: state.dataimport.fileName,
    fileType: state.dataimport.fileType,
    statusId: state.dataimport.statusId,
    import_start_date: state.dataimport.import_start_date,
    import_end_date: state.dataimport.import_end_date,
    items: state.dataimport.items,
    fetching: state.dataimport.fetching,
    impFileType: state.dataimport.impFileType,
    categories,
    primaryCharges,
    users,
    isError: state.dataimport.isError,
    validated: state.dataimport.validated,
    downloading: state.dataimport.downloading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    search: (params, callback) => dispatch(actions.dataimport.searchItems(params, callback)),
    importFiletype: (fileType) => dispatch(actions.dataimport.importFiletype(fileType)),
    uploadFile: (data, onProgress, onSuccess, callback) =>
      dispatch(actions.dataimport.uploadFile(data, onProgress, onSuccess, callback)),
    download: (kind, fileId, callback) => dispatch(actions.common.downloadAttachmentFile(kind, fileId, callback)),
    downloadErrors: (kind, fileIds, callback, fallback) =>
      dispatch(actions.common.downloadErrorFiles(kind, fileIds, callback, fallback)),
    downloadFormats: (filetype, callback) => dispatch(actions.dataimport.downloadFormatFiles(filetype, callback)),
    revertLocalCondition: () => dispatch(actions.dataimport.revertLocalCondition()),
    toggleDownloading: (downloading) => dispatch(actions.dataimport.toggleDownloading(downloading)),
    changeDateFrom: (dateFrom) => dispatch(actions.dataimport.changeDateFrom(dateFrom)),
    changeDateTo: (dateTo) => dispatch(actions.dataimport.changeDateTo(dateTo)),
    setConditions: (params) => dispatch(actions.dataimport.setConditions(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Import);
