import * as constants from "../constants/index";
import environments from "../environments";
import * as util from "../lib/matrix";
import restoreState from "../lib/restoreState";
import storageManager from "../lib/storageManager";
import { MatrixSearchParams } from "@/models/matrix";

export const setValidRequired = (valid) => {
  return {
    type: constants.MATRIXSEARCH_SET_VALID_REQUIRED,
    payload: valid,
  };
};

export const changeCategory = (categoryId) => ({
  type: constants.MATRIXSEARCH_CHANGE_CATEGORY,
  payload: categoryId,
});

export const changePrimaryCharge = (primaryChargeId) => ({
  type: constants.MATRIXSEARCH_CHANGE_PRIMARY_CHARGE,
  payload: primaryChargeId,
});

export const changeArea = (areaIds) => ({
  type: constants.MATRIXSEARCH_CHANGE_AREA,
  payload: areaIds,
});

export const changeDevice = (deviceIds) => ({
  type: constants.MATRIXSEARCH_CHANGE_DEVICE,
  payload: deviceIds,
});

export const changeFacilityManagement = (facilityManagementIds) => ({
  type: constants.MATRIXSEARCH_CHANGE_FACILITY_MANAGEMENT,
  payload: facilityManagementIds,
});

export const changeConstructionManagement = (constructionManagementIds) => ({
  type: constants.MATRIXSEARCH_CHANGE_CONSTRUCTION_MANAGEMENT,
  payload: constructionManagementIds,
});

export const changeOther = (otherIds) => ({
  type: constants.MATRIXSEARCH_CHANGE_OTHER,
  payload: otherIds,
});

export const changeSystem = (systemIds) => ({
  type: constants.MATRIXSEARCH_CHANGE_SYSTEM,
  payload: systemIds,
});

export const changeCompany = (companyIds) => ({
  type: constants.MATRIXSEARCH_CHANGE_COMPANY,
  payload: companyIds,
});

export const changeUser = (userIds) => ({
  type: constants.MATRIXSEARCH_CHANGE_USER,
  payload: userIds,
});

export const changeGroup = (groupIds) => ({
  type: constants.MATRIXSEARCH_CHANGE_GROUP,
  payload: groupIds,
});

export const changeProcessMiddleClass = (processMiddleClassIds) => ({
  type: constants.MATRIXSEARCH_CHANGE_MIDDLE,
  payload: processMiddleClassIds,
});

export const changeItemText = (text) => ({
  type: constants.MATRIXSEARCH_CHANGE_ITEM_TEXT,
  payload: text,
});

export const changeProcessText = (text) => ({
  type: constants.MATRIXSEARCH_CHANGE_PROCESS_TEXT,
  payload: text,
});

export const changeRegulation = (text) => ({
  type: constants.MATRIXSEARCH_CHANGE_REGULATION,
  payload: text,
});

export const changeFilter = (filterId) => ({
  type: constants.MATRIXSEARCH_CHANGE_FILTER,
  payload: filterId,
});

export const beginSearch = (params) => ({
  type: constants.MATRIXSEARCH_BEGIN_SEARCH,
  payload: params,
});

export const endSearch = (data) => ({
  type: constants.MATRIXSEARCH_END_SEARCH,
  payload: data,
});

export const revertLocalCondition = () => ({
  type: constants.MATRIXSEARCH_REVERT_CONDITION,
});

export const setConditions = (params: MatrixSearchParams) => ({
  type: constants.MATRIXSEARCH_SET_CONDITIONS,
  payload: params,
});

export const clearConditions = () => ({
  type: constants.MATRIXSEARCH_CLEAR_CONDITIONS,
});

export const searchProcesses = (params, callback) => {
  let endpoint = `${environments.API_BASE_URI}/matrix/processes`;
  endpoint += `?category_id=${params.categoryId}&primary_charge_id=${params.primaryChargeId}`;
  if (params.companyIds && params.companyIds.length > 0) {
    const paramCompany = params.companyIds.map((id) => {
      return "company_id=" + id;
    });
    endpoint += `&${paramCompany.join("&")}`;
  }
  if (params.processMiddleClassIds && params.processMiddleClassIds.length > 0) {
    const paramMiddle = params.processMiddleClassIds.map((id) => {
      return "process_middle_class_id=" + id;
    });
    endpoint += `&${paramMiddle.join("&")}`;
  }
  if (params.processText) {
    endpoint += `&process_text=${encodeURIComponent(params.processText)}`;
  }

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIXSEARCH_BEGIN_SEARCH_PROCESSES,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.MATRIXSEARCH_END_SEARCH_PROCESSES,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const searchTasks = (params = null, callback) => {
  let endpoint = `${environments.API_BASE_URI}/matrix/item_tasks`;
  const query = util.getQueryByParams(params);
  if (params?.hideInvalidRows) {
    query.set("hide_invalid_rows_flg", "true");
  }

  if (query.toString() === "") {
    storageManager.removeConstructionItem("matrixSearchParams");
  } else {
    const urlState = restoreState();

    // item_id付き検索はストレージに保存しない
    if (!urlState.item_id) {
      storageManager.setConstructionItem("matrixSearchParams", JSON.stringify(params));
    }

    endpoint = `${endpoint}?${query.toString()}`;
  }

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIXSEARCH_BEGIN_SEARCH_TASKS,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.MATRIXSEARCH_END_SEARCH_TASKS,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const searchTasksChiba = (params = null, callback) => {
  let endpoint = `${environments.API_BASE_URI}/matrix/item_tasks_chiba`;
  const query = util.getQueryByParams(params);
  if (params?.hideInvalidRows) {
    query.set("hide_invalid_rows_flg", "true");
  }
  let itemId = 0;

  if (query.toString() === "") {
    storageManager.removeConstructionItem("matrixSearchParams");
  } else {
    const urlState = restoreState();

    // item_id付き検索はストレージに保存しない
    if (!urlState.item_id) {
      storageManager.setConstructionItem("matrixSearchParams", JSON.stringify(params));
    }
    itemId = urlState.item_id;
    endpoint = `${endpoint}?${query.toString()}`;
  }

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIXSEARCH_BEGIN_SEARCH_TASKS,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }
        response.item_id = parseInt(itemId);

        return {
          type: constants.MATRIXSEARCH_END_SEARCH_TASKS_CHIBA,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const searchProgressRate = (params = null, callback) => {
  let endpoint = `${environments.API_BASE_URI}/matrix/progress_rate`;

  const queryParams = { ...params };
  delete queryParams.start;
  delete queryParams.limit;
  const query = util.getQueryByParams(queryParams);

  if (query.toString() !== "") {
    endpoint = `${endpoint}?${query.toString()}`;
  }

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIXSEARCH_BEGIN_SEARCH_PROGRESS_RATE,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.MATRIXSEARCH_END_SEARCH_PROGRESS_RATE,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const fetchDownloadUrl = (params = null, format) => {
  let endpoint = `${environments.API_BASE_URI}/matrix/files?filetype=1&format=${format}`;
  const query = util.getQueryByParams(params);

  if (query.toString() !== "") {
    endpoint = `${endpoint}&${query.toString()}`;
  }

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIXSEARCH_BEGIN_FETCH_DOWNLOAD_URL,
        };
      },
      success: (blob) => {
        return {
          type: constants.MATRIXSEARCH_END_FETCH_DOWNLOAD_URL,
          payload: {
            format,
            url: URL.createObjectURL(blob),
          },
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};
