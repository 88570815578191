import clsx from "clsx";
import moment from "moment";
import React, { forwardRef, useMemo, useCallback } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

import "react-datepicker/dist/react-datepicker.css";
import Select from "../common/Select";

import { CheckBox, FormGrid, FormGroup, FormRow, Spacer } from "@/components/common";
import { SearchConditionConfigModal } from "@/components/common/SearchCondition/SearchConditionConfigModal";
import { SearchConditionDisplayButton } from "@/components/common/SearchCondition/SearchConditionDisplayButton";
import { SettingIconButton } from "@/components/common/SettingIconButton";
import { useSearchConditionConfig } from "@/hooks/useSearchConditionConfig";
import { ApprovalSearchKey, ApprovalSearchKeyType, ApprovalSearchParams } from "@/models/approval";
import { ConstructionMasters } from "@/models/masters";
import { SearchConditionConfigDefinition, ScreenName } from "@/models/screenConfig";

type StateToProps = {
  masters: ConstructionMasters;
  areaIds: number[];
  deviceIds: number[];
  categoryId: number;
  companyIds: number[];
  facilityManagementIds: number[];
  constructionManagementIds: number[];
  primaryChargeIds: number[];
  processMajorClassIds: number[];
  processMiddleClassIds: number[];
  checkpointIds: number[];
  scheduleEndDate: string;
  itemText: string;
  processText: string;
  otherIds: number[];
  filter: number[];
};

type DispatchToProps = {
  changeDevice: (deviceIds: number[]) => void;
  changeArea: (areaIds: number[]) => void;
  changeCategory: (categoryId: number) => void;
  changeFacilityManagement: (facilityManagementIds: number[]) => void;
  changeConstructionManagement: (constructionManagementIds: number[]) => void;
  changePrimaryCharge: (primaryChargeIds: number[]) => void;
  changeOther: (otherIds: number[]) => void;
  changeProcessMajorClass: (processMajorClassIds: number[]) => void;
  changeCheckpoint: (checkpointIds: number[]) => void;
  changeCompany: (companyIds: number[]) => void;
  changeProcessMiddleClass: (processMiddleClassIds: number[]) => void;
  changeScheduleEndDate: (scheduleEndDate: string) => void;
  changeItemText: (itemText: string) => void;
  changeProcessText: (processText: string) => void;
  changeFilter: (filter: number) => void;
  handleClear: () => void;
  handleSearch: (option?: { params?: ApprovalSearchParams }) => void;
};

type Props = StateToProps & DispatchToProps;

const ApprovalSearchPanel = forwardRef<HTMLDivElement, Props>(
  (
    {
      masters,
      areaIds,
      deviceIds,
      categoryId,
      companyIds,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      filter,
      processMajorClassIds,
      processMiddleClassIds,
      scheduleEndDate,
      checkpointIds,
      itemText,
      processText,
      changeArea,
      changeDevice,
      changeCategory,
      changeFacilityManagement,
      changeConstructionManagement,
      changePrimaryCharge,
      changeOther,
      changeProcessMajorClass,
      changeScheduleEndDate,
      changeCheckpoint,
      changeCompany,
      changeProcessMiddleClass,
      changeItemText,
      changeProcessText,
      changeFilter,
      handleClear,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();

    // データ取り込み画面の検索条件設定の定義
    const approvalSearchConditionDefinitions = useMemo<SearchConditionConfigDefinition<ApprovalSearchKeyType>[]>(
      () => [
        {
          itemsPerLine: 3,
          items: [
            { key: ApprovalSearchKey.Area, label: t("area"), required: false },
            { key: ApprovalSearchKey.Device, label: t("device"), required: false },
            { key: ApprovalSearchKey.FacilityManagement, label: t("facility_management"), required: false },
            { key: ApprovalSearchKey.Category, label: t("machines_category"), required: false },
            { key: ApprovalSearchKey.ConstructionManagement, label: t("construction_management"), required: false },
            { key: ApprovalSearchKey.PrimaryCharge, label: t("primary_charge"), required: false },
            { key: ApprovalSearchKey.Other, label: t("other"), required: false },
            { key: ApprovalSearchKey.ProcessMajorClass, label: t("process_major_class"), required: false },
            { key: ApprovalSearchKey.Company, label: t("company"), required: false },
            { key: ApprovalSearchKey.ProcessMiddleClass, label: t("process_middle_class"), required: false },
            { key: ApprovalSearchKey.ScheduleEndDate, label: t("scheduled_date"), required: false },
            { key: ApprovalSearchKey.Checkpoint, label: t("approval_confirm"), required: false },
            { key: ApprovalSearchKey.ItemText, label: t("title"), required: false },
            { key: ApprovalSearchKey.ProcessText, label: t("process_title"), required: false },
            { key: ApprovalSearchKey.NotStarted, label: t("not_started"), required: false },
            { key: ApprovalSearchKey.Coming, label: t("coming"), required: false },
            { key: ApprovalSearchKey.Expired, label: t("expired"), required: false },
            { key: ApprovalSearchKey.Completed, label: t("completed"), required: false },
            { key: ApprovalSearchKey.PreworkCompletionReport, label: t("prework_completion_report"), required: false },
          ],
        },
      ],
      [t]
    );

    // 検索条件設定
    const config = useSearchConditionConfig<ApprovalSearchKeyType>({
      screenName: ScreenName.APPROVAL,
      requiredKeys: approvalSearchConditionDefinitions.flatMap((v) =>
        v.items.filter((item) => item.required).map((item) => item.key)
      ),
    });

    const handleSearch = (e: React.MouseEvent) => {
      e.preventDefault();

      if (config.showAllConditions) {
        props.handleSearch();
      } else {
        // 検索条件設定で表示している検索条件のみ有効とする
        props.handleSearch({
          params: {
            areaIds: config.map.get(ApprovalSearchKey.Area) ? areaIds : [],
            deviceIds: config.map.get(ApprovalSearchKey.Device) ? deviceIds : [],
            categoryId: config.map.get(ApprovalSearchKey.Category) ? categoryId : 0,
            facilityManagementIds: config.map.get(ApprovalSearchKey.FacilityManagement) ? facilityManagementIds : [],
            constructionManagementIds: config.map.get(ApprovalSearchKey.ConstructionManagement)
              ? constructionManagementIds
              : [],
            primaryChargeIds: config.map.get(ApprovalSearchKey.PrimaryCharge) ? primaryChargeIds : [],
            otherIds: config.map.get(ApprovalSearchKey.Other) ? otherIds : [],
            processMajorClassIds: config.map.get(ApprovalSearchKey.ProcessMajorClass) ? processMajorClassIds : [],
            scheduleEndDate: config.map.get(ApprovalSearchKey.ScheduleEndDate) ? scheduleEndDate : "",
            checkpointIds: config.map.get(ApprovalSearchKey.Checkpoint) ? checkpointIds : [],
            companyIds: config.map.get(ApprovalSearchKey.Company) ? companyIds : [],
            processMiddleClassIds: config.map.get(ApprovalSearchKey.ProcessMiddleClass) ? processMiddleClassIds : [],
            itemText: config.map.get(ApprovalSearchKey.ItemText) ? itemText : "",
            processText: config.map.get(ApprovalSearchKey.ProcessText) ? processText : "",
            filter: [
              config.map.get(ApprovalSearchKey.NotStarted) && (filter ?? []).includes(1) ? 1 : undefined,
              config.map.get(ApprovalSearchKey.Coming) && (filter ?? []).includes(2) ? 2 : undefined,
              config.map.get(ApprovalSearchKey.Expired) && (filter ?? []).includes(3) ? 3 : undefined,
              config.map.get(ApprovalSearchKey.Completed) && (filter ?? []).includes(4) ? 4 : undefined,
              config.map.get(ApprovalSearchKey.PreworkCompletionReport) && (filter ?? []).includes(5) ? 5 : undefined,
            ].filter((v) => !!v),
          },
        });
      }
    };

    const display = useCallback(
      (target: ApprovalSearchKeyType): string => {
        return config.show([target]) ? "" : "hidden";
      },
      [config.show]
    );

    if (!config.map) return <></>;

    return (
      <div ref={ref}>
        <div className="flex justify-end bg-[#e4e4e4] w-[1200px]">
          <SettingIconButton className="w-[32px] p-[8px]" onClick={config.openSettingModal} />
        </div>
        <div className="search-box">
          <div className="grid gap-y-[16px]">
            <FormGrid
              className="grid-cols-4"
              display={config.show([
                ApprovalSearchKey.Area,
                ApprovalSearchKey.Device,
                ApprovalSearchKey.FacilityManagement,
                ApprovalSearchKey.Category,
                ApprovalSearchKey.ConstructionManagement,
                ApprovalSearchKey.PrimaryCharge,
                ApprovalSearchKey.Other,
                ApprovalSearchKey.ProcessMajorClass,
                ApprovalSearchKey.Company,
                ApprovalSearchKey.ProcessMiddleClass,
              ])}
            >
              <FormGroup title={t("area")} display={config.show([ApprovalSearchKey.Area])}>
                <Select
                  prefix="area"
                  isMulti={true}
                  options={masters?.areas || []}
                  value={areaIds}
                  onChange={changeArea}
                />
              </FormGroup>
              <FormGroup title={t("device")} display={config.show([ApprovalSearchKey.Device])}>
                <Select
                  prefix="device"
                  isMulti={true}
                  options={masters?.devices || []}
                  value={deviceIds}
                  onChange={changeDevice}
                />
              </FormGroup>
              <FormGroup title={t("facility_management")} display={config.show([ApprovalSearchKey.FacilityManagement])}>
                <Select
                  prefix="facility_management"
                  isMulti={true}
                  options={masters?.facility_managements || []}
                  value={facilityManagementIds}
                  onChange={changeFacilityManagement}
                />
              </FormGroup>
              <FormGroup title={t("machines_category")} display={config.show([ApprovalSearchKey.Category])}>
                <Select
                  prefix="category"
                  isMulti={false}
                  options={masters?.categories || []}
                  value={categoryId}
                  onChange={changeCategory}
                />
              </FormGroup>
              <FormGroup
                title={t("construction_management")}
                display={config.show([ApprovalSearchKey.ConstructionManagement])}
              >
                <Select
                  prefix="construction_management"
                  isMulti={true}
                  options={masters?.construction_managements || []}
                  value={constructionManagementIds}
                  onChange={changeConstructionManagement}
                />
              </FormGroup>
              <FormGroup title={t("primary_charge")} display={config.show([ApprovalSearchKey.PrimaryCharge])}>
                <Select
                  prefix="primary_charge"
                  isMulti={true}
                  options={masters?.primary_charges || []}
                  value={primaryChargeIds}
                  onChange={changePrimaryCharge}
                />
              </FormGroup>
              <FormGroup title={t("other")} display={config.show([ApprovalSearchKey.Other])}>
                <Select
                  prefix="other"
                  isMulti={true}
                  options={masters?.others || []}
                  value={otherIds}
                  onChange={changeOther}
                />
              </FormGroup>
              <FormGroup title={t("process_major_class")} display={config.show([ApprovalSearchKey.ProcessMajorClass])}>
                <Select
                  prefix="process_major_class"
                  isMulti={true}
                  options={masters?.process_major_classes || []}
                  value={processMajorClassIds}
                  onChange={changeProcessMajorClass}
                />
              </FormGroup>
              <FormGroup title={t("company")} display={config.show([ApprovalSearchKey.Company])}>
                <Select
                  prefix="company"
                  options={masters?.companies || []}
                  value={companyIds}
                  onChange={changeCompany}
                  isMulti={true}
                />
              </FormGroup>
              <FormGroup
                title={t("process_middle_class")}
                display={config.show([ApprovalSearchKey.ProcessMiddleClass])}
              >
                <Select
                  prefix="process_middle_class"
                  options={masters?.process_middle_classes || []}
                  value={processMiddleClassIds}
                  onChange={changeProcessMiddleClass}
                  isMulti={true}
                />
              </FormGroup>
            </FormGrid>

            <FormGrid
              className="grid-cols-4"
              display={config.show([
                ApprovalSearchKey.ScheduleEndDate,
                ApprovalSearchKey.Checkpoint,
                ApprovalSearchKey.ItemText,
                ApprovalSearchKey.ProcessText,
              ])}
            >
              <FormGroup title={t("scheduled_date")} display={config.show([ApprovalSearchKey.ScheduleEndDate])}>
                <DatePicker
                  wrapperClassName="w-200"
                  selected={scheduleEndDate === "" ? null : moment(scheduleEndDate, "YYYY/MM/DD").toDate()}
                  dateFormat="yyyy/MM/dd"
                  locale={t("calender_locale")}
                  onChange={(date) =>
                    changeScheduleEndDate(date === null ? "" : moment(date).format("YYYY/MM/DD").toString())
                  }
                />
              </FormGroup>
              <FormGroup title={t("approval_confirm")} display={config.show([ApprovalSearchKey.Checkpoint])}>
                <Select
                  prefix="checkpoint"
                  isMulti={true}
                  options={masters?.checkpoints || []}
                  value={checkpointIds}
                  onChange={changeCheckpoint}
                />
              </FormGroup>
              <FormGroup title={t("title")} display={config.show([ApprovalSearchKey.ItemText])}>
                <input
                  data-test-id="text-approval-item-name"
                  type="text"
                  className="form-control w-200"
                  value={itemText}
                  onChange={(e) => changeItemText(e.target.value)}
                />
              </FormGroup>
              <FormGroup title={t("process_title")} display={config.show([ApprovalSearchKey.ProcessText])}>
                <input
                  data-test-id="text-approval-process-name"
                  type="text"
                  className="form-control w-200"
                  value={processText}
                  onChange={(e) => changeProcessText(e.target.value)}
                />
              </FormGroup>
            </FormGrid>
            <FormRow
              className="gap-x-[15px] ml-90"
              display={config.show([
                ApprovalSearchKey.NotStarted,
                ApprovalSearchKey.Coming,
                ApprovalSearchKey.Expired,
                ApprovalSearchKey.Completed,
                ApprovalSearchKey.PreworkCompletionReport,
              ])}
            >
              <div className={clsx("search-box__form left", display(ApprovalSearchKey.NotStarted))}>
                <CheckBox label={t("not_started")} checked={_.includes(filter, 1)} onChange={() => changeFilter(1)} />
              </div>
              <div className={clsx("search-box__form left", display(ApprovalSearchKey.Coming))}>
                <CheckBox label={t("coming")} checked={_.includes(filter, 2)} onChange={() => changeFilter(2)} />
              </div>
              <div className={clsx("search-box__form left", display(ApprovalSearchKey.Expired))}>
                <CheckBox label={t("expired")} checked={_.includes(filter, 3)} onChange={() => changeFilter(3)} />
              </div>
              <div className={clsx("search-box__form left", display(ApprovalSearchKey.Completed))}>
                <CheckBox label={t("completed")} checked={_.includes(filter, 4)} onChange={() => changeFilter(4)} />
              </div>
              <div className={clsx("search-box__form left", display(ApprovalSearchKey.PreworkCompletionReport))}>
                <CheckBox
                  label={t("prework_completion_report")}
                  checked={_.includes(filter, 5)}
                  onChange={() => changeFilter(5)}
                />
              </div>
            </FormRow>
            <FormRow className="justify-between mt-[16px]">
              <Spacer x="210px" />
              <SearchConditionDisplayButton
                isExpanded={config.showAllConditions}
                onChange={config.toggleDisplayOptionalConditions}
              />
              <div className="flex gap-x-4">
                <button className="btn btn-gray" onClick={handleClear}>
                  {t("reset")}
                </button>
                <button className="btn btn-blue" onClick={handleSearch}>
                  {t("search")}
                </button>
              </div>
            </FormRow>
          </div>
        </div>
        {config.showSettingModal && (
          <SearchConditionConfigModal<ApprovalSearchKeyType>
            title={t("search_condition_config")}
            configMap={config.map}
            definitions={approvalSearchConditionDefinitions}
            onClose={config.closeSettingModal}
            onSubmit={(c) => config.saveSearchItemConfig(c)}
          />
        )}
      </div>
    );
  }
);

ApprovalSearchPanel.displayName = "ApprovalSearchPanel";

export default ApprovalSearchPanel;
