import { connect } from "react-redux";

import actions from "../../actions";
import SearchPanel from "../../components/summary/SearchPanel";

const mapStateToProps = (state) => {
  let areas = [];
  let devices = [];
  let categories = [];
  let facility_managements = [];
  let construction_managements = [];
  let primary_charges = [];

  if (state.construction.masters) {
    areas = state.construction.masters.areas;
    devices = state.construction.masters.devices;
    categories = state.construction.masters.categories;
    facility_managements = state.construction.masters.facility_managements;
    construction_managements = state.construction.masters.construction_managements;
    primary_charges = state.construction.masters.primary_charges;
  }

  return {
    areaIds: state.summary.areaIds,
    deviceIds: state.summary.deviceIds,
    categoryIds: state.summary.categoryIds,
    facilityManagementIds: state.summary.facilityManagementIds,
    constructionManagementIds: state.summary.constructionManagementIds,
    primaryChargeIds: state.summary.primaryChargeIds,
    masters: {
      areas,
      devices,
      categories,
      facility_managements,
      construction_managements,
      primary_charges,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeArea: (areaId) => dispatch(actions.summary.changeArea(areaId)),
    changeDevice: (deviceId) => dispatch(actions.summary.changeDevice(deviceId)),
    changeCategory: (categoryId) => dispatch(actions.summary.changeCategory(categoryId)),
    changeFacilityManagement: (items) => dispatch(actions.summary.changeFacilityManagement(items)),
    changeConstructionManagement: (items) => dispatch(actions.summary.changeConstructionManagement(items)),
    changePrimaryCharge: (items) => dispatch(actions.summary.changePrimaryCharge(items)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SearchPanel);
