export const ScheduleSearchKey = {
  Area: "areaId",
  Device: "deviceId",
  Category: "categoryId",
  Manager: "managerUserId",
  FieldP1: "fieldP1",
  Field1: "field1",
  Field2: "field2",
  ApproveUnprocessed: "approveUnprocessed",
  Approve1User: "approve1UserId",
  Approve4User: "approve4UserId",
  FieldT9: "fieldT9",
  ScheduleDate: "scheduleDate",
} as const;

export type ScheduleSearchKeyType = (typeof ScheduleSearchKey)[keyof typeof ScheduleSearchKey];
