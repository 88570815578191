import { Timestamp } from "@/sx-layout/models";

export type ScheduleItemChiba = {
  schedule_id: number;
  item_id: number;
  schedule_date: string;
  schedule_no: number;
  category_id?: number;
  area_id?: number;
  device_id?: number;
  facility_management_id?: number;
  construction_management_id?: number;
  primary_charge_id?: number;
  other_id?: number;
  special_process_text: string;
  item_name: string;
  device_name: string;
  process_text: string;
  checkpoint1?: number;
  checkpoint1_name: string;
  checkpoint2?: number;
  checkpoint2_name: string;
  checkpoint3?: number;
  checkpoint3_name: string;
  checkpoint4?: number;
  checkpoint4_name: string;
  checkpoint5?: number;
  checkpoint5_name: string;
  checkpoint6?: number;
  checkpoint6_name: string;
  work_start_hour: string;
  work_end_hour: string;
  note3?: string;
  protective_equipment_text: string;
  company_name: string;
  user_name: string;
  request_user_id: number;
  request_user_name: string;
  approve_user_id?: number;
  approve_user_name: string;
  field1?: number;
  field1_name: string;
  field2?: number;
  field2_name: string;
  field12?: number;
  field12_name: string;
  field3?: number;
  field3_name: string;
  field4?: number;
  field4_name: string;
  field5?: number;
  field5_name: string;
  field6?: number;
  field6_name: string;
  field7?: number;
  field7_name: string;
  field8?: number;
  field8_name: string;
  field9?: number;
  field9_name: string;
  field13?: number;
  field13_name: string;
  field14?: number;
  field14_name: string;
  field15?: number;
  field15_name: string;
  field10?: number;
  field10_name: string;
  field11?: number;
  field11_name: string;
  note1?: string;
  note2?: string;
  item_url_num: number;
  history_alert?: string[];
  timestamp: Timestamp;
  roles: number[];
};

export type ScheduleUrl = {
  item_url_id: number;
  item_id: number;
  label: string;
  url: string;
  timestamp: Timestamp;
};

export type ScheduleOption = {
  id: string;
  name: string;
  company_id?: string;
};

export type ScheduleOptionGroup = {
  target: string;
  options: ScheduleOption[];
};

export type ScheduleChibaSearchParams = {
  areaIds?: number[];
  deviceIds?: number[];
  categoryIds?: number[];
  facilityManagementIds?: number[];
  constructionManagementIds?: number[];
  primaryChargeIds?: number[];
  otherIds?: number[];
  field1Params?: number[];
  companyParams?: number[];
  userParams?: number[];
  groupParams?: number[];
  approveParams?: number[];
  checkpoint1?: number;
  checkpoint2?: number;
  checkpoint3?: number;
  checkpoint4?: number;
  checkpoint5?: number;
  checkpoint6?: number;
  filter?: number;
  requestUser?: number;
  field5?: number;
};

// 変更履歴表示対象項目
export const HistoryColumn = {
  RECORD: "record",
  SPECIAL_PROCESS_TEXT: "special_process_text",
  PROCESS_TEXT: "process_text",
  CHECKPOINT1: "checkpoint1",
  CHECKPOINT2: "checkpoint2",
  CHECKPOINT3: "checkpoint3",
  CHECKPOINT4: "checkpoint4",
  CHECKPOINT5: "checkpoint5",
  CHECKPOINT6: "checkpoint6",
  WORK_START_HOUR: "work_start_hour",
  WORK_END_HOUR: "work_end_hour",
  NOTE3: "note3",
  PROTECTIVE_EQUIPMENT_TEXT: "protective_equipment_text",
  FIELD1: "field1",
  NOTE1: "note1",
  REQUEST_USER_ID: "request_user_id",
} as const;

export type HistoryColumnType = (typeof HistoryColumn)[keyof typeof HistoryColumn];

export type ScheduleHistory = {
  content: string;
  insert_date: string;
  insert_user_id: number;
  insert_user_name: string;
};

export const ScheduleChibaSearchKey = {
  ScheduleDate: "scheduleDate",
  Area: "areaIds",
  Device: "deviceIds",
  Category: "categoryIds",
  FacilityManagement: "facilityManagementIds",
  ConstructionManagement: "constructionManagementIds",
  PrimaryCharge: "primaryChargeIds",
  Other: "otherIds",
  Company: "companyIds",
  UserGroup: "userGroup",
  Field1: "field1Ids",
  Approve: "approveIds",
  Checkpoint1: "checkpoint1Id",
  Checkpoint2: "checkpoint2Id",
  Checkpoint3: "checkpoint3Id",
  Checkpoint4: "checkpoint4Id",
  Checkpoint5: "checkpoint5Id",
  Checkpoint6: "checkpoint6Id",
  Filter: "filter",
  RequestUser: "requestUserId",
  Field5: "field5Ids",
};

export type ScheduleChibaSearchKeyType = (typeof ScheduleChibaSearchKey)[keyof typeof ScheduleChibaSearchKey];
