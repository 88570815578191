import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component, createRef } from "react";
import { withTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";

import SearchPanel from "../../containers/system/SearchPanelContainer";
import SystemEditorContainer from "../../containers/system/SystemEditorContainer";
import SystemViewerContainer from "../../containers/system/SystemViewerContainer";
import { addHeightResizeListener, removeHeightResizeListener, getTableBodyHeight } from "../../lib/common";
import * as util from "../../lib/common";
import restoreState from "../../lib/restoreState";
import { isValid } from "../../lib/roleChecker";
import storageManager from "../../lib/storageManager";
import { SystemSearchParams } from "../../models/system";

import { FooterPager } from "@/components/common/FooterPager";

class System extends Component {
  searchBox;
  constructor(props) {
    super(props);
    this.searchBox = createRef();
    this.state = {
      showSearch: true,
      showTable: false,
      showDetail: false,
      showEditor: false,
      limit: 20,
      start: 1,
      isEmpty: true,
      packSystemIds: [],
      systemId: 0,
      systemName: "",
      error: {},
      tableBodyMaxHeight: window.innerHeight - 420,
      objectUrl: "",
      downloadName: "",
      currentPage: 1,
      maxPage: 0,
      editItem: null,
      activeButton: "",
      requestParam: {},
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleDetailSearch = this.handleDetailSearch.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleSearchBoxHeightChange = this.handleSearchBoxHeightChange.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
    this.showDropdown = this.showDropdown.bind(this);
    this.hideDropdown = this.hideDropdown.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);
    this.handlePrevPage = this.handlePrevPage.bind(this);
    this.handleEditor = this.handleEditor.bind(this);
    this.hideEditor = this.hideEditor.bind(this);
    this.hideDetail = this.hideDetail.bind(this);
    this.toggleCheckobx = this.toggleCheckobx.bind(this);
  }

  componentDidMount() {
    this.handleSearchBoxHeightChange();
    addHeightResizeListener(this.searchBox.current, this.handleSearchBoxHeightChange);

    this.resizeTimer = 0;
    window.addEventListener("resize", this.handleResize);

    const urlState = restoreState();
    const { validated } = this.props;
    if (
      validated &&
      (storageManager.getConstructionItem("systemSearchParams") !== null || (urlState && urlState.hasQuery))
    ) {
      this.handleSearch();
    }
  }

  componentDidUpdate(prevProps) {
    const { validated } = this.props;
    if (!validated) {
      return;
    }

    const urlState = restoreState();
    const notFirst =
      storageManager.getConstructionItem("systemSearchParams") !== null || (urlState && urlState.hasQuery);
    if (!prevProps.validated && validated && notFirst) {
      this.handleSearch();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    removeHeightResizeListener(this.searchBox.current);

    this.props.revertLocalCondition();
  }

  handleSearchBoxHeightChange() {
    const maxHeight = getTableBodyHeight("system", this.searchBox.current, this.theader);
    this.setState({ tableBodyMaxHeight: maxHeight });
  }

  handleResize() {
    if (this.resizeTimer > 0) {
      clearTimeout(this.resizeTimer);
    }

    const callback = () => {
      this.handleSearchBoxHeightChange();
    };

    this.resizeTimer = setTimeout(callback, 200);
  }

  toggleSearch() {
    const callback = () => {
      this.handleSearchBoxHeightChange();
      addHeightResizeListener(this.searchBox.current, this.handleSearchBoxHeightChange);
    };
    this.setState({ showSearch: !this.state.showSearch }, () => setTimeout(callback, 100));
  }

  showDropdown(name) {
    this.setState({
      activeButton: name,
    });
  }

  hideDropdown() {
    this.setState({ activeButton: "" });
  }

  handleSearch(option?: { params?: SystemSearchParams }) {
    this.setState({
      isEmpty: true,
      packSystemIds: [],
      error: {},
    });

    const error = {};
    const {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      systemName,
      note,
      scheStartDate,
      scheEndDate,
      closeStartDate,
      closeEndDate,
      totalTest,
      preparation,
      search,
      t,
      setConditions,
    } = this.props;

    const params = {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      systemName,
      note,
      scheStartDate,
      scheEndDate,
      closeStartDate,
      closeEndDate,
      totalTest,
      preparation,
      start: 1,
      limit: this.state.limit,
      ...option?.params,
    };

    // Date validation
    if (params.scheStartDate !== null && params.scheEndDate !== null) {
      const sd = moment(params.scheStartDate);
      const ed = moment(params.scheEndDate);
      error.scheDate = ed.diff(sd, "days") < 0 ? t("schedule_alert") : "";
      this.setState({ error });
    }

    if (params.closeStartDate !== null && params.closeEndDate !== null) {
      const sd = moment(params.closeStartDate);
      const ed = moment(params.closeEndDate);
      error.closeDate = ed.diff(sd, "days") < 0 ? t("schedule_alert") : "";
      this.setState({ error });
    }

    option?.params && setConditions(option.params);

    search(params, (data) => {
      this.setState(
        {
          start: data.length + 1,
          isEmpty: data.length === 0,
          maxPage: Math.ceil(data.total_num / this.state.limit),
          currentPage: Math.ceil(params.start / this.state.limit),
          showTable: true,
          requestParam: { ...params },
        },
        () => {
          this.handleSearchBoxHeightChange();
        }
      );
    });
  }

  handleReset() {
    this.setState({
      isEmpty: true,
      packSystemIds: [],
      error: {},
    });

    const { search } = this.props;

    const params = {
      areaIds: [],
      deviceIds: [],
      categoryId: 0,
      facilityManagementIds: [],
      constructionManagementIds: [],
      primaryChargeIds: [],
      otherIds: [],
      systemName: "",
      note: "",
      scheStartDate: "",
      scheEndDate: "",
      closeStartDate: "",
      closeEndDate: "",
      totalTest: -1,
      preparation: "",
      start: 1,
      limit: this.state.limit,
    };

    search(params, (data) => {
      this.setState({
        start: data.length + 1,
        isEmpty: data.length === 0,
        maxPage: Math.ceil(data.total_num / this.state.limit),
        currentPage: Math.ceil(params.start / this.state.limit),
        showTable: true,
        requestParam: { ...params },
      });
    });
  }

  handleClear() {
    this.setState({ start: 1 }, () => {
      this.props.resetSearch();
      this.handleReset();
    });
  }

  handleSearchPager(startPos = 1) {
    this.setState({
      isEmpty: true,
      packSystemIds: [],
      error: {},
    });

    const error = {};
    const { scheStartDate, scheEndDate, closeStartDate, closeEndDate, search, t } = this.props;

    const params = {
      ...this.state.requestParam,
      start: startPos,
      limit: this.state.limit,
    };

    // Date validation
    if (scheStartDate !== null && scheEndDate !== null) {
      const sd = moment(scheStartDate);
      const ed = moment(scheEndDate);
      error.scheDate = ed.diff(sd, "days") < 0 ? t("schedule_alert") : "";
      this.setState({ error });
    }

    if (closeStartDate !== null && closeEndDate !== null) {
      const sd = moment(closeStartDate);
      const ed = moment(closeEndDate);
      error.closeDate = ed.diff(sd, "days") < 0 ? t("schedule_alert") : "";
      this.setState({ error });
    }

    search(params, (data) => {
      this.setState(
        {
          start: data.length + 1,
          isEmpty: data.length === 0,
          maxPage: Math.ceil(data.total_num / this.state.limit),
          currentPage: Math.ceil(params.start / this.state.limit),
          showTable: true,
        },
        () => {
          this.handleSearchBoxHeightChange();
        }
      );
    });
  }

  handleNextPage() {
    const { currentPage, limit, maxPage } = this.state;
    if (currentPage < maxPage) {
      const startPos = 1 + currentPage * limit;
      this.handleSearchPager(startPos);
    }
  }

  handlePrevPage() {
    const { currentPage, limit } = this.state;
    if (currentPage > 1) {
      const startPos = 1 + (currentPage - 2) * limit;
      this.handleSearchPager(startPos);
    }
  }

  handleJumpPage(page) {
    const startPos = 1 + (page - 1) * this.state.limit;
    this.handleSearchPager(startPos);
  }

  handleDetailSearch(system_id, system_name) {
    const { searchItems } = this.props;

    const params = {
      systemId: system_id,
      start: 1,
      limit: 999999,
    };

    searchItems(params, (data) => {
      this.setState({
        showDetail: true,
        systemId: system_id,
        systemName: system_name,
        detailItems: data,
      });
    });
  }

  handleEditor(systemId) {
    const { fetchItem } = this.props;

    fetchItem(systemId, (data) => {
      this.setState({
        showEditor: true,
        editItem: data,
      });
    });
  }

  hideEditor() {
    this.setState({ showEditor: false });
  }

  hideDetail() {
    this.setState({ showDetail: false });
  }

  handleSave(data, cancelHandler) {
    const { updateItem } = this.props;

    updateItem(data, this.hideEditor, cancelHandler);
    this.hideEditor();
  }

  toggleCheckobx(system_id) {
    if (this.state.packSystemIds.includes(system_id)) {
      this.setState({
        packSystemIds: this.state.packSystemIds.filter((item) => item !== system_id),
      });
    } else {
      this.setState({
        packSystemIds: [...this.state.packSystemIds, system_id],
      });
    }
  }

  handleDownload(ids, filetype) {
    const { toggleDownloading } = this.props;
    toggleDownloading(true);

    const fallback = () => toggleDownloading(false);

    this.props.downloadFiles(
      "system",
      ids,
      filetype,
      (blob, fileName) => {
        const objectUrl = URL.createObjectURL(blob);
        const fname = fileName || "system.xls";

        this.setState({ objectUrl, downloadName: fname }, () => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fname);
          } else if (this.btnDownload) {
            const evt = util.createClickEvent();
            this.btnDownload.dispatchEvent(evt);
          }

          toggleDownloading(false);
        });
      },
      fallback
    );
  }

  render() {
    const titleClassName = `toggle icon-keyboard_arrow_up ${!this.state.showSearch ? "closed" : ""}`;
    const { fetching, t, downloading, masterItems } = this.props;

    const error = this.state.error;

    return (
      <div>
        <div className="contents system">
          <div className="inner">
            <h1 className="page-ttl">
              {t("system_list")}
              <span
                data-test-id="button-system-toggle-search"
                className={titleClassName}
                onClick={this.toggleSearch}
              ></span>
            </h1>
            {this.state.showSearch && (
              <SearchPanel
                ref={this.searchBox}
                error={error}
                handleSearch={this.handleSearch}
                handleClear={this.handleClear}
              />
            )}
            <div className={`${fetching ? "loading loading--list" : ""}`}>
              {this.state.isEmpty || !masterItems || !masterItems.length
                ? this.state.showTable && !fetching && <p className="empty-message">{t("no_data")}</p>
                : this.state.showTable && (
                    <>
                      <div className="tbl-top-area clearfix relative">
                        <div className="tbl-top-left">
                          <div className="btn-dropdown-area" onMouseLeave={this.hideDropdown}>
                            <a
                              className="d-n"
                              download={this.state.downloadName}
                              href={this.state.objectUrl}
                              ref={(node) => (this.btnDownload = node)}
                            >
                              download
                            </a>
                            <button
                              data-test-id="button-system-batch"
                              className="btn btn-blue btn-dropdown"
                              onClick={() => this.showDropdown("batch")}
                              disabled={this.state.packSystemIds.length === 0}
                            >
                              <span
                                className={clsx(
                                  "icon",
                                  this.state.packSystemIds.length > 0 ? "icon-check_box" : "icon-check_box_disabled"
                                )}
                              />
                              {t("packed_operation")}
                            </button>
                            {this.state.activeButton === "batch" && (
                              <ul className="dropdown-menu" onClick={this.hideDropdown}>
                                <li
                                  data-test-id="button-system-update-system"
                                  onClick={() => this.handleDownload(this.state.packSystemIds, 2)}
                                >
                                  <img src="./img/icon_xls.svg" alt="XLS" className="icon-file" />
                                  {t("for_update_system")}
                                </li>
                                <li
                                  data-test-id="button-system-update-item-system"
                                  onClick={() => this.handleDownload(this.state.packSystemIds, 1)}
                                >
                                  <img src="./img/icon_xls.svg" alt="XLS" className="icon-file" />
                                  {t("for_update_item_system")}
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>
                        <div className={`${downloading ? "loading-small loading-small-download" : ""}`} />
                      </div>
                      <div className="tbl-area">
                        <table className="tbl-basic tbl-data">
                          <thead className="list-head tbl-head-adjusted" ref={(node) => (this.theader = node)}>
                            <tr>
                              <th width="30"></th>
                              <th>{t("system")}</th>
                              <th width="120">{t("airproof_sche_date")}</th>
                              <th width="120">{t("airproof_result_date")}</th>
                              <th>{t("note")}</th>
                              <th width="100">{t("progress")}</th>
                              <th width="100">{t("progress_schedule")}</th>
                              <th width="140">{t("airproof_comp_all")}</th>
                              <th width="120">{t("last_registry_date")}</th>
                              <th width="60">{t("edit")}</th>
                            </tr>
                          </thead>
                          <tbody
                            className="list-body"
                            style={{ maxHeight: this.state.tableBodyMaxHeight }}
                            ref={(node) => (this.tbody = node)}
                          >
                            {masterItems.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td className="txt-center">
                                    <label className={"ckbox"}>
                                      <input type="checkbox" onClick={() => this.toggleCheckobx(item.system_id)} />
                                      <span></span>
                                    </label>
                                  </td>
                                  <td>
                                    <a onClick={() => this.handleDetailSearch(item.system_id, item.system_name)}>
                                      {item.system_name}
                                    </a>
                                  </td>
                                  <td className="txt-center">
                                    {item.totaltest_schedule_date
                                      ? item.totaltest_schedule_date.replace(/-/g, "/")
                                      : ""}
                                  </td>
                                  <td className="txt-center">
                                    {item.totaltest_result_date ? item.totaltest_result_date.replace(/-/g, "/") : ""}
                                  </td>
                                  <td className="txt-left">{item.system_comment}</td>
                                  <td className={`txt-right${item.background_color === 3 ? " cell-orange" : ""}`}>
                                    {item.result_progress_rate}%
                                  </td>
                                  <td className="txt-right">{item.schedule_progress_rate}%</td>
                                  <td className="txt-center">
                                    {item.preparation_complete_num}/{item.preparation_num}
                                  </td>
                                  <td className="txt-center">
                                    {item.item_add_date ? item.item_add_date.replace(/-/g, "/") : ""}
                                  </td>
                                  <td className="txt-center">
                                    {isValid(item.roles, "__self__", "update") && (
                                      <a onClick={() => this.handleEditor(item.system_id)}>{t("edit")}</a>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
            </div>
          </div>
        </div>
        {this.state.maxPage > 0 && (
          <FooterPager
            currentPage={this.state.currentPage}
            maxPage={this.state.maxPage}
            onPrev={() => this.handlePrevPage()}
            onNext={() => this.handleNextPage()}
            onJump={(page) => this.handleJumpPage(page)}
          />
        )}
        {this.state.showDetail && (
          <SystemViewerContainer
            closeHandler={this.hideDetail}
            systemId={this.state.systemId}
            systemName={this.state.systemName}
            resultProgressRate={this.state.detailItems.result_progress_rate}
            scheduleProgressRate={this.state.detailItems.schedule_progress_rate}
            masterDetailItems={this.state.detailItems.system_item_list_data}
            detailItems={this.state.detailItems.system_item_list_data}
          />
        )}
        {this.state.showEditor && (
          <SystemEditorContainer
            titleName={t("system_edit") + "：[" + this.state.editItem.system_name + "]"}
            data={this.state.editItem}
            closeHandler={this.hideEditor}
          />
        )}
      </div>
    );
  }
}

System.propTypes = {
  areaIds: PropTypes.array.isRequired,
  deviceIds: PropTypes.array.isRequired,
  categoryId: PropTypes.number.isRequired,
  facilityManagementIds: PropTypes.array.isRequired,
  constructionManagementIds: PropTypes.array.isRequired,
  primaryChargeIds: PropTypes.array.isRequired,
  otherIds: PropTypes.array.isRequired,
  systemName: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  scheStartDate: PropTypes.string,
  scheEndDate: PropTypes.string,
  closeStartDate: PropTypes.string,
  closeEndDate: PropTypes.string,
  totalTest: PropTypes.number.isRequired,
  preparation: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  search: PropTypes.func.isRequired,
  searchItems: PropTypes.func.isRequired,
  fetchItem: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  revertLocalCondition: PropTypes.func.isRequired,
  validated: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  toggleDownloading: PropTypes.func.isRequired,
  downloadFiles: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  masterItems: PropTypes.array.isRequired,
  setConditions: PropTypes.func.isRequired,
};

export default withTranslation()(System);
