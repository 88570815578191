import * as constants from "../constants/index";
import environments from "../environments";
import storageManager from "../lib/storageManager";

import { hideConstructionSelector } from "./app";

export const setValidated = (validated) => ({
  type: constants.ASSET_SET_VALIDATED,
  payload: validated,
});

export const changeCategory = (items) => ({
  type: constants.ASSET_CHANGE_CATEGORY,
  payload: items,
});

export const changeFilename = (fileName) => ({
  type: constants.ASSET_CHANGE_FILENAME,
  payload: fileName,
});

export const changeDateFrom = (insertStartDate) => ({
  type: constants.ASSET_CHANGE_DATE_FROM,
  payload: insertStartDate,
});

export const changeDateTo = (insertEndDate) => ({
  type: constants.ASSET_CHANGE_DATE_TO,
  payload: insertEndDate,
});

export const changeUploadTarget = (uploadId) => ({
  type: constants.ASSET_CHANGE_UPLOAD_TARGET,
  payload: uploadId,
});

export const revertLocalCondition = () => ({
  type: constants.ASSET_REVERT_CONDITION,
});

export const clearSearch = (target) => ({
  type: constants.ASSET_CLEAR_SEARCH,
  payload: target,
});

export const download = (params = null, filetype, callback, fallback) => {
  let endpoint = `${environments.API_BASE_URI}/asset/files`;

  const query = new URLSearchParams();

  query.set("filetype", filetype);
  query.set("format", "excel");

  if (params && params.categoryIds && params.categoryIds.length > 0) {
    params.categoryIds.forEach((param) => query.append("category_id[]", param));
  }

  if (params && params.fileName) {
    query.set("file_name", params.fileName);
  }

  if (params && params.insertStartDate) {
    query.set("insert_start_date", params.insertStartDate);
  }

  if (params && params.insertEndDate) {
    query.set("insert_end_date", params.insertEndDate);
  }

  if (params && params.uploadTarget && params.uploadTarget.length > 0) {
    params.uploadTarget.forEach((param) => query.append("upload_target[]", param));
  }

  endpoint = `${endpoint}?${query.toString()}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      success: (response, fileName) => {
        if (callback !== undefined) {
          callback(response, fileName);
        }

        return {
          type: constants.ASSET_END_DOWNLOAD,
        };
      },
      error: (response) => {
        fallback && fallback();

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const createZip = (params, callback) => {
  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/asset/create`,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(params),
    callbacks: {
      begin: () => ({
        type: constants.ASSET_BEGIN_CREATE_ZIP_FILE,
      }),
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.ASSET_END_CREATE_ZIP_FILE,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const search = (params, callback) => {
  let endpoint = `${environments.API_BASE_URI}/assets`;
  const query = new URLSearchParams();

  if (params && params.categoryIds && params.categoryIds.length > 0) {
    params.categoryIds.forEach((param) => query.append("category_id[]", param));
  }

  if (params && params.fileName) {
    query.set("file_name", params.fileName);
  }

  if (params && params.insertStartDate) {
    query.set("insert_start_date", params.insertStartDate);
  }

  if (params && params.insertEndDate) {
    query.set("insert_end_date", params.insertEndDate);
  }

  if (params && params.uploadTarget && params.uploadTarget.length > 0) {
    params.uploadTarget.forEach((param) => query.append("upload_target[]", param));
  }

  if (params && params.limit) {
    query.set("limit", params.limit);
  }

  if (params && params.start) {
    query.set("start", params.start);
  }

  storageManager.setConstructionItem("assetSearchParams", JSON.stringify(params));
  endpoint = `${endpoint}?${query.toString()}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.ASSET_BEGIN_SEARCH,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.ASSET_END_SEARCH,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const deleteItem = (params, callback) => {
  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/assets/delete`,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({ file_id: params.file_id, upload_target: params.upload_target }),
    callbacks: {
      begin: () => {
        return {
          type: constants.ASSET_BEGIN_DELETE_ITEM,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.ASSET_END_DELETE_ITEM,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
    meta: {
      confirm: {
        messages: [params.file_name, "msg_deleted"],
      },
    },
  };
};

export const setConditions = (conditions) => {
  return {
    type: constants.ASSET_SET_CONDITIONS,
    payload: conditions,
  };
};
