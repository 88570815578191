import _ from "lodash";
import moment from "moment";
import React, { useState, useMemo, forwardRef } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

import "react-datepicker/dist/react-datepicker.css";

import MasterSelect from "../common/Select";

import { FormGrid, FormGroup, FormRow, Spacer } from "@/components/common";
import { SearchConditionConfigModal } from "@/components/common/SearchCondition/SearchConditionConfigModal";
import { SearchConditionDisplayButton } from "@/components/common/SearchCondition/SearchConditionDisplayButton";
import { SettingIconButton } from "@/components/common/SettingIconButton";
import { useSearchConditionConfig } from "@/hooks/useSearchConditionConfig";
import { AssetSearchKey, AssetSearchParams, AssetSearchKeyType } from "@/models/asset";
import { ConstructionMasters } from "@/models/masters";
import { SearchConditionConfigDefinition, ScreenName } from "@/models/screenConfig";

type StateProps = {
  masters: ConstructionMasters;
  categoryIds: number[];
  insertStartDate: string;
  insertEndDate: string;
  fileName: string;
  uploadTarget: number[];
};

type DispatchProps = {
  changeCategory: (categoryIds: number[]) => void;
  changeFilename: (fileName: string) => void;
  changeUploadTarget: (uploadTarget: number) => void;
  changeDateFrom: (date: string) => void;
  changeDateTo: (date: string) => void;
  handleSearch: (option?: { params: AssetSearchParams }) => void;
  handleClear: () => void;
};

type AssetSearchAreaProps = StateProps & DispatchProps;

const AssetSearchArea = forwardRef<HTMLDivElement, AssetSearchAreaProps>((props, ref) => {
  const { t } = useTranslation();
  const [error, setError] = useState<{ insertDate?: boolean; upload?: boolean }>({});

  const {
    masters,
    categoryIds,
    insertStartDate,
    insertEndDate,
    fileName,
    uploadTarget,
    changeCategory,
    changeFilename,
    changeUploadTarget,
    handleSearch,
    handleClear,
    changeDateFrom,
    changeDateTo,
  } = props;

  // データ取り込み画面の検索条件設定の定義
  const assetSearchConditionDefinitions = useMemo<SearchConditionConfigDefinition<AssetSearchKeyType>[]>(
    () => [
      {
        itemsPerLine: 3,
        items: [
          { key: AssetSearchKey.Category, label: t("machines_category"), required: false },
          { key: AssetSearchKey.FileName, label: t("file_name"), required: false },
          { key: AssetSearchKey.InsertDate, label: t("registered_day"), required: false },
          { key: AssetSearchKey.UploadTarget, label: t("upload_destination"), required: true },
        ],
      },
    ],
    []
  );

  // 検索条件設定
  const config = useSearchConditionConfig<AssetSearchKeyType>({
    screenName: ScreenName.ASSET,
    requiredKeys: assetSearchConditionDefinitions.flatMap((v) =>
      v.items.filter((item) => item.required).map((item) => item.key)
    ),
  });

  const handleChangeDateFrom = (date) => {
    changeDateFrom(
      date ? `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}` : ""
    );
  };

  const handleChangeDateTo = (date) => {
    changeDateTo(
      date ? `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}` : ""
    );
  };

  const handleSearchClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const error: { insertDate?: boolean; upload?: boolean } = {};

    // 日付のチェック
    if ((config.map.get(AssetSearchKey.InsertDate) || config.showAllConditions) && insertStartDate && insertEndDate) {
      const sd = moment(insertStartDate);
      const ed = moment(insertEndDate);
      error.insertDate = ed.diff(sd, "days") < 0;
    }

    error.upload = uploadTarget.length < 1;
    setError(error);

    if (!error.upload) {
      if (config.showAllConditions) {
        handleSearch();
      } else {
        handleSearch({
          params: {
            categoryIds: config.map.get(AssetSearchKey.Category) ? categoryIds : [],
            fileName: config.map.get(AssetSearchKey.FileName) ? fileName : "",
            insertStartDate: config.map.get(AssetSearchKey.InsertDate) ? insertStartDate : "",
            insertEndDate: config.map.get(AssetSearchKey.InsertDate) ? insertEndDate : "",
            uploadTarget: config.map.get(AssetSearchKey.UploadTarget) ? uploadTarget : [],
          },
        });
      }
    }
  };

  const handleClearClick = () => {
    handleClear();
    setError({});
  };

  if (!config.map) return <></>;

  return (
    <div ref={ref}>
      <div className="flex justify-end bg-[#e4e4e4] w-[1200px]">
        <SettingIconButton className="w-[32px] p-[8px]" onClick={config.openSettingModal} />
      </div>
      <div className="search-box">
        <div className="grid gap-y-[16px]">
          <FormGrid className="grid-cols-3" display={config.show([AssetSearchKey.Category, AssetSearchKey.FileName])}>
            <FormGroup title={t("machines_category")} display={config.show([AssetSearchKey.Category])}>
              <MasterSelect
                prefix="category"
                isMulti={true}
                options={masters?.categories || []}
                value={categoryIds}
                onChange={changeCategory}
              />
            </FormGroup>
            <FormGroup title={t("file_name")} display={config.show([AssetSearchKey.FileName])}>
              <input
                data-test-id="text-import-file-name"
                type="text"
                className="form-control"
                value={fileName}
                onChange={(e) => changeFilename(e.target.value)}
              />
            </FormGroup>
          </FormGrid>
          <FormGrid
            className="grid-cols-3"
            display={config.show([AssetSearchKey.InsertDate, AssetSearchKey.UploadTarget])}
          >
            <FormGroup
              title={t("registered_day")}
              className="relative"
              display={config.show([AssetSearchKey.InsertDate])}
            >
              <div className="flex items-center space-between">
                <DatePicker
                  wrapperClassName="w-[133px]"
                  disabled={false}
                  selected={insertStartDate ? moment(insertStartDate).toDate() : null}
                  dateFormat="yyyy/MM/dd"
                  locale={t("calender_locale")}
                  onChange={handleChangeDateFrom}
                />
                <div className="w-30 ta-c">&nbsp;〜&nbsp;</div>
                <DatePicker
                  wrapperClassName="w-[133px]"
                  disabled={false}
                  selected={insertEndDate ? moment(insertEndDate).toDate() : null}
                  dateFormat="yyyy/MM/dd"
                  locale={t("calender_locale")}
                  onChange={handleChangeDateTo}
                />
              </div>
              {error.insertDate && (
                <div className="absolute form-error">
                  <p className="form-error-message w-300">{t("schedule_alert")}</p>
                </div>
              )}
            </FormGroup>
            <FormGroup
              className="relative"
              titleClassName="w-[116px]"
              title={t("upload_destination")}
              display={config.show([AssetSearchKey.UploadTarget])}
            >
              <label data-test-id="checkbox-search-panel-expired" className="ckbox">
                <input type="checkbox" checked={_.includes(uploadTarget, 1)} onChange={() => changeUploadTarget(1)} />
                <span>{t("board")}</span>
              </label>
              <label data-test-id="checkbox-search-panel-assigned" className="ckbox">
                <input type="checkbox" checked={_.includes(uploadTarget, 2)} onChange={() => changeUploadTarget(2)} />
                <span>{t("title")}</span>
              </label>
              <label data-test-id="checkbox-search-panel-not-completed" className="ckbox">
                <input type="checkbox" checked={_.includes(uploadTarget, 3)} onChange={() => changeUploadTarget(3)} />
                <span>{t("detail")}</span>
              </label>
              {error.upload && (
                <div className="absolute form-error">
                  <p className="form-error-message w-230">{t("set_upload_target")}</p>
                </div>
              )}
            </FormGroup>
          </FormGrid>
          <FormRow className="justify-between mt-[16px]">
            <Spacer x="210px" />
            <SearchConditionDisplayButton
              isExpanded={config.showAllConditions}
              onChange={config.toggleDisplayOptionalConditions}
            />
            <div className="flex gap-x-4">
              <button data-test-id="button-import-reset" className="btn btn-gray" onClick={handleClearClick}>
                {t("reset")}
              </button>
              <button data-test-id="button-import-search" className="btn btn-blue" onClick={handleSearchClick}>
                {t("search")}
              </button>
            </div>
          </FormRow>
        </div>
      </div>
      {config.showSettingModal && (
        <SearchConditionConfigModal<AssetSearchKeyType>
          title={t("search_condition_config")}
          configMap={config.map}
          definitions={assetSearchConditionDefinitions}
          onClose={config.closeSettingModal}
          onSubmit={(c) => config.saveSearchItemConfig(c)}
        />
      )}
    </div>
  );
});

AssetSearchArea.displayName = "AssetSearchArea";

export default AssetSearchArea;
