import { connect } from "react-redux";

import actions from "../../actions";
import SearchPanel from "../../components/system/SearchPanel";

const mapStateToProps = (state) => {
  let areas = [];
  let devices = [];
  let categories = [];
  let facility_managements = [];
  let construction_managements = [];
  let primary_charges = [];
  let others = [];

  if (state.construction.masters) {
    areas = state.construction.masters.areas;
    devices = state.construction.masters.devices;
    categories = state.construction.masters.categories;
    facility_managements = state.construction.masters.facility_managements;
    construction_managements = state.construction.masters.construction_managements;
    primary_charges = state.construction.masters.primary_charges;
    others = state.construction.masters.others;
  }

  return {
    masters: {
      areas,
      devices,
      categories,
      facility_managements,
      construction_managements,
      primary_charges,
      others,
    },
    areaIds: state.system.areaIds,
    deviceIds: state.system.deviceIds,
    categoryId: state.system.categoryId,
    facilityManagementIds: state.system.facilityManagementIds,
    constructionManagementIds: state.system.constructionManagementIds,
    primaryChargeIds: state.system.primaryChargeIds,
    otherIds: state.system.otherIds,
    systemName: state.system.systemName,
    note: state.system.note,
    scheStartDate: state.system.scheStartDate,
    scheEndDate: state.system.scheEndDate,
    closeStartDate: state.system.closeStartDate,
    closeEndDate: state.system.closeEndDate,
    totalTest: state.system.totalTest,
    preparation: state.system.preparation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeArea: (items) => dispatch(actions.system.changeArea(items)),
    changeDevice: (items) => dispatch(actions.system.changeDevice(items)),
    changeCategory: (item) => dispatch(actions.system.changeCategory(item)),
    changeFacilityManagement: (items) => dispatch(actions.system.changeFacilityManagement(items)),
    changeConstructionManagement: (items) => dispatch(actions.system.changeConstructionManagement(items)),
    changePrimaryCharge: (items) => dispatch(actions.system.changePrimaryCharge(items)),
    changeOther: (items) => dispatch(actions.system.changeOther(items)),
    changeSystemName: (systemName) => dispatch(actions.system.changeSystemName(systemName)),
    changeNote: (note) => dispatch(actions.system.changeNote(note)),
    changeScheduledDateFrom: (dateFrom) => dispatch(actions.system.changeScheduledDateFrom(dateFrom)),
    changeScheduledDateTo: (dateTo) => dispatch(actions.system.changeScheduledDateTo(dateTo)),
    changeCloseDateFrom: (dateFrom) => dispatch(actions.system.changeCloseDateFrom(dateFrom)),
    changeCloseDateTo: (dateTo) => dispatch(actions.system.changeCloseDateTo(dateTo)),
    changeTotalTest: (item) => dispatch(actions.system.changeTotalTest(item)),
    changePreparation: (item) => dispatch(actions.system.changePreparation(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SearchPanel);
