export type Status = {
  status_id: number;
  status_name: string;
};

export type FileType = {
  type_id: number;
  type_name: string;
};

export type ErrorState = {
  import_start_date?: string;
};

export type ImportSearchParams = {
  categoryId?: number;
  fileName?: string;
  statusId?: number;
  import_start_date?: string;
  import_end_date?: string;
  userId?: number;
  fileType?: number;
};

export const ImportSearchKey = {
  Category: "category_id",
  FileName: "file_name",
  Status: "status",
  ImportDate: "import_date",
  User: "user_id",
  FileType: "file_type",
} as const;

export type ImportSearchKeyType = (typeof ImportSearchKey)[keyof typeof ImportSearchKey];
