import { connect } from "react-redux";

import actions from "../../actions/index";
import ScheduleChiba from "../../components/list/ScheduleChiba";

import { setConditions } from "@/actions/approval";
import { ConstructionExtensionConfig } from "@/models/constructionExtensions";

const mapStateToProps = (state) => {
  let config: ConstructionExtensionConfig = {};

  if (state.construction.extensions) {
    const extension = state.construction.extensions.extensions.filter((item) => item.extension_type === 6);
    if (extension.length > 0) config = JSON.parse(extension[0].config);
  }

  return {
    masters: state.construction.masters,
    areaIds: state.schedule_chiba.areaIds,
    deviceIds: state.schedule_chiba.deviceIds,
    categoryIds: state.schedule_chiba.categoryIds,
    facilityManagementIds: state.schedule_chiba.facilityManagementIds,
    constructionManagementIds: state.schedule_chiba.constructionManagementIds,
    primaryChargeIds: state.schedule_chiba.primaryChargeIds,
    otherIds: state.schedule_chiba.otherIds,
    field1Params: state.schedule_chiba.field1Params,
    companyParams: state.schedule_chiba.companyParams,
    userParams: state.schedule_chiba.userParams,
    groupParams: state.schedule_chiba.groupParams,
    approveParams: state.schedule_chiba.approveParams,
    checkpoint1: state.schedule_chiba.checkpoint1,
    checkpoint2: state.schedule_chiba.checkpoint2,
    checkpoint3: state.schedule_chiba.checkpoint3,
    checkpoint4: state.schedule_chiba.checkpoint4,
    checkpoint5: state.schedule_chiba.checkpoint5,
    checkpoint6: state.schedule_chiba.checkpoint6,
    filter: state.schedule_chiba.filter,
    requestUser: state.schedule_chiba.requestUser,
    field5: state.schedule_chiba.field5,
    scheduleDate: state.schedule_chiba.scheduleDate,
    items: state.schedule_chiba.items,
    chiba: state.schedule_chiba.chiba,
    bulkRoles: state.schedule_chiba.bulkRoles,
    firstScheduleDate: state.schedule_chiba.firstScheduleDate,
    scheduleUpdateDate: state.schedule_chiba.scheduleUpdateDate,
    switcherStatus: state.schedule_chiba.switcherStatus,
    fetching: state.schedule_chiba.fetching,
    isError: state.schedule_chiba.isError,
    validated: state.schedule_chiba.validated,
    roles: state.app.roles,
    config,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeArea: (items) => dispatch(actions.schedule_chiba.changeArea(items)),
    changeDevice: (items) => dispatch(actions.schedule_chiba.changeDevice(items)),
    changeCategory: (items) => dispatch(actions.schedule_chiba.changeCategory(items)),
    changeFacilityManagement: (items) => dispatch(actions.schedule_chiba.changeFacilityManagement(items)),
    changeConstructionManagement: (items) => dispatch(actions.schedule_chiba.changeConstructionManagement(items)),
    changePrimaryCharge: (items) => dispatch(actions.schedule_chiba.changePrimaryCharge(items)),
    changeOther: (items) => dispatch(actions.schedule_chiba.changeOther(items)),
    changeConstructionCompany: (items) => dispatch(actions.schedule_chiba.changeConstructionCompany(items)),
    changeField1: (items) => dispatch(actions.schedule_chiba.changeField1(items)),
    changeUser: (items) => dispatch(actions.schedule_chiba.changeUser(items)),
    changeGroup: (items) => dispatch(actions.schedule_chiba.changeGroup(items)),
    changeApprove: (items) => dispatch(actions.schedule_chiba.changeApprove(items)),
    changeCheckpoint1: (items) => dispatch(actions.schedule_chiba.changeCheckpoint1(items)),
    changeCheckpoint2: (items) => dispatch(actions.schedule_chiba.changeCheckpoint2(items)),
    changeCheckpoint3: (items) => dispatch(actions.schedule_chiba.changeCheckpoint3(items)),
    changeCheckpoint4: (items) => dispatch(actions.schedule_chiba.changeCheckpoint4(items)),
    changeCheckpoint5: (items) => dispatch(actions.schedule_chiba.changeCheckpoint5(items)),
    changeCheckpoint6: (items) => dispatch(actions.schedule_chiba.changeCheckpoint6(items)),
    changeFilter: (items) => dispatch(actions.schedule_chiba.changeFilter(items)),
    changeRequestUser: (items) => dispatch(actions.schedule_chiba.changeRequestUser(items)),
    changeField5: (items) => dispatch(actions.schedule_chiba.changeField5(items)),
    changeScheduleDate: (scheduleDate) => dispatch(actions.schedule_chiba.changeScheduleDate(scheduleDate)),
    search: (params, callback) => dispatch(actions.schedule_chiba.search(params, callback)),
    getOptions: (callback) => dispatch(actions.schedule_chiba.getOptions(callback)),
    clearSearch: (target) => dispatch(actions.schedule_chiba.clearSearch(target)),
    revertLocalCondition: () => dispatch(actions.schedule_chiba.revertLocalCondition()),
    download: (params, filetype, callback, fallback) =>
      dispatch(actions.schedule_chiba.download(params, filetype, callback, fallback)),
    updateItem: (data, handleSuccess, handleCancel) =>
      dispatch(actions.schedule_chiba.updateItem(data, handleSuccess, handleCancel)),
    getHistories: (scheduleId, columnTarget, callback) =>
      dispatch(actions.schedule_chiba.getScheduleHistories(scheduleId, columnTarget, callback)),
    showAlert: (title, messages, okClickHandler) => dispatch(actions.app.showAlert(title, messages, okClickHandler)),
    hideAlert: () => dispatch(actions.app.hideAlert()),
    setConditions: (conditions) => dispatch(actions.schedule_chiba.setConditions(conditions)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleChiba);
