import React, { FC, KeyboardEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  currentPage: number;
  maxPage: number;
  onPrev: () => void;
  onNext: () => void;
  onJump: (page: number) => void;
  rightComponent?: React.ReactNode;
};

export const FooterPager: FC<Props> = (props) => {
  const { t } = useTranslation();

  const [input, setInput] = useState<string>(props.currentPage.toString());

  useEffect(() => {
    setInput(props.currentPage.toString());
  }, [props.currentPage]);

  const handleChange = (e) => setInput(e.target.value);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      // Enterキー押したら指定したページにジャンプする
      if (e.key !== "Enter") return;
      // 空の場合は何もしない
      if (!input) {
        return e.currentTarget.blur();
      }
      // 全角文字を変換
      const value = input.replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0));
      // 整数で有効なページ番号かチェック
      const num = Number(value);
      if (!Number.isInteger(num) || num < 1 || props.maxPage < num || num === props.currentPage) {
        return e.currentTarget.blur();
      }
      props.onJump(Number(num));
    },
    [input, props.maxPage, props.currentPage]
  );

  const handleBlur = () => {
    setInput(props.currentPage.toString());
  };

  return (
    <div className="pagination">
      <div className="null-box grid1">
        {1 < props.currentPage && (
          <div className="left-arrow-box" onClick={props.onPrev}>
            <span>{t("prev")}</span>
          </div>
        )}
      </div>
      <span className="page-status">
        <input
          type="text"
          value={input}
          maxLength={4}
          className="w-[30px]"
          onFocus={() => setInput("")}
          onChange={handleChange}
          onKeyDown={(e) => handleKeyDown(e)}
          onBlur={handleBlur}
        />
        &nbsp;&#047;&nbsp;
        {props.maxPage}
      </span>
      <div className="null-box grid3">
        {props.currentPage < props.maxPage && (
          <div className="right-arrow-box" onClick={props.onNext}>
            <span>{t("next")}</span>
          </div>
        )}
      </div>
      {props.rightComponent}
    </div>
  );
};
