import React, { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Select from "../common/Select";

import { FormGrid, FormGroup, FormRow, Spacer } from "@/components/common";
import { SearchConditionConfigModal } from "@/components/common/SearchCondition/SearchConditionConfigModal";
import { SearchConditionDisplayButton } from "@/components/common/SearchCondition/SearchConditionDisplayButton";
import { SettingIconButton } from "@/components/common/SettingIconButton";
import { useSearchConditionConfig } from "@/hooks/useSearchConditionConfig";
import { ConstructionMasters } from "@/models/masters";
import { SearchConditionConfigDefinition, ScreenName } from "@/models/screenConfig";
import { SummarySearchKey, SummarySearchKeyType, SummarySearchParams } from "@/models/summary";

type Props = {
  masters: ConstructionMasters;
  areaIds: number[];
  deviceIds: number[];
  categoryIds: number[];
  facilityManagementIds: number[];
  constructionManagementIds: number[];
  primaryChargeIds: number[];
};

type DispatchProps = {
  changeArea: (value: number[]) => void;
  changeDevice: (value: number[]) => void;
  changeCategory: (value: number[]) => void;
  changeFacilityManagement: (value: number[]) => void;
  changeConstructionManagement: (value: number[]) => void;
  changePrimaryCharge: (value: number[]) => void;
  handleSearch: (option?: { params?: SummarySearchParams }) => void;
  handleReset: () => void;
};

type SearchPanelProps = Props & DispatchProps;

const SearchPanel = forwardRef<HTMLDivElement, SearchPanelProps>(
  (
    {
      masters,
      areaIds,
      deviceIds,
      categoryIds,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      changeArea,
      changeDevice,
      changeCategory,
      changeFacilityManagement,
      changeConstructionManagement,
      changePrimaryCharge,
      handleReset,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();

    // データ取り込み画面の検索条件設定の定義
    const summarySearchConditionDefinitions = useMemo<SearchConditionConfigDefinition<SummarySearchKeyType>[]>(
      () => [
        {
          itemsPerLine: 3,
          items: [
            { key: SummarySearchKey.Area, label: t("area"), required: false },
            { key: SummarySearchKey.Device, label: t("device"), required: false },
            { key: SummarySearchKey.Category, label: t("machines_category"), required: false },
            { key: SummarySearchKey.FacilityManagement, label: t("facility_management"), required: false },
            { key: SummarySearchKey.ConstructionManagement, label: t("construction_management"), required: false },
            { key: SummarySearchKey.PrimaryCharge, label: t("primary_charge"), required: false },
          ],
        },
      ],
      [t]
    );

    // 検索条件設定
    const config = useSearchConditionConfig<SummarySearchKeyType>({
      screenName: ScreenName.SUMMARY,
      requiredKeys: summarySearchConditionDefinitions.flatMap((v) =>
        v.items.filter((item) => item.required).map((item) => item.key)
      ),
    });

    const handleSearch = (e: React.MouseEvent) => {
      e.preventDefault();

      if (config.showAllConditions) {
        props.handleSearch();
      } else {
        // 検索条件設定で表示している検索条件のみ有効とする
        props.handleSearch({
          params: {
            areaIds: config.map.get(SummarySearchKey.Area) ? areaIds : [],
            deviceIds: config.map.get(SummarySearchKey.Device) ? deviceIds : [],
            categoryIds: config.map.get(SummarySearchKey.Category) ? categoryIds : [],
            facilityManagementIds: config.map.get(SummarySearchKey.FacilityManagement) ? facilityManagementIds : [],
            constructionManagementIds: config.map.get(SummarySearchKey.ConstructionManagement)
              ? constructionManagementIds
              : [],
            primaryChargeIds: config.map.get(SummarySearchKey.PrimaryCharge) ? primaryChargeIds : [],
          },
        });
      }
    };

    if (!config.map) return <></>;

    return (
      <div ref={ref}>
        <div className="flex justify-end bg-[#e4e4e4] w-[1200px]">
          <SettingIconButton className="w-[32px] p-[8px]" onClick={config.openSettingModal} />
        </div>
        <div className="search-box">
          <div className="grid">
            <FormGrid
              className="grid-cols-3"
              display={config.show([
                SummarySearchKey.Area,
                SummarySearchKey.Device,
                SummarySearchKey.Category,
                SummarySearchKey.FacilityManagement,
                SummarySearchKey.ConstructionManagement,
                SummarySearchKey.PrimaryCharge,
              ])}
            >
              <FormGroup title={t("area")} className="w-230" display={config.show([SummarySearchKey.Area])}>
                <Select prefix="area" isMulti={true} options={masters.areas} value={areaIds} onChange={changeArea} />
              </FormGroup>
              <FormGroup title={t("device")} className="w-260" display={config.show([SummarySearchKey.Device])}>
                <Select
                  prefix="device"
                  isMulti={true}
                  options={masters.devices}
                  value={deviceIds}
                  onChange={changeDevice}
                />
              </FormGroup>
              <FormGroup
                title={t("machines_category")}
                className="w-290"
                display={config.show([SummarySearchKey.Category])}
              >
                <Select
                  prefix="category"
                  isMulti={true}
                  options={masters.categories}
                  value={categoryIds}
                  onChange={changeCategory}
                />
              </FormGroup>
              <FormGroup
                title={t("facility_management")}
                className="w-230"
                display={config.show([SummarySearchKey.FacilityManagement])}
              >
                <Select
                  prefix="facility_management"
                  isMulti={true}
                  options={masters.facility_managements}
                  value={facilityManagementIds}
                  onChange={changeFacilityManagement}
                />
              </FormGroup>
              <FormGroup
                title={t("construction_management")}
                className="w-260"
                display={config.show([SummarySearchKey.ConstructionManagement])}
              >
                <Select
                  prefix="construction_management"
                  isMulti={true}
                  options={masters.construction_managements}
                  value={constructionManagementIds}
                  onChange={changeConstructionManagement}
                />
              </FormGroup>
              <FormGroup
                title={t("primary_charge")}
                className="w-290"
                display={config.show([SummarySearchKey.PrimaryCharge])}
              >
                <Select
                  prefix="primary_charge"
                  isMulti={true}
                  options={masters.primary_charges}
                  value={primaryChargeIds}
                  onChange={changePrimaryCharge}
                />
              </FormGroup>
            </FormGrid>
            <FormRow className="justify-between mt-[16px]">
              <Spacer x="210px" />
              <SearchConditionDisplayButton
                isExpanded={config.showAllConditions}
                onChange={config.toggleDisplayOptionalConditions}
              />
              <div className="flex gap-x-4">
                <button className="btn btn-gray" onClick={handleReset}>
                  {t("reset")}
                </button>
                <button className="btn btn-blue" onClick={handleSearch}>
                  {t("search")}
                </button>
              </div>
            </FormRow>
          </div>
        </div>
        {config.showSettingModal && (
          <SearchConditionConfigModal<SummarySearchKeyType>
            title={t("search_condition_config")}
            configMap={config.map}
            definitions={summarySearchConditionDefinitions}
            onClose={config.closeSettingModal}
            onSubmit={(c) => config.saveSearchItemConfig(c)}
          />
        )}
      </div>
    );
  }
);

SearchPanel.displayName = "SearchPanel";

export default SearchPanel;
