import _ from "lodash";
import moment from "moment";

import * as constants from "../constants/index";
import restoreState from "../lib/restoreState";
import storageManager from "../lib/storageManager";

const getInitialSearchConditions = (onlyLocal = false) => {
  const urlState = restoreState();
  let areaIds = [];
  let deviceIds = [];
  let categoryId = 0;
  let facilityManagementIds = [];
  let constructionManagementIds = [];
  let primaryChargeIds = [];
  let otherIds = [];
  let processMajorClassIds = [];
  let processMiddleClassIds = [];
  let companyIds = [];
  let scheduleEndDate = "";
  let checkpointIds = [];
  let itemText = "";
  let processText = "";
  let filter = [];

  if (storageManager.getConstructionItem("approvalSearchParams") !== null) {
    const params = JSON.parse(storageManager.getConstructionItem("approvalSearchParams"));

    areaIds = params.areaIds;
    deviceIds = params.deviceIds;
    categoryId = params.categoryId;
    facilityManagementIds = params.facilityManagementIds;
    constructionManagementIds = params.constructionManagementIds;
    primaryChargeIds = params.primaryChargeIds;
    otherIds = params.otherIds;
    processMajorClassIds = params.processMajorClassIds;
    processMiddleClassIds = params.processMiddleClassIds;
    companyIds = params.companyIds;
    scheduleEndDate = params.scheduleEndDate;
    checkpointIds = params.checkpointIds;
    itemText = params.itemText;
    processText = params.processText;
    filter = params.filter;
  }

  if (!onlyLocal && urlState && urlState.menu === "approval") {
    areaIds = urlState.area_ids
      ? decodeURIComponent(urlState.area_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    deviceIds = urlState.device_ids
      ? decodeURIComponent(urlState.device_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    categoryId = urlState.category_id ? Number(urlState.category_id) : 0;
    facilityManagementIds = urlState.facility_management_ids
      ? decodeURIComponent(urlState.facility_management_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    constructionManagementIds = urlState.construction_management_ids
      ? decodeURIComponent(urlState.construction_management_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    primaryChargeIds = urlState.primary_charge_ids
      ? decodeURIComponent(urlState.primary_charge_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    otherIds = urlState.other_ids
      ? decodeURIComponent(urlState.other_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    processMajorClassIds = urlState.process_major_class_ids
      ? decodeURIComponent(urlState.process_major_class_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    processMiddleClassIds = urlState.process_middle_class_ids
      ? decodeURIComponent(urlState.process_middle_class_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    companyIds = urlState.company_ids
      ? decodeURIComponent(urlState.company_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    scheduleEndDate = urlState.schedule_end_date ? decodeURIComponent(urlState.schedule_end_date) : "";
    checkpointIds = urlState.checkpoint_ids
      ? decodeURIComponent(urlState.checkpoint_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    itemText = urlState.item_text ? decodeURIComponent(urlState.item_text) : "";
    processText = urlState.process_text ? decodeURIComponent(urlState.process_text) : "";
    filter = urlState.filter
      ? decodeURIComponent(urlState.filter)
          .split(",")
          .map((f) => Number(f))
      : [];
  }

  if (scheduleEndDate) {
    const date = moment(scheduleEndDate, "YYYY/MM/DD").format("YYYY-MM-DD").toString();
    if (date === "Invalid date") {
      scheduleEndDate = "";
    } else if (scheduleEndDate !== date) {
      scheduleEndDate = date;
    }
  }

  const filterMaster = [1, 2, 3, 4, 5];
  filter = filter.filter((f) => filterMaster.indexOf(f) >= 0);

  return {
    areaIds,
    deviceIds,
    categoryId,
    facilityManagementIds,
    constructionManagementIds,
    primaryChargeIds,
    otherIds,
    processMajorClassIds,
    processMiddleClassIds,
    companyIds,
    scheduleEndDate,
    checkpointIds,
    itemText,
    processText,
    filter,
  };
};

const approval = (
  state = {
    areaIds: [],
    deviceIds: [],
    categoryId: 0,
    facilityManagementIds: [],
    constructionManagementIds: [],
    primaryChargeIds: [],
    otherIds: [],
    processMajorClassIds: [],
    processMiddleClassIds: [],
    companyIds: [],
    scheduleEndDate: "",
    checkpointIds: [],
    itemText: "",
    processText: "",
    filter: [],
    items: [],
    switcherStatus: -1,
    fetching: false,
    isError: false,
    validated: false,
  },
  action
) => {
  switch (action.type) {
    case constants.APP_CONTENTS_READY: {
      return {
        ...state,
        ...getInitialSearchConditions(),
      };
    }
    case constants.APPROVAL_CHANGE_AREA: {
      return {
        ...state,
        areaIds: action.payload,
      };
    }
    case constants.APPROVAL_CHANGE_DEVICE: {
      return {
        ...state,
        deviceIds: action.payload,
      };
    }
    case constants.APPROVAL_CHANGE_FACILITY_MANAGEMENT: {
      return {
        ...state,
        facilityManagementIds: action.payload,
      };
    }
    case constants.APPROVAL_CHANGE_CATEGORY: {
      return {
        ...state,
        categoryId: action.payload,
      };
    }
    case constants.APPROVAL_CHANGE_CONSTRUCTION_MANAGEMENT: {
      return {
        ...state,
        constructionManagementIds: action.payload,
      };
    }
    case constants.APPROVAL_CHANGE_PRIMARY_CHARGE: {
      return {
        ...state,
        primaryChargeIds: action.payload,
      };
    }
    case constants.APPROVAL_CHANGE_OTHER: {
      return {
        ...state,
        otherIds: action.payload,
      };
    }
    case constants.APPROVAL_CHANGE_PROCESS_MAJOR_CLASS: {
      return {
        ...state,
        processMajorClassIds: action.payload,
      };
    }
    case constants.APPROVAL_CHANGE_PROCESS_MIDDLE_CLASS: {
      return {
        ...state,
        processMiddleClassIds: action.payload,
      };
    }
    case constants.APPROVAL_CHANGE_COMPANY: {
      return {
        ...state,
        companyIds: action.payload,
      };
    }
    case constants.APPROVAL_CHANGE_SCHEDULE_DATE: {
      return {
        ...state,
        scheduleEndDate: action.payload,
      };
    }
    case constants.APPROVAL_CHANGE_CHECKPOINT: {
      return {
        ...state,
        checkpointIds: action.payload,
      };
    }
    case constants.APPROVAL_CHANGE_ITEM_TEXT: {
      return {
        ...state,
        itemText: action.payload,
      };
    }
    case constants.APPROVAL_CHANGE_PROCESS_TEXT: {
      return {
        ...state,
        processText: action.payload,
      };
    }
    case constants.APPROVAL_CHANGE_FILTER: {
      let filter = [...state.filter];

      if (_.includes(filter, action.payload)) {
        filter = filter.filter((f) => f !== action.payload);
      } else {
        filter = [...filter, action.payload];
      }

      return {
        ...state,
        filter,
      };
    }
    case constants.APPROVAL_BEGIN_SEARCH: {
      return {
        ...state,
        fetching: true,
        isError: false,
      };
    }
    case constants.APPROVAL_END_SEARCH: {
      return {
        ...state,
        fetching: false,
        items: action.payload.list,
      };
    }
    case constants.APPROVAL_CLEAR_SEARCH: {
      return {
        ...state,
        areaIds: [],
        deviceIds: [],
        categoryId: 0,
        facilityManagementIds: [],
        constructionManagementIds: [],
        primaryChargeIds: [],
        otherIds: [],
        processMajorClassIds: [],
        processMiddleClassIds: [],
        companyIds: [],
        scheduleEndDate: "",
        checkpointIds: [],
        itemText: "",
        processText: "",
        filter: [],
      };
    }
    case constants.COMMON_BEGIN_CHANGE_TASK_STATUS: {
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.task_id === action.payload.task_id) {
            return {
              ...item,
              processing: true,
              error: false,
            };
          }

          return item;
        }),
      };
    }
    case constants.COMMON_END_CHANGE_TASK_STATUS: {
      const { task_id, valid_flg, status, schedule_type, display_status, timestamp } = action.payload.task;

      return {
        ...state,
        items: state.items.map((item) => {
          if (item.task_id === task_id) {
            return {
              ...item,
              valid_flg,
              status,
              schedule_type,
              display_status,
              timestamp,
              processing: false,
              error: false,
              ahead_days: action.payload.ahead_days,
            };
          }

          return item;
        }),
      };
    }
    case constants.COMMON_ERROR_CHANGE_TASK_STATUS: {
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.task_id === action.payload.task_id) {
            return {
              ...item,
              processing: false,
              error: true,
            };
          }

          return item;
        }),
      };
    }
    case constants.MATRIX_END_UPDATE_TASK_DETAIL: {
      const { itemId, taskId } = action.payload;
      const { schedule_end_date, display_status, schedule_type, status, valid_flg, timestamp } = action.payload.data;

      return {
        ...state,
        items: state.items.map((item) => {
          if (item.task_id === taskId && item.item_id === itemId) {
            return {
              ...item,
              schedule_end_date,
              display_status,
              schedule_type,
              status,
              valid_flg,
              timestamp,
            };
          }

          return item;
        }),
      };
    }
    case constants.APPROVAL_CHANGE_SWITCHER_STATUS: {
      return {
        ...state,
        switcherStatus: action.payload,
      };
    }
    case constants.APPROVAL_SET_VALIDATED: {
      return {
        ...state,
        validated: action.payload,
      };
    }
    case constants.APPROVAL_REVERT_CONDITION: {
      return {
        ...state,
        ...getInitialSearchConditions(true),
      };
    }
    case constants.APPROVAL_SET_CONDITIONS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case constants.APP_SHOW_ERROR: {
      if (action.errorFrom === "approval") {
        return {
          ...state,
          fetching: false,
          isError: true,
        };
      }

      return state;
    }
    default:
      return state;
  }
};

export default approval;
