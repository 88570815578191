export type Asset = {
  upload_target: number;
  information_file_id?: number;
  item_file_id?: number;
  task_file_id?: number;
  title: string;
  file_name: string;
  category_name?: string;
  item_name?: string;
  process_name?: string;
  insert_date: string;
  insert_user_id: number;
  insert_user_name: string;
  roles: number[];
};

export type AssetSearchParams = {
  categoryIds: number[];
  insertStartDate: string;
  insertEndDate: string;
  fileName: string;
  uploadTarget: number[];
};

export const AssetSearchKey = {
  Category: "categoryIds",
  FileName: "fileName",
  InsertDate: "insertDate",
  UploadTarget: "uploadTarget",
};

export type AssetSearchKeyType = (typeof AssetSearchKey)[keyof typeof AssetSearchKey];
