export type SystemSearchParams = {
  areaIds?: number[];
  deviceIds?: number[];
  facilityManagementIds?: number[];
  systemName?: string;
  constructionManagementIds?: number[];
  primaryChargeIds?: number[];
  otherIds?: number[];
  note?: string;
  scheStartDate?: string;
  scheEndDate?: string;
  closeStartDate?: string;
  closeEndDate?: string;
  categoryId?: number;
  totalTest?: number;
  preparation?: boolean;
};

export const SystemSearchKey = {
  Area: "area_ids",
  Device: "device_ids",
  FacilityManagement: "facility_management_ids",
  SystemName: "system_name",
  ConstructionManagement: "construction_management_ids",
  PrimaryCharge: "primary_charge_ids",
  Other: "other_ids",
  Note: "note",
  ScheduleDate: "schedule_date",
  CloseDate: "close_date",
  Category: "category_id",
  TotalTest: "total_test",
  Preparation: "preparation",
} as const;

export type SystemSearchKeyType = (typeof SystemSearchKey)[keyof typeof SystemSearchKey];
