export type Summary = {
  area_id: number;
  area_name: string;
  category_summaries: { category_id: number }[];
  construction_management_id: number;
  construction_management_name: string;
  device_code: string;
  device_id: number;
  device_name: string;
  facility_management_id: number;
  facility_management_name: string;
  primary_charge_id: number;
  primary_charge_name: string;
};

export type Category = {
  category_id: number;
  category_name: string;
};

export type SummarySearchParams = {
  areaIds?: number[];
  deviceIds?: number[];
  categoryIds?: number[];
  facilityManagementIds?: number[];
  constructionManagementIds?: number[];
  primaryChargeIds?: number[];
};

export const SummarySearchKey = {
  Area: "area_ids",
  Device: "device_ids",
  Category: "category_ids",
  FacilityManagement: "facility_management_ids",
  ConstructionManagement: "construction_management_ids",
  PrimaryCharge: "primary_charge_ids",
} as const;

export type SummarySearchKeyType = (typeof SummarySearchKey)[keyof typeof SummarySearchKey];
