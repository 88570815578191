import { connect } from "react-redux";

import actions from "../../actions";
import SearchPanel from "../../components/import/SearchPanel";

const mapStateToProps = (state) => {
  return {
    masters: state.construction.masters,
    categoryId: state.dataimport.categoryId,
    fileName: state.dataimport.fileName,
    statusId: state.dataimport.statusId,
    import_start_date: state.dataimport.import_start_date,
    import_end_date: state.dataimport.import_end_date,
    userId: state.dataimport.userId,
    fileType: state.dataimport.fileType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeUser: (userId) => dispatch(actions.dataimport.changeUser(userId)),
    changeCategory: (categoryId) => dispatch(actions.dataimport.changeCategory(categoryId)),
    resetSearch: () => dispatch(actions.dataimport.resetSearch()),
    changeFilename: (fileName) => dispatch(actions.dataimport.changeFilename(fileName)),
    changeStatus: (statusId) => dispatch(actions.dataimport.changeStatus(statusId)),
    changeFiletype: (fileType) => dispatch(actions.dataimport.changeFiletype(fileType)),
    changeDateFrom: (dateFrom) => dispatch(actions.dataimport.changeDateFrom(dateFrom)),
    changeDateTo: (dateTo) => dispatch(actions.dataimport.changeDateTo(dateTo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SearchPanel);
