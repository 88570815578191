import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import actions from "@/actions";
import FileItem from "@/components/FileItem";
import { UploadFileMode } from "@/models/files";
import { RootState } from "@/reducers/types";
import { Machine, MachineStatusLabel } from "@/sx-layout/components/plotmap/models";
import { MachineType } from "@/sx-layout/components/settings/machineSetting/models";

type Props = {
  data: Machine;
  leftOrRight: "left-aligned" | "right-aligned";
};

export const TooltipMachine: FC<Props> = ({ data, leftOrRight }) => {
  const { t } = useTranslation();
  const machineTypes = useSelector<RootState, MachineType[]>((state) => state.machine.machineTypes);

  const dispatch = useDispatch();

  const hasAttachedFile = data.files && data.files.length;

  const renderFileList = () => {
    return (
      <div className="file-upload-box w-[100%]">
        <ul className="form-file-list">
          {data.files
            .filter((f) => f.mode !== UploadFileMode.DELETE)
            .map((file, index) => (
              <FileItem
                // @ts-ignore
                additionalFilenameClass="text-[1rem] !pl-0 mw-90"
                additionalImageAreaClass="!text-left"
                key={index}
                // @ts-ignore
                kind="machines"
                file={file}
                onDownload={(kind, fileId, callback) =>
                  dispatch(actions.common.downloadAttachmentFile(kind, fileId, callback))
                }
              />
            ))}
        </ul>
      </div>
    );
  };

  return (
    <div
      style={
        leftOrRight === "left-aligned"
          ? {
              left: "15px",
            }
          : {
              right: "15px",
            }
      }
      className={`
        sx-layout-tooltip
        border-4
        border-[#dea321]
        border-solid
        text-[13px]
        shadow-[0_4px_4px_0px_rgba(0,0,0,0.3)]
        rounded-lg
        p-3
        bg-[#fff]
        z-10
      `}
      id="tooltip-machine"
    >
      <img
        style={{ position: "absolute", top: "10px", right: "10px", width: "20px", height: "20px" }}
        src="./img/tooltip_machine.svg"
      />
      {data.keepout_area_id && (
        <img
          style={{ position: "absolute", top: "30px", right: "10px", width: "20px", height: "20px" }}
          src="./img/icon-link.svg"
        />
      )}
      <div className="flex mb-10">
        <span className="w-[60px] text-right font-bold mr-2">No</span>
        <span className="w-[180px] text-left">{data.no}</span>
      </div>
      <div className="flex mb-10">
        <span className="w-[60px] text-right font-bold mr-2">{t("heavy_machine")}</span>
        <span className="w-[180px] text-left">{data.machine_type_name}</span>
      </div>
      <div className="flex mb-10">
        <span className="w-[60px] text-right font-bold mr-2">{t("radio")}</span>
        <span className="w-[180px] text-left">{data.wireless_flg === true ? t("use") : t("disuse")}</span>
      </div>
      <div className="flex mb-10">
        <span className="w-[60px] text-right font-bold mr-2">{t("jib_extension")}</span>
        <span className="w-[180px] text-left">{data.jib_flg === true ? t("extension") : t("not_extension")}</span>
      </div>
      <div className="flex mb-10">
        <span className="w-[60px] text-right font-bold mr-2">{t("substitute")}</span>
        <span className="w-[180px] text-left">
          {machineTypes.find((machineType: MachineType) => machineType.machine_type_id === data.substitute)
            ?.machine_type_name ?? t("disuse")}
        </span>
      </div>
      <div className="flex mb-10">
        <span className="w-[60px] text-right font-bold mr-2">{t("operation_time")}</span>
        <span className="w-[180px] text-left">{`${data.use_start_hour}~${data.use_end_hour}`}</span>
      </div>
      <div className="flex mb-10">
        <span className="w-[60px] text-right font-bold mr-2">{t("content")}</span>
        <span className="w-[180px] text-left">{data.work_content}</span>
      </div>
      <div className="flex mb-10">
        <span className="w-[60px] text-right font-bold mr-2">{t("belonging")}</span>
        <span className="w-[180px] text-left">{data.company_name}</span>
      </div>
      <div className="flex mb-10">
        <span className="w-[60px] text-right font-bold mr-2">{t("machine_user")}</span>
        <span className="w-[180px] text-left">{data.charge_user_name}</span>
      </div>
      <div className="flex mb-10">
        <span className="w-[60px] text-right font-bold mr-2">{t("contact")}</span>
        <span className="w-[180px] text-left">{data.phone_number}</span>
      </div>
      <div className="flex mb-10">
        <span className="w-[60px] text-right font-bold mr-2">{t("machine_status")}</span>
        <span className="w-[180px] text-left">{t(MachineStatusLabel[data.status])}</span>
      </div>
      <div className="flex mb-10">
        <span className="w-[60px] text-right font-bold mr-2">{t("note")}</span>
        <span className="w-[180px] text-left">{data.note}</span>
      </div>
      {hasAttachedFile ? (
        <div className="flex pt-[10px] flex-col">
          <span className="w-[80px] text-right font-bold mr-2">{t("attached_file")}</span>
          <span className="text-left mt-4">{renderFileList()}</span>
        </div>
      ) : (
        <div className="flex pt-[10px]">
          <span className="w-[80px] text-right font-bold mr-2">{t("attached_file")}</span>
          <span className="text-left">{data.file_num ? `${data.file_num}${t("unit")}` : t("no_attached_file")}</span>
        </div>
      )}
    </div>
  );
};
