import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { useTranslation, withTranslation } from "react-i18next";

import * as util from "../../lib/matrix";
import Modal from "../Modal";
import ChargeSelect from "../common/ChargeSelect";
import Select from "../common/Select";

import { CheckBox, FormGrid, FormGroup, FormRow } from "@/components/common";
import { SearchConditionConfigModal } from "@/components/common/SearchCondition/SearchConditionConfigModal";
import { SearchConditionDisplayButton } from "@/components/common/SearchCondition/SearchConditionDisplayButton";
import { Spacer } from "@/components/common/Spacer";
import { useSearchConditionConfig } from "@/hooks/useSearchConditionConfig";
import { ConstructionMasters } from "@/models/masters";
import { MatrixSearchKey, MatrixSearchKeyType, MatrixSearchParams } from "@/models/matrix";
import { ScreenName, SearchConditionConfigDefinition } from "@/models/screenConfig";
import { SettingIconButton } from "@/components/common/SettingIconButton";

type Props = {
  search: (option?: { params?: MatrixSearchParams }) => void;
  cancelHandler: (_) => void;
  closeHandler: () => void;
};

type DispatchProps = {
  masters: ConstructionMasters;
  searchParams: MatrixSearchParams;
  changeArea: (_) => void;
  changeDevice: (_) => void;
  changeCategory: (_) => void;
  changeFacilityManagement: (_) => void;
  changeConstructionManagement: (_) => void;
  changePrimaryCharge: (_) => void;
  changeOther: (_) => void;
  changeSystem: (_) => void;
  changeCompany: (_) => void;
  changeUser: (_) => void;
  changeGroup: (_) => void;
  changeProcessMiddleClass: (_) => void;
  changeItemText: (_) => void;
  changeProcessText: (_) => void;
  changeRegulation: (_) => void;
  changeFilter: (_) => void;
  clearConditions: () => void;
  showAlert: (title, message) => void;
};

const SearchPanel: React.FC<Props> = (props: Props & DispatchProps) => {
  const { t } = useTranslation();

  // マトリクス画面の検索条件設定の定義
  const matrixSearchConditionDefinitions = useMemo<SearchConditionConfigDefinition<MatrixSearchKeyType>[]>(
    () => [
      {
        itemsPerLine: 3,
        items: [
          { key: MatrixSearchKey.Category, label: t("machines_category"), required: true },
          {
            key: MatrixSearchKey.PrimaryCharge,
            label: t("primary_charge"),
            required: true,
          },
        ],
      },
      {
        itemsPerLine: 3,
        items: [
          { key: MatrixSearchKey.Area, label: t("area") },
          { key: MatrixSearchKey.Device, label: t("device") },
          { key: MatrixSearchKey.FacilityManagement, label: t("facility_management") },
          { key: MatrixSearchKey.ConstructionManagement, label: t("construction_management") },
          { key: MatrixSearchKey.Other, label: t("other") },
          { key: MatrixSearchKey.Company, label: t("company") },
          { key: MatrixSearchKey.ProcessMiddleClass, label: t("matrix_search_middle") },
          { key: MatrixSearchKey.Regulation, label: t("law") },
        ],
      },
      {
        itemsPerLine: 1,
        items: [{ key: MatrixSearchKey.System, label: t("system") }],
      },
      {
        itemsPerLine: 1,
        items: [{ key: MatrixSearchKey.User, label: t("assignee") }],
      },
      {
        itemsPerLine: 1,
        items: [{ key: MatrixSearchKey.ItemText, label: t("title") }],
      },
      {
        itemsPerLine: 1,
        items: [{ key: MatrixSearchKey.ProcessText, label: t("process_title") }],
      },
      {
        itemsPerLine: 4,
        items: [
          { key: MatrixSearchKey.FilterComing, label: t("coming") },
          { key: MatrixSearchKey.FilterExpired, label: t("expired") },
          { key: MatrixSearchKey.FilterAssigned, label: t("assigned") },
          { key: MatrixSearchKey.FilterNotCompleted, label: t("not_completed") },
        ],
      },
    ],
    []
  );

  // 検索条件設定
  const config = useSearchConditionConfig<MatrixSearchKeyType>({
    screenName: ScreenName.MATRIX,
    requiredKeys: matrixSearchConditionDefinitions.flatMap((v) =>
      v.items.filter((item) => item.required).map((item) => item.key)
    ),
  });

  const handleSearch = (e) => {
    e.preventDefault();
    if (!searchParams.primaryChargeId || searchParams.primaryChargeId === 0) {
      props.showAlert(t("error"), [t("select_primary_charge")]);
    } else {
      if (config.showAllConditions) {
        props.search();
      } else {
        // 検索条件設定で表示している検索条件のみ有効とする
        props.search({
          params: {
            categoryId: config.map.get(MatrixSearchKey.Category) ? searchParams.categoryId : 0,
            primaryChargeId: config.map.get(MatrixSearchKey.PrimaryCharge) ? searchParams.primaryChargeId : 0,
            areaIds: config.map.get(MatrixSearchKey.Area) ? searchParams.areaIds : [],
            deviceIds: config.map.get(MatrixSearchKey.Device) ? searchParams.deviceIds : [],
            facilityManagementIds: config.map.get(MatrixSearchKey.FacilityManagement)
              ? searchParams.facilityManagementIds
              : [],
            constructionManagementIds: config.map.get(MatrixSearchKey.ConstructionManagement)
              ? searchParams.constructionManagementIds
              : [],
            otherIds: config.map.get(MatrixSearchKey.Other) ? searchParams.otherIds : [],
            systemIds: config.map.get(MatrixSearchKey.System) ? searchParams.systemIds : [],
            companyIds: config.map.get(MatrixSearchKey.Company) ? searchParams.companyIds : [],
            userIds: config.map.get(MatrixSearchKey.User) ? searchParams.userIds : [],
            groupIds: config.map.get(MatrixSearchKey.User) ? searchParams.groupIds : [],
            processMiddleClassIds: config.map.get(MatrixSearchKey.ProcessMiddleClass)
              ? searchParams.processMiddleClassIds
              : [],
            itemText: config.map.get(MatrixSearchKey.ItemText) ? searchParams.itemText : "",
            processText: config.map.get(MatrixSearchKey.ProcessText) ? searchParams.processText : "",
            regulation: config.map.get(MatrixSearchKey.Regulation) ? searchParams.regulation : "",
            filters: [
              config.map.get(MatrixSearchKey.FilterComing) && (searchParams.filters ?? []).includes(1) ? 1 : undefined,
              config.map.get(MatrixSearchKey.FilterExpired) && (searchParams.filters ?? []).includes(2) ? 2 : undefined,
              config.map.get(MatrixSearchKey.FilterAssigned) && (searchParams.filters ?? []).includes(3)
                ? 3
                : undefined,
              config.map.get(MatrixSearchKey.FilterNotCompleted) && (searchParams.filters ?? []).includes(4)
                ? 4
                : undefined,
            ].filter((v) => !!v),
          },
        });
      }
      props.closeHandler();
    }
  };

  const {
    masters,
    searchParams,
    changeArea,
    changeDevice,
    changeCategory,
    changeFacilityManagement,
    changeConstructionManagement,
    changePrimaryCharge,
    changeOther,
    changeSystem,
    changeItemText,
    changeProcessText,
    changeRegulation,
    changeFilter,
    changeUser,
    changeGroup,
    changeCompany,
    changeProcessMiddleClass,
  } = props;

  // 機器分類と元請の連動
  const primaryChargesForCategory = useMemo(() => {
    const categoryPrimaryCharge = masters?.categories_primary_charges?.find(
      (category) => category.category_id === searchParams.categoryId
    );

    return categoryPrimaryCharge?.primary_charges ?? [];
  }, [masters?.categories_primary_charges, searchParams.categoryId]);
  useEffect(() => {
    if (!primaryChargesForCategory.some((pc) => pc.primary_charge_id === searchParams.primaryChargeId)) {
      changePrimaryCharge(primaryChargesForCategory[0]?.primary_charge_id ?? 0);
    }
  }, [changePrimaryCharge, primaryChargesForCategory, searchParams.primaryChargeId]);

  if (!config.map) return <></>;

  return (
    <>
      <Modal title={t("matrix_search")} closeHandler={props.closeHandler}>
        <div className="flex justify-end bg-[#e4e4e4] w-full">
          <SettingIconButton className="w-[32px] p-[8px] " onClick={config.openSettingModal} />
        </div>
        <div className="modal-body w-880 clearfix">
          <div className="grid gap-y-[16px]">
            <FormRow>
              <FormGroup title={t("machines_category")} className="w-340">
                <Select
                  prefix="category"
                  options={masters.categories}
                  value={searchParams.categoryId}
                  onChange={changeCategory}
                />
              </FormGroup>
              <FormGroup title={t("primary_charge")} className="w-260">
                <Select
                  prefix="primary_charge"
                  options={primaryChargesForCategory}
                  value={searchParams.primaryChargeId}
                  onChange={changePrimaryCharge}
                />
              </FormGroup>
            </FormRow>
            <FormGrid
              className="grid-cols-3"
              display={config.show([
                MatrixSearchKey.Area,
                MatrixSearchKey.Device,
                MatrixSearchKey.FacilityManagement,
                MatrixSearchKey.ConstructionManagement,
                MatrixSearchKey.Other,
                MatrixSearchKey.Company,
                MatrixSearchKey.ProcessMiddleClass,
                MatrixSearchKey.Regulation,
              ])}
            >
              <FormGroup title={t("area")} className="w-260" display={config.show([MatrixSearchKey.Area])}>
                <Select
                  prefix="area"
                  options={masters.areas}
                  value={searchParams.areaIds}
                  onChange={changeArea}
                  isMulti={true}
                  withNone
                />
              </FormGroup>
              <FormGroup title={t("device")} className="w-260" display={config.show([MatrixSearchKey.Device])}>
                <Select
                  prefix="device"
                  options={masters.devices}
                  value={searchParams.deviceIds}
                  onChange={changeDevice}
                  isMulti={true}
                  withNone
                />
              </FormGroup>
              <FormGroup
                title={t("facility_management")}
                className="w-260"
                display={config.show([MatrixSearchKey.FacilityManagement])}
              >
                <Select
                  prefix="facility_management"
                  options={masters.facility_managements}
                  value={searchParams.facilityManagementIds}
                  onChange={changeFacilityManagement}
                  isMulti={true}
                  withNone
                />
              </FormGroup>
              <FormGroup
                title={t("construction_management")}
                className="w-260"
                display={config.show([MatrixSearchKey.ConstructionManagement])}
              >
                <Select
                  prefix="construction_management"
                  options={masters.construction_managements}
                  value={searchParams.constructionManagementIds}
                  onChange={changeConstructionManagement}
                  isMulti={true}
                  withNone
                />
              </FormGroup>
              <FormGroup title={t("other")} className="w-260" display={config.show([MatrixSearchKey.Other])}>
                <Select
                  prefix="other"
                  options={masters.others}
                  value={searchParams.otherIds}
                  onChange={changeOther}
                  isMulti={true}
                  withNone
                />
              </FormGroup>
              <FormGroup title={t("company")} className="w-260" display={config.show([MatrixSearchKey.Company])}>
                <Select
                  prefix="company"
                  options={masters.companies}
                  value={searchParams.companyIds}
                  onChange={changeCompany}
                  isMulti={true}
                />
              </FormGroup>
              <FormGroup
                title={t("matrix_search_middle")}
                className="w-260"
                display={config.show([MatrixSearchKey.ProcessMiddleClass])}
              >
                <Select
                  prefix="process_middle_class"
                  options={masters.process_middle_classes}
                  value={searchParams.processMiddleClassIds}
                  onChange={changeProcessMiddleClass}
                  isMulti={true}
                />
              </FormGroup>
              <FormGroup title={t("law")} className="w-260" display={config.show([MatrixSearchKey.Regulation])}>
                <input
                  data-test-id="text-search-panel-regulation"
                  type="text"
                  className="form-control w-170"
                  value={searchParams.regulation}
                  onChange={(e) => changeRegulation(e.target.value)}
                />
              </FormGroup>
            </FormGrid>
            <FormRow display={config.show([MatrixSearchKey.System])}>
              <FormGroup title={t("system")} className="w-340">
                <Select
                  prefix="system"
                  options={masters.systems}
                  value={searchParams.systemIds}
                  onChange={changeSystem}
                  isMulti={true}
                />
              </FormGroup>
            </FormRow>
            <FormRow display={config.show([MatrixSearchKey.User])}>
              <FormGroup title={t("assignee")} className="w-340">
                <ChargeSelect
                  masters={masters}
                  userId={searchParams.userIds}
                  groupId={searchParams.groupIds}
                  isMulti={true}
                  onChange={(e) => {
                    const users = e ? e.filter((d) => d.category === "user") : [];
                    const groups = e ? e.filter((d) => d.category === "group") : [];

                    changeUser(users.map((u) => u.value));
                    changeGroup(groups.map((g) => g.value));
                  }}
                />
              </FormGroup>
            </FormRow>
            <FormRow display={config.show([MatrixSearchKey.ItemText])}>
              <FormGroup title={t("title")} className="w-520">
                <input
                  data-test-id="text-search-panel-item-name"
                  type="text"
                  className="form-control w-430"
                  value={searchParams.itemText}
                  onChange={(e) => changeItemText(e.target.value)}
                />
              </FormGroup>
            </FormRow>
            <FormRow display={config.show([MatrixSearchKey.ProcessText])}>
              <FormGroup title={t("process_title")} className="w-520">
                <input
                  data-test-id="text-search-panel-process-name"
                  type="text"
                  className="form-control w-430"
                  value={searchParams.processText}
                  onChange={(e) => changeProcessText(e.target.value)}
                />
              </FormGroup>
            </FormRow>
            <FormRow
              className="gap-x-[15px]"
              display={config.show([
                MatrixSearchKey.FilterComing,
                MatrixSearchKey.FilterExpired,
                MatrixSearchKey.FilterAssigned,
                MatrixSearchKey.FilterNotCompleted,
              ])}
            >
              <Spacer x="60px" />
              {config.show([MatrixSearchKey.FilterComing]) && (
                <CheckBox
                  data-test-id="checkbox-search-panel-coming"
                  checked={_.includes(searchParams.filters, 1)}
                  label={t("coming")}
                  onChange={() => changeFilter(1)}
                />
              )}
              {config.show([MatrixSearchKey.FilterExpired]) && (
                <CheckBox
                  data-test-id="checkbox-search-panel-expired"
                  checked={_.includes(searchParams.filters, 2)}
                  label={t("expired")}
                  onChange={() => changeFilter(2)}
                />
              )}
              {config.show([MatrixSearchKey.FilterAssigned]) && (
                <CheckBox
                  data-test-id="checkbox-search-panel-assigned"
                  checked={_.includes(searchParams.filters, 3)}
                  label={t("assigned")}
                  onChange={() => changeFilter(3)}
                />
              )}
              {config.show([MatrixSearchKey.FilterNotCompleted]) && (
                <CheckBox
                  data-test-id="checkbox-search-panel-not-completed"
                  checked={_.includes(searchParams.filters, 4)}
                  label={t("not_completed")}
                  onChange={() => changeFilter(4)}
                />
              )}
            </FormRow>
          </div>
          <div className="flex justify-center mt-[20px]">
            <SearchConditionDisplayButton
              isExpanded={config.showAllConditions}
              onChange={config.toggleDisplayOptionalConditions}
            />
          </div>
        </div>
        <div className="modal-footer">
          <button
            data-test-id="button-search-panel-cancel"
            type="button"
            className="btn btn-gray"
            onClick={props.cancelHandler}
          >
            {t("cancel")}
          </button>
          <button
            data-test-id="button-search-panel-clear"
            type="button"
            className="btn btn-gray"
            onClick={() => {
              util.removeItemIdParam();
              props.clearConditions();
            }}
          >
            {t("clear")}
          </button>
          <button
            data-test-id="button-search-panel-search"
            type="button"
            className="btn btn-blue"
            onClick={(e) => {
              util.removeItemIdParam();
              util.removeProcessIdParam();
              handleSearch(e);
            }}
          >
            {t("search")}
          </button>
        </div>
      </Modal>
      {config.showSettingModal && (
        <SearchConditionConfigModal<MatrixSearchKeyType>
          title={t("matrix") + t("search_condition_config")}
          configMap={config.map}
          definitions={matrixSearchConditionDefinitions}
          onClose={config.closeSettingModal}
          onSubmit={(c) => config.saveSearchItemConfig(c)}
        />
      )}
    </>
  );
};

export default withTranslation()(SearchPanel);
