import { connect } from "react-redux";

import actions from "../../actions/index";
import InformationEditor from "../../components/home/InformationEditor";

const mapStateToProps = (state) => {
  let extensions = [];
  let fileUpload = true;

  if (state.construction.extensions) {
    extensions = state.construction.extensions.extensions.filter((item) => item.extension_type === 5);
    if (extensions.length > 0) {
      const config = JSON.parse(extensions[0].config);
      fileUpload = config.file_upload.information;
    }
  }

  return { fileUpload };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchItem: (informationId, callback) => dispatch(actions.information.fetchItem(informationId, callback)),
    create: (data, callback, failedCallback) =>
      dispatch(actions.information.createItem(data, callback, failedCallback)),
    update: (informationId, data, callback, failedCallback) =>
      dispatch(actions.information.updateItem(informationId, data, callback, failedCallback)),
    upload: (data, onProgress, onSuccess, onError) =>
      dispatch(actions.app.upload(data, onProgress, onSuccess, onError)),
    download: (kind, fileId, callback) => dispatch(actions.common.downloadAttachmentFile(kind, fileId, callback)),
    setUnloadAlert: () => dispatch(actions.app.setUnloadAlert()),
    clearUnloadAlert: () => dispatch(actions.app.clearUnloadAlert()),
    removeFile: (title, messages, okClickHandler) => {
      dispatch(
        actions.app.showConfirm(
          title,
          messages,
          () => {
            okClickHandler();
            dispatch(actions.app.hideConfirm());
          },
          () => {
            dispatch(actions.app.hideConfirm());
          }
        )
      );
    },
    showAlert: (title, messages) =>
      dispatch(actions.app.showAlert(title, messages, () => dispatch(actions.app.hideAlert()))),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InformationEditor);
