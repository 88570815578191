import { connect } from "react-redux";

import actions from "../../actions/index";
import ApprovalSearchPanel from "../../components/list/ApprovalSearchPanel";

const mapStateToProps = (state) => {
  return {
    masters: state.construction.masters,
    areaIds: state.approval.areaIds,
    deviceIds: state.approval.deviceIds,
    categoryId: state.approval.categoryId,
    facilityManagementIds: state.approval.facilityManagementIds,
    constructionManagementIds: state.approval.constructionManagementIds,
    primaryChargeIds: state.approval.primaryChargeIds,
    otherIds: state.approval.otherIds,
    processMajorClassIds: state.approval.processMajorClassIds,
    scheduleEndDate: state.approval.scheduleEndDate,
    checkpointIds: state.approval.checkpointIds,
    companyIds: state.approval.companyIds,
    processMiddleClassIds: state.approval.processMiddleClassIds,
    itemText: state.approval.itemText,
    processText: state.approval.processText,
    filter: state.approval.filter,
    items: state.approval.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeArea: (items) => dispatch(actions.approval.changeArea(items)),
    changeDevice: (items) => dispatch(actions.approval.changeDevice(items)),
    changeCategory: (item) => dispatch(actions.approval.changeCategory(item)),
    changeFacilityManagement: (items) => dispatch(actions.approval.changeFacilityManagement(items)),
    changeConstructionManagement: (items) => dispatch(actions.approval.changeConstructionManagement(items)),
    changePrimaryCharge: (items) => dispatch(actions.approval.changePrimaryCharge(items)),
    changeOther: (items) => dispatch(actions.approval.changeOther(items)),
    changeProcessMajorClass: (items) => dispatch(actions.approval.changeProcessMajorClass(items)),
    changeProcessMiddleClass: (items) => dispatch(actions.approval.changeProcessMiddleClass(items)),
    changeCompany: (items) => dispatch(actions.approval.changeCompany(items)),
    changeScheduleEndDate: (scheduleEndDate) => dispatch(actions.approval.changeScheduleEndDate(scheduleEndDate)),
    changeCheckpoint: (items) => dispatch(actions.approval.changeCheckpoint(items)),
    changeItemText: (itemText) => dispatch(actions.approval.changeItemText(itemText)),
    changeProcessText: (processText) => dispatch(actions.approval.changeProcessText(processText)),
    changeFilter: (flag) => dispatch(actions.approval.changeFilter(flag)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ApprovalSearchPanel);
