import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import React, { useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import actions from "@/actions";
import { FormGrid, FormGroup, FormRow, Spacer } from "@/components/common";
import { SearchConditionConfigModal } from "@/components/common/SearchCondition/SearchConditionConfigModal";
import { SearchConditionDisplayButton } from "@/components/common/SearchCondition/SearchConditionDisplayButton";
import MasterSelect from "@/components/common/Select";
import { SettingIconButton } from "@/components/common/SettingIconButton";
import { UseSearchInputType } from "@/components/list/Schedule/hooks/useSearchInput";
import { SearchConditionOption, SearchInput } from "@/components/list/Schedule/types";
import { ScheduleSearchKey, ScheduleSearchKeyType } from "@/components/list/Schedule/types/schedule";
import { NONE_VALUE } from "@/constants";
import { useSearchConditionConfig } from "@/hooks/useSearchConditionConfig";
import { ConstructionMasters } from "@/models/masters";
import { SearchConditionConfigDefinition, ScreenName } from "@/models/screenConfig";

type Props = {
  scheduleDate: Date;
  input: UseSearchInputType;
  masters: ConstructionMasters;
  searchOptions: SearchConditionOption[];
  onReset: () => void;
  onSearch: (option?: { params?: SearchInput }) => void;
};

export const SearchBox = ({ scheduleDate, input, masters, searchOptions, onReset, onSearch }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // データ取り込み画面の検索条件設定の定義
  const scheduleSearchConditionDefinitions = useMemo<SearchConditionConfigDefinition<ScheduleSearchKeyType>[]>(
    () => [
      {
        itemsPerLine: 3,
        items: [
          { key: ScheduleSearchKey.ScheduleDate, label: t("scheduled_date"), required: true },
          { key: ScheduleSearchKey.Area, label: t("area"), required: false },
          { key: ScheduleSearchKey.Device, label: t("device"), required: false },
          { key: ScheduleSearchKey.Category, label: t("machines_category"), required: false },
          { key: ScheduleSearchKey.Manager, label: t("sankyu_manager"), required: false },
          { key: ScheduleSearchKey.FieldP1, label: t("company"), required: false },
          { key: ScheduleSearchKey.Field1, label: t("hazard_rank"), required: false },
          { key: ScheduleSearchKey.Field2, label: t("attendance"), required: false },
          { key: ScheduleSearchKey.ApproveUnprocessed, label: t("unprocessed"), required: false },
          { key: ScheduleSearchKey.FieldT9, label: t("overtime_hours"), required: false },
          { key: ScheduleSearchKey.Approve1User, label: t("construction") + t("supervising_section"), required: false },
          { key: ScheduleSearchKey.Approve4User, label: t("facility") + t("supervising_section"), required: false },
        ],
      },
    ],
    []
  );

  // 検索条件設定
  const config = useSearchConditionConfig<ScheduleSearchKeyType>({
    screenName: ScreenName.SCHEDULE,
    requiredKeys: scheduleSearchConditionDefinitions.flatMap((v) =>
      v.items.filter((item) => item.required).map((item) => item.key)
    ),
  });

  const hazardRankOptions = useMemo(
    () => searchOptions.find((v) => v.target === "field1")?.options ?? [],
    [searchOptions]
  );
  const attendanceOptions = useMemo(
    () => searchOptions.find((v) => v.target === "field2")?.options ?? [],
    [searchOptions]
  );

  const [scheduleDateLabel, setScheduleDateLabel] = useState<string>(moment(scheduleDate).format("YYYY/MM/DD"));

  const handleReset = () => {
    const date = moment().startOf("day").add(1, "day").toDate();
    setScheduleDateLabel(moment(date).format("YYYY/MM/DD"));
    onReset();
  };

  const handleSearch = () => {
    if (!scheduleDateLabel) {
      dispatch(
        actions.app.showAlert(t("error"), [`${t("scheduled_date")}${t("input_required_field")}`], () =>
          dispatch(actions.app.hideAlert())
        )
      );

      return;
    }

    if (config.showAllConditions) {
      onSearch();
    } else {
      onSearch({
        params: {
          schedule_date: moment(scheduleDateLabel, "YYYY/MM/DD").toDate(),
          area_id: config.map.get(ScheduleSearchKey.Area) ? input.areaId : [],
          device_id: config.map.get(ScheduleSearchKey.Device) ? input.deviceId : [],
          category_id: config.map.get(ScheduleSearchKey.Category) ? input.categoryId : [],
          manager_user_id: config.map.get(ScheduleSearchKey.Manager) ? input.managerUserId : [],
          field_p1: config.map.get(ScheduleSearchKey.FieldP1) ? input.fieldP1 : "",
          field1: config.map.get(ScheduleSearchKey.Field1) ? input.field1 : [],
          field2: config.map.get(ScheduleSearchKey.Field2) ? input.field2 : "",
          approve1_unprocessed: config.map.get(ScheduleSearchKey.ApproveUnprocessed)
            ? input.approve1Unprocessed
            : false,
          approve4_unprocessed: config.map.get(ScheduleSearchKey.ApproveUnprocessed)
            ? input.approve4Unprocessed
            : false,
          approve1_user_id: config.map.get(ScheduleSearchKey.Approve1User) ? input.approve1UserId : [],
          approve4_user_id: config.map.get(ScheduleSearchKey.Approve4User) ? input.approve4UserId : [],
          field_t9: config.map.get(ScheduleSearchKey.FieldT9) ? input.fieldT9 : false,
        },
      });
    }
  };

  if (!config.map) return <></>;

  return (
    <div>
      <div className="flex justify-end bg-[#e4e4e4] w-[900px]">
        <SettingIconButton className="w-[32px] p-[8px]" onClick={config.openSettingModal} />
      </div>
      <div className="search-box w-900 mb-0">
        <FormGrid
          className="grid-cols-3"
          display={config.show([
            ScheduleSearchKey.ScheduleDate,
            ScheduleSearchKey.Area,
            ScheduleSearchKey.Device,
            ScheduleSearchKey.Category,
            ScheduleSearchKey.Manager,
            ScheduleSearchKey.FieldP1,
            ScheduleSearchKey.Field1,
            ScheduleSearchKey.Field2,
            ScheduleSearchKey.ApproveUnprocessed,
            ScheduleSearchKey.FieldT9,
            ScheduleSearchKey.Approve1User,
            ScheduleSearchKey.Approve4User,
          ])}
        >
          <FormGroup
            title={t("scheduled_date")}
            titleClassName="w-100"
            display={config.show([ScheduleSearchKey.ScheduleDate])}
          >
            <DatePicker
              wrapperClassName="w-[100%]"
              selected={!scheduleDateLabel ? null : moment(scheduleDateLabel, "YYYY/MM/DD").toDate()}
              dateFormat="yyyy/MM/dd"
              locale={t("calender_locale")}
              onChange={(date) => {
                input.setScheduleDate(date);
                setScheduleDateLabel(!date ? null : moment(date).format("YYYY-MM-DD"));
              }}
            />
          </FormGroup>
          <FormGroup title={t("area")} display={config.show([ScheduleSearchKey.Area])}>
            <MasterSelect
              prefix="area"
              isMulti={true}
              options={masters?.areas ?? []}
              value={input.areaId}
              onChange={(ids) => input.setAreaId(ids)}
            />
          </FormGroup>
          <FormGroup title={t("device")} display={config.show([ScheduleSearchKey.Device])}>
            <MasterSelect
              prefix="device"
              isMulti={true}
              options={masters?.devices ?? []}
              value={input.deviceId}
              onChange={(ids) => input.setDeviceId(ids)}
            />
          </FormGroup>
          <FormGroup
            title={t("machines_category")}
            titleClassName="w-100"
            display={config.show([ScheduleSearchKey.Category])}
          >
            <MasterSelect
              prefix="category"
              isMulti={true}
              options={masters?.categories ?? []}
              value={input.categoryId ?? NONE_VALUE}
              onChange={(ids) => input.setCategoryId(ids)}
            />
          </FormGroup>
          <FormGroup title={t("sankyu_manager")} display={config.show([ScheduleSearchKey.Manager])}>
            <MasterSelect
              prefix="user"
              isMulti={true}
              options={masters?.users ?? []}
              value={input.managerUserId}
              onChange={(ids) => input.setManagerUserId(ids)}
            />
          </FormGroup>
          <FormGroup title={t("company")} display={config.show([ScheduleSearchKey.FieldP1])}>
            <input
              type="text"
              className="form-control"
              value={input.fieldP1}
              onChange={(e) => input.setFieldP1(e.target.value)}
            />
          </FormGroup>
          <FormGroup title={t("hazard_rank")} titleClassName="w-100" display={config.show([ScheduleSearchKey.Field1])}>
            <MasterSelect
              isMulti={true}
              options={hazardRankOptions}
              value={input.field1}
              onChange={(ids) => input.setField1(ids)}
              placeholder={<span className="text-black">{"-----"}</span>}
            />
          </FormGroup>
          <FormGroup title={t("attendance")} display={config.show([ScheduleSearchKey.Field2])}>
            <MasterSelect
              options={attendanceOptions}
              value={input.field2}
              onChange={(ids) => input.setField2(ids[0])}
              withNone={true}
              noneLabel={"-----"}
              placeholder={<span className="text-black">{"-----"}</span>}
            />
          </FormGroup>
          <div className="flex flex-col flex-start">
            <FormRow className="whitespace-nowrap" display={config.show([ScheduleSearchKey.ApproveUnprocessed])}>
              <span className="form-label w-[82px] mr-[8px] text-right">{t("unprocessed")}</span>
              <label className="ckbox">
                <input
                  type="checkbox"
                  checked={input.approve1Unprocessed}
                  onChange={() => input.setApprove1Unprocessed(!input.approve1Unprocessed)}
                />
                <span>
                  {t("construction")}
                  {t("supervising_section")}
                </span>
              </label>
              <label className="ckbox mr-0">
                <input
                  type="checkbox"
                  checked={input.approve4Unprocessed}
                  onChange={() => input.setApprove4Unprocessed(!input.approve4Unprocessed)}
                />
                <span>
                  {t("facility")}
                  {t("supervising_section")}
                </span>
              </label>
            </FormRow>
            <FormRow className="whitespace-nowrap" display={config.show([ScheduleSearchKey.FieldT9])}>
              <span className="form-label w-[82px] mr-[8px] text-right">{t("overtime_hours")}</span>
              <label className="ckbox">
                <input
                  type="checkbox"
                  checked={input.fieldT9 || false}
                  onChange={() => input.setFieldT9(!input.fieldT9)}
                />
                <span>{t("registered")}</span>
              </label>
            </FormRow>
          </div>
          <FormGroup
            title={t("construction") + t("supervising_section")}
            titleClassName="w-100"
            display={config.show([ScheduleSearchKey.Approve1User])}
          >
            <MasterSelect
              prefix="user"
              isMulti={true}
              options={masters?.users ?? []}
              value={input.approve1UserId}
              onChange={(ids) => input.setApprove1UserId(ids)}
            />
          </FormGroup>
          <FormGroup
            title={t("facility") + t("supervising_section")}
            display={config.show([ScheduleSearchKey.Approve4User])}
          >
            <MasterSelect
              prefix="user"
              isMulti={true}
              options={masters?.users ?? []}
              value={input.approve4UserId}
              onChange={(ids) => input.setApprove4UserId(ids)}
            />
          </FormGroup>
        </FormGrid>
        <FormRow className="justify-between mt-[16px]">
          <Spacer x="210px" />
          <SearchConditionDisplayButton
            isExpanded={config.showAllConditions}
            onChange={config.toggleDisplayOptionalConditions}
          />
          <div className="flex gap-x-4">
            <button data-test-id="button-import-reset" className="btn btn-gray" onClick={handleReset}>
              {t("reset")}
            </button>
            <button data-test-id="button-import-search" className="btn btn-blue" onClick={handleSearch}>
              {t("search")}
            </button>
          </div>
        </FormRow>
      </div>
      {config.showSettingModal && (
        <SearchConditionConfigModal<ScheduleSearchKeyType>
          title={t("search_condition_config")}
          configMap={config.map}
          definitions={scheduleSearchConditionDefinitions}
          onClose={config.closeSettingModal}
          onSubmit={(c) => config.saveSearchItemConfig(c)}
        />
      )}
    </div>
  );
};
