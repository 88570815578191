import * as constants from "../constants/index";
import restoreState from "../lib/restoreState";
import storageManager from "../lib/storageManager";
import { compareInazumaCell } from "../lib/summary";

import { Category, Summary } from "@/models/summary";

const getInitialSearchConditions = (onlyLocal = false) => {
  let areaIds = [];
  let deviceIds = [];
  let categoryIds = [];
  let facilityManagementIds = [];
  let constructionManagementIds = [];
  let primaryChargeIds = [];

  if (storageManager.getConstructionItem("summarySearchParams") !== null) {
    const params = JSON.parse(storageManager.getConstructionItem("summarySearchParams"));

    areaIds = params.areaIds;
    deviceIds = params.deviceIds;
    categoryIds = params.categoryIds;
    facilityManagementIds = params.facilityManagementIds;
    constructionManagementIds = params.constructionManagementIds;
    primaryChargeIds = params.primaryChargeIds;
  }

  const urlState = restoreState();

  if (!onlyLocal && urlState && urlState.menu === "summary") {
    areaIds = urlState.area_ids
      ? decodeURIComponent(urlState.area_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    deviceIds = urlState.device_ids
      ? decodeURIComponent(urlState.device_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    categoryIds = urlState.category_ids
      ? decodeURIComponent(urlState.category_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    facilityManagementIds = urlState.facility_management_ids
      ? decodeURIComponent(urlState.facility_management_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    constructionManagementIds = urlState.construction_management_ids
      ? decodeURIComponent(urlState.construction_management_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    primaryChargeIds = urlState.primary_charge_ids
      ? decodeURIComponent(urlState.primary_charge_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
  }

  return {
    areaIds,
    deviceIds,
    categoryIds,
    facilityManagementIds,
    constructionManagementIds,
    primaryChargeIds,
  };
};

export type SummaryState = {
  areaIds: number[];
  deviceIds: number[];
  categoryIds: number[];
  facilityManagementIds: number[];
  constructionManagementIds: number[];
  primaryChargeIds: number[];
  summaries: Summary[];
  categories: Category[];
  fetching: boolean;
  isError: boolean;
  validated: boolean;
  isInazumaSelecting: boolean;
  inazumaSelectedCells: any[];
  loadingPanel: boolean;
  isGraphSelecting: boolean;
  graphSelectedCells: any[];
  isScheduleSelecting: boolean;
  scheduleSelectedCells: any[];
  isDelaySelecting: boolean;
  delaySelectedCells: any[];
};

const summary = (
  state = {
    areaIds: [],
    deviceIds: [],
    categoryIds: [],
    facilityManagementIds: [],
    constructionManagementIds: [],
    primaryChargeIds: [],
    summaries: [],
    categories: [],
    fetching: false,
    isError: false,
    validated: false,
    isInazumaSelecting: false,
    inazumaSelectedCells: [],
    loadingPanel: false,
    isGraphSelecting: false,
    graphSelectedCells: [],
    isScheduleSelecting: false,
    scheduleSelectedCells: [],
    isDelaySelecting: false,
    delaySelectedCells: [],
  },
  action
) => {
  switch (action.type) {
    case constants.APP_CONTENTS_READY: {
      return {
        ...state,
        ...getInitialSearchConditions(),
      };
    }
    case constants.SUMMARY_CHANGE_AREA: {
      return {
        ...state,
        areaIds: action.payload,
      };
    }
    case constants.SUMMARY_CHANGE_DEVICE: {
      return {
        ...state,
        deviceIds: action.payload,
      };
    }
    case constants.SUMMARY_CHANGE_CATEGORY: {
      return {
        ...state,
        categoryIds: action.payload,
      };
    }
    case constants.SUMMARY_CHANGE_FACILITY_MANAGEMENT: {
      return {
        ...state,
        facilityManagementIds: action.payload,
      };
    }
    case constants.SUMMARY_CHANGE_CONSTRUCTION_MANAGEMENT: {
      return {
        ...state,
        constructionManagementIds: action.payload,
      };
    }
    case constants.SUMMARY_CHANGE_PRIMARY_CHARGE: {
      return {
        ...state,
        primaryChargeIds: action.payload,
      };
    }

    case constants.SUMMARY_RESET_SEARCH: {
      return {
        ...state,
        areaIds: [],
        deviceIds: [],
        categoryIds: [],
        facilityManagementIds: [],
        constructionManagementIds: [],
        primaryChargeIds: [],
      };
    }
    case constants.SUMMARY_BEGIN_SEARCH_ITEMS: {
      return {
        ...state,
        fetching: true,
        isError: false,
      };
    }
    case constants.SUMMARY_END_SEARCH_ITEMS: {
      return {
        ...state,
        summaries: action.payload.summaries,
        categories: action.payload.categories,
        fetching: false,
      };
    }
    case constants.SUMMARY_BEGIN_SEARCH_ITEMS_INFINITE: {
      return {
        ...state,
        fetching: true,
      };
    }
    case constants.SUMMARY_END_SEARCH_ITEMS_INFINITE: {
      return {
        ...state,
        fetching: false,
        summaries: [...state.summaries, ...action.payload.summaries],
      };
    }
    case constants.SUMMARY_SET_VALIDATED: {
      return {
        ...state,
        validated: Boolean(action.payload),
      };
    }
    case constants.SUMMARY_SET_CONDITIONS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case constants.SUMMARY_REVERT_CONDITION: {
      return {
        ...state,
        ...getInitialSearchConditions(true),
      };
    }
    case constants.SUMMARY_BEGIN_SELECT_INAZUMA: {
      return {
        ...state,
        isInazumaSelecting: true,
        isGraphSelecting: false,
        isScheduleSelecting: false,
        isDelaySelecting: false,
        inazumaSelectedCells: [],
      };
    }
    case constants.SUMMARY_END_SELECT_INAZUMA: {
      return {
        ...state,
        isInazumaSelecting: false,
      };
    }
    case constants.SUMMARY_TOGGLE_INAZUMA_CELL: {
      const cell = action.payload;
      const items = state.inazumaSelectedCells.filter((c) => compareInazumaCell(c, cell));

      if (items.length > 0) {
        return {
          ...state,
          inazumaSelectedCells: state.inazumaSelectedCells.filter((c) => !compareInazumaCell(c, cell)),
        };
      }

      return {
        ...state,
        inazumaSelectedCells: [...state.inazumaSelectedCells, cell],
      };
    }
    case constants.SUMMARY_CLEAR_INAZUMA_CELLS: {
      return {
        ...state,
        inazumaSelectedCells: [],
      };
    }

    case constants.SUMMARY_BEGIN_INAZUMA_PANEL_REQUEST_CREATION: {
      return {
        ...state,
        loadingPanel: true,
      };
    }

    case constants.SUMMARY_END_INAZUMA_PANEL_REQUEST_CREATION: {
      return {
        ...state,
        loadingPanel: false,
      };
    }

    case constants.SUMMARY_BEGIN_INAZUMA_PANEL_CREATE_BASE: {
      return {
        ...state,
        loadingPanel: true,
      };
    }

    case constants.SUMMARY_BEGIN_SELECT_SCHEDULE: {
      return {
        ...state,
        isScheduleSelecting: true,
        isInazumaSelecting: false,
        isGraphSelecting: false,
        isDelaySelecting: false,
        scheduleSelectedCells: [],
      };
    }
    case constants.SUMMARY_END_SELECT_SCHEDULE: {
      return {
        ...state,
        isScheduleSelecting: false,
      };
    }
    case constants.SUMMARY_TOGGLE_SCHEDULE_CELL: {
      const cell = action.payload;
      const items = state.scheduleSelectedCells.filter((c) => compareInazumaCell(c, cell));

      if (items.length > 0) {
        return {
          ...state,
          scheduleSelectedCells: state.scheduleSelectedCells.filter((c) => !compareInazumaCell(c, cell)),
        };
      }

      return {
        ...state,
        scheduleSelectedCells: [...state.scheduleSelectedCells, cell],
      };
    }
    case constants.SUMMARY_CLEAR_SCHEDULE_CELLS: {
      return {
        ...state,
        scheduleSelectedCells: [],
      };
    }

    case constants.SUMMARY_BEGIN_CREATE_SCHEDULE: {
      return {
        ...state,
        loadingPanel: true,
      };
    }

    case constants.SUMMARY_END_CREATE_SCHEDULE: {
      return {
        ...state,
        loadingPanel: false,
      };
    }

    case constants.SUMMARY_END_INAZUMA_PANEL_CREATE_BASE: {
      return {
        ...state,
        loadingPanel: false,
      };
    }

    case constants.SUMMARY_BEGIN_SELECT_GRAPH: {
      return {
        ...state,
        isGraphSelecting: true,
        isInazumaSelecting: false,
        isScheduleSelecting: false,
        isDelaySelecting: false,
        graphSelectedCells: [],
      };
    }
    case constants.SUMMARY_END_SELECT_GRAPH: {
      return {
        ...state,
        isGraphSelecting: false,
      };
    }
    case constants.SUMMARY_TOGGLE_GRAPH_CELL: {
      const cell = action.payload;
      const { graphSelectedCells } = state;
      const items = graphSelectedCells.filter((c) => compareInazumaCell(c, cell));

      if (items.length > 0) {
        return {
          ...state,
          graphSelectedCells: graphSelectedCells.filter((c) => !compareInazumaCell(c, cell)),
        };
      }

      return {
        ...state,
        graphSelectedCells: [...graphSelectedCells, cell],
      };
    }
    case constants.SUMMARY_CLEAR_GRAPH_CELLS: {
      return {
        ...state,
        graphSelectedCells: [],
      };
    }

    case constants.SUMMARY_BEGIN_SELECT_DELAY: {
      return {
        ...state,
        isDelaySelecting: true,
        isGraphSelecting: false,
        isInazumaSelecting: false,
        isScheduleSelecting: false,
        delaySelectedCells: [],
      };
    }
    case constants.SUMMARY_END_SELECT_DELAY: {
      return {
        ...state,
        isDelaySelecting: false,
      };
    }

    case constants.SUMMARY_TOGGLE_DELAY_CELL: {
      const cell = action.payload;
      const { delaySelectedCells } = state;
      const items = delaySelectedCells.filter((c) => compareInazumaCell(c, cell));

      if (items.length > 0) {
        return {
          ...state,
          delaySelectedCells: delaySelectedCells.filter((c) => !compareInazumaCell(c, cell)),
        };
      }

      return {
        ...state,
        delaySelectedCells: [...delaySelectedCells, cell],
      };
    }

    case constants.SUMMARY_CLEAR_DELAY_CELLS: {
      return {
        ...state,
        delaySelectedCells: [],
      };
    }

    case constants.APP_SHOW_ERROR: {
      if (action.errorFrom === "summary") {
        return {
          ...state,
          fetching: false,
          isError: true,
          loadingPanel: false,
        };
      }

      return state;
    }
    default:
      return state;
  }
};

export default summary;
