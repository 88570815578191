import _ from "lodash";
import moment from "moment";

import * as constants from "../constants/index";
import restoreState from "../lib/restoreState";
import storageManager from "../lib/storageManager";

const getInitialSearchConditions = (onlyLocal = false) => {
  const urlState = restoreState();
  let areaIds = [];
  let deviceIds = [];
  let facilityManagementIds = [];
  let constructionManagementIds = [];
  let primaryChargeIds = [];
  let otherIds = [];
  let systemName = "";
  let note = "";
  let scheduledDateFrom = "";
  let scheduledDateTo = "";
  let closeDateFrom = "";
  let closeDateTo = "";
  let categoryId = 0;
  let totalTest = -1;
  let preparation = "";

  if (storageManager.getConstructionItem("systemSearchParams") !== null) {
    const params = JSON.parse(storageManager.getConstructionItem("systemSearchParams"));
    areaIds = params.areaIds;
    deviceIds = params.deviceIds;
    categoryId = params.categoryId;
    facilityManagementIds = params.facilityManagementIds;
    constructionManagementIds = params.constructionManagementIds;
    primaryChargeIds = params.primaryChargeIds;
    otherIds = params.otherIds;
    systemName = params.systemName;
    note = params.note;
    scheduledDateFrom = params.scheStartDate;
    scheduledDateTo = params.scheEndDate;
    closeDateFrom = params.closeStartDate;
    closeDateTo = params.closeEndDate;
    totalTest = params.totalTest;
    preparation = params.preparation;
  }

  if (!onlyLocal && urlState && urlState.menu === "system") {
    areaIds = urlState.area_ids
      ? decodeURIComponent(urlState.area_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    deviceIds = urlState.device_ids
      ? decodeURIComponent(urlState.device_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    categoryId = urlState.category_id ? Number(urlState.category_id) : 0;
    facilityManagementIds = urlState.facility_management_ids
      ? decodeURIComponent(urlState.facility_management_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    constructionManagementIds = urlState.construction_management_ids
      ? decodeURIComponent(urlState.construction_management_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    primaryChargeIds = urlState.primary_charge_ids
      ? decodeURIComponent(urlState.primary_charge_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    otherIds = urlState.other_ids
      ? decodeURIComponent(urlState.other_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    systemName = urlState.system_name ? decodeURIComponent(urlState.system_name) : "";
    note = urlState.text ? decodeURIComponent(urlState.text) : "";
    scheduledDateFrom = urlState.schedule_date_from ? decodeURIComponent(urlState.schedule_date_from) : "";
    scheduledDateTo = urlState.schedule_date_to ? decodeURIComponent(urlState.schedule_date_to) : "";
    closeDateFrom = urlState.result_date_from ? decodeURIComponent(urlState.result_date_from) : "";
    closeDateTo = urlState.result_date_to ? decodeURIComponent(urlState.result_date_to) : "";
    totalTest = urlState.totaltest_end ? decodeURIComponent(urlState.totaltest_end) : -1;
    preparation = urlState.preparation_flg ? decodeURIComponent(urlState.preparation_flg) : "";
  }

  const totalTestMaster = ["0", "1"];
  if (totalTestMaster.indexOf(totalTest) === -1) {
    totalTest = -1;
  }

  const preparationMaster = ["false", "true"];
  if (preparationMaster.indexOf(preparation) === -1) {
    preparation = "";
  }

  if (scheduledDateFrom) {
    const scheStartDate = moment(scheduledDateFrom, "YYYY/MM/DD").format("YYYY-MM-DD").toString();
    if (scheStartDate === "Invalid date") {
      scheduledDateFrom = "";
    } else if (scheduledDateFrom !== scheStartDate) {
      scheduledDateFrom = scheStartDate;
    }
  }

  if (scheduledDateTo) {
    const scheEndDate = moment(scheduledDateTo, "YYYY/MM/DD").format("YYYY-MM-DD").toString();
    if (scheEndDate === "Invalid date") {
      scheduledDateTo = "";
    } else if (scheduledDateTo !== scheEndDate) {
      scheduledDateTo = scheEndDate;
    }
  }

  if (closeDateFrom) {
    const resStartDate = moment(closeDateFrom, "YYYY/MM/DD").format("YYYY-MM-DD").toString();
    if (resStartDate === "Invalid date") {
      closeDateFrom = "";
    } else if (closeDateFrom !== resStartDate) {
      closeDateFrom = resStartDate;
    }
  }

  if (closeDateTo) {
    const resEndDate = moment(closeDateTo, "YYYY/MM/DD").format("YYYY-MM-DD").toString();
    if (resEndDate === "Invalid date") {
      closeDateTo = "";
    } else if (closeDateTo !== resEndDate) {
      closeDateTo = resEndDate;
    }
  }

  return {
    areaIds,
    deviceIds,
    categoryId,
    facilityManagementIds,
    constructionManagementIds,
    primaryChargeIds,
    otherIds,
    systemName,
    note,
    scheStartDate: scheduledDateFrom,
    scheEndDate: scheduledDateTo,
    closeStartDate: closeDateFrom,
    closeEndDate: closeDateTo,
    totalTest,
    preparation,
  };
};

const system = (
  state = {
    areaIds: [],
    deviceIds: [],
    categoryId: 0,
    facilityManagementIds: [],
    constructionManagementIds: [],
    primaryChargeIds: [],
    otherIds: [],
    systemName: "",
    note: "",
    scheStartDate: "",
    scheEndDate: "",
    closeStartDate: "",
    closeEndDate: "",
    totalTest: -1,
    preparation: "",
    masterItems: [],
    items: [],
    detailItems: [],
    editItem: [],
    fetching: false,
    validated: false,
    downloading: false,
  },
  action
) => {
  switch (action.type) {
    case constants.APP_CONTENTS_READY: {
      return {
        ...state,
        ...getInitialSearchConditions(),
      };
    }
    case constants.SYSTEM_CHANGE_AREA: {
      return {
        ...state,
        areaIds: action.payload,
      };
    }
    case constants.SYSTEM_CHANGE_DEVICE: {
      return {
        ...state,
        deviceIds: action.payload,
      };
    }
    case constants.SYSTEM_CHANGE_FACILITY_MANAGEMENT: {
      return {
        ...state,
        facilityManagementIds: action.payload,
      };
    }
    case constants.SYSTEM_CHANGE_CATEGORY: {
      return {
        ...state,
        categoryId: action.payload,
      };
    }
    case constants.SYSTEM_CHANGE_CONSTRUCTION_MANAGEMENT: {
      return {
        ...state,
        constructionManagementIds: action.payload,
      };
    }
    case constants.SYSTEM_CHANGE_PRIMARY_CHARGE: {
      return {
        ...state,
        primaryChargeIds: action.payload,
      };
    }
    case constants.SYSTEM_CHANGE_OTHER: {
      return {
        ...state,
        otherIds: action.payload,
      };
    }
    case constants.SYSTEM_CHANGE_SYSTEMNAME: {
      return {
        ...state,
        systemName: action.payload,
      };
    }
    case constants.SYSTEM_CHANGE_NOTE: {
      return {
        ...state,
        note: action.payload,
      };
    }
    case constants.SYSTEM_CHANGE_SCHEDULED_DATE_FROM: {
      return {
        ...state,
        scheStartDate: action.payload,
      };
    }
    case constants.SYSTEM_CHANGE_SCHEDULED_DATE_TO: {
      return {
        ...state,
        scheEndDate: action.payload,
      };
    }
    case constants.SYSTEM_CHANGE_CLOSE_DATE_FROM: {
      return {
        ...state,
        closeStartDate: action.payload,
      };
    }
    case constants.SYSTEM_CHANGE_CLOSE_DATE_TO: {
      return {
        ...state,
        closeEndDate: action.payload,
      };
    }
    case constants.SYSTEM_CHANGE_TOTALTEST: {
      return {
        ...state,
        totalTest: action.payload,
      };
    }
    case constants.SYSTEM_CHANGE_PREPARATION: {
      return {
        ...state,
        preparation: action.payload,
      };
    }
    case constants.SYSTEM_RESET_SEARCH: {
      return {
        ...state,
        areaIds: [],
        deviceIds: [],
        categoryId: 0,
        facilityManagementIds: [],
        constructionManagementIds: [],
        primaryChargeIds: [],
        otherIds: [],
        systemName: "",
        note: "",
        scheStartDate: "",
        scheEndDate: "",
        closeStartDate: "",
        closeEndDate: "",
        totalTest: -1,
        preparation: "",
        items: [],
        fetching: false,
      };
    }

    case constants.SYSTEM_BEGIN_FETCH_ITEMS: {
      return {
        ...state,
        fetching: true,
      };
    }
    case constants.SYSTEM_END_FETCH_ITEMS: {
      return {
        ...state,
        masterItems: action.payload,
        fetching: false,
      };
    }
    case constants.SYSTEM_BEGIN_FETCH_ITEM: {
      return {
        ...state,
        fetching: true,
      };
    }
    case constants.SYSTEM_END_FETCH_ITEM: {
      return {
        ...state,
        editItem: action.payload,
        fetching: false,
      };
    }
    case constants.SYSTEM_BEGIN_SEARCH_ITEMS: {
      return {
        ...state,
        fetching: true,
      };
    }
    case constants.SYSTEM_END_SEARCH_ITEMS: {
      return {
        ...state,
        masterItems: action.payload.list,
        items: action.payload.list,
        fetching: false,
      };
    }
    case constants.SYSTEM_BEGIN_SEARCH_DETAIL_ITEMS: {
      return {
        ...state,
        fetching: true,
      };
    }
    case constants.SYSTEM_END_SEARCH_DETAIL_ITEMS: {
      return {
        ...state,
        detailItems: action.payload,
        fetching: false,
      };
    }

    case constants.SYSTEM_SET_VALIDATED: {
      return {
        ...state,
        validated: action.payload,
      };
    }
    case constants.SYSTEM_SET_CONDITIONS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case constants.SYSTEM_REVERT_CONDITION: {
      return {
        ...state,
        ...getInitialSearchConditions(true),
      };
    }
    case constants.SYSTEM_TOGGLE_DOWNLOADING: {
      return {
        ...state,
        downloading: action.payload,
      };
    }

    case constants.SYSTEM_END_UPDATE_ITEM: {
      const masterItems = state.masterItems.map((item) => {
        if (item.system_id === Number(action.payload.system_id)) {
          const updateItem = {
            background_color: item.background_color,
            item_add_date: item.item_add_date,
            preparation_complete_num: item.preparation_complete_num,
            preparation_num: item.preparation_num,
            result_progress_rate: item.result_progress_rate,
            roles: action.payload.roles,
            schedule_progress_rate: item.schedule_progress_rate,
            system_comment: action.payload.system_comment,
            system_id: action.payload.system_id,
            system_name: action.payload.system_name,
            timestamp: action.payload.timestamp,
            totaltest_result_date: action.payload.totaltest_result_date,
            totaltest_schedule_date: action.payload.totaltest_schedule_date,
          };

          return updateItem;
        }

        return item;
      });

      // const items = masterItems.filter(item => item.system_name.indexOf(state.text) >= 0);

      return {
        ...state,
        masterItems,
        // items
      };
    }
    case constants.APP_SHOW_ERROR: {
      if (action.errorFrom === "system") {
        return {
          ...state,
          fetching: false,
        };
      }

      return state;
    }
    default:
      return state;
  }
};

export default system;
