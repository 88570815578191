import { connect } from "react-redux";

import actions from "../../actions";
import System from "../../components/system/System";

const mapStateToProps = (state) => {
  let areas = [];
  let devices = [];
  let categories = [];
  let facilityManagements = [];
  let constructionManagements = [];
  let primaryCharges = [];
  let others = [];

  if (state.construction.masters) {
    areas = state.construction.masters.areas;
    devices = state.construction.masters.devices;
    categories = state.construction.masters.categories;
    facilityManagements = state.construction.masters.facility_managements;
    constructionManagements = state.construction.masters.construction_managements;
    primaryCharges = state.construction.masters.primary_charges;
    others = state.construction.masters.others;
  }

  return {
    areaIds: state.system.areaIds,
    deviceIds: state.system.deviceIds,
    categoryId: state.system.categoryId,
    facilityManagementIds: state.system.facilityManagementIds,
    constructionManagementIds: state.system.constructionManagementIds,
    primaryChargeIds: state.system.primaryChargeIds,
    otherIds: state.system.otherIds,
    systemName: state.system.systemName,
    note: state.system.note,
    scheStartDate: state.system.scheStartDate,
    scheEndDate: state.system.scheEndDate,
    closeStartDate: state.system.closeStartDate,
    closeEndDate: state.system.closeEndDate,
    totalTest: state.system.totalTest,
    preparation: state.system.preparation,
    masterItems: state.system.masterItems,
    detailItems: state.system.detailItems,
    editItem: state.system.editItem,
    fetching: state.system.fetching,
    validated: state.system.validated,
    downloading: state.system.downloading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    search: (params, callback) => dispatch(actions.system.search(params, callback)),
    searchItems: (params, callback) => dispatch(actions.system.searchItems(params, callback)),
    fetchItem: (id, callback) => dispatch(actions.system.fetchItem(id, callback)),
    resetSearch: () => dispatch(actions.system.resetSearch()),
    updateItem: (data, handleSuccess, handleCancel) =>
      dispatch(actions.system.updateItem(data, handleSuccess, handleCancel)),
    downloadFiles: (kind, fileIds, filetype, callback, fallback) =>
      dispatch(actions.common.downloadSystemFiles(kind, fileIds, filetype, callback, fallback)),
    revertLocalCondition: () => dispatch(actions.system.revertLocalCondition()),
    toggleDownloading: (downloading) => dispatch(actions.system.toggleDownloading(downloading)),
    setConditions: (conditions) => dispatch(actions.system.setConditions(conditions)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(System);
