import * as constants from "../constants/index";
import * as util from "../lib/matrix";

import { DragColumn, MatrixColumnKeyType, MatrixSearchParams, Process } from "@/models/matrix";
import { TaskSwitcherStatus, TaskSwitcherStatusType } from "@/models/tasks";

export type MatrixState = {
  columns: Process[];
  scheduleRoles: [number, number, number]; // [施工会社, 危険源, 低減策]の変更不可フラグ
  dragColumn?: DragColumn;
  dropIndex?: number;
  dragScroll?: string;
  rows: any[];
  approvals: any[];
  switcherStatus: TaskSwitcherStatusType;
  fetching: false;
  scheduleProgressRate: number;
  resultProgressRate: number;
  itemNum: number;
  completeProcessId: number;
  fetchingProgressRate: boolean;
  fetchingItemTasks: boolean;
  fetchingProcesses: boolean;
  isError: boolean;
  inheritingSearchConditions: boolean;
  downloading: boolean;
  loadingBulkUpdate: boolean;
  paramItemId: number;
  taskRoles: any[];
  titleSubmenu?: {
    x: number;
    y: number;
    index: number;
    item: object; // TODO 型定義
    canCopy: boolean;
    canDelete: boolean;
    canBulkOff: boolean;
    canSort: boolean;
  };
  processSubmenu?: { x: number; y: number; process: Process };
  // マトリクス表示設定
  configColumns: { [key in MatrixColumnKeyType]?: boolean };
  configHideInvalidRows: null | boolean;
  currentConditions?: MatrixSearchParams;
};

const updateItems = (items, itemId, item) => {
  return items.map((data) => {
    if (data.item_id === itemId) {
      return {
        ...data,
        ...item,
      };
    }

    return data;
  });
};

const updateItemTasks = (items, itemId, taskId, task) => {
  return items.map((data) => {
    if (data.item_id === itemId) {
      return {
        ...data,
        tasks: data.tasks.map((t) => {
          if (t.task_id === taskId) {
            return {
              ...t,
              ...task,
            };
          }

          return t;
        }),
      };
    }

    return data;
  });
};

const initialState: MatrixState = {
  columns: [],
  scheduleRoles: [0, 0, 0],
  dragColumn: undefined,
  dropIndex: -1,
  rows: [],
  approvals: [],
  switcherStatus: TaskSwitcherStatus.OFF,
  fetching: false,
  scheduleProgressRate: 0,
  resultProgressRate: 0,
  itemNum: 0,
  completeProcessId: 0,
  fetchingProgressRate: false,
  fetchingItemTasks: false,
  fetchingProcesses: false,
  isError: false,
  inheritingSearchConditions: false,
  downloading: false,
  loadingBulkUpdate: false,
  paramItemId: 0,
  taskRoles: [],
  configColumns: null,
  configHideInvalidRows: null,
  currentConditions: null,
};

const matrix = (state = { ...initialState }, action) => {
  switch (action.type) {
    case constants.APP_CHANGE_MENU: {
      if (action.payload !== "matrix") {
        return { ...initialState };
      }

      return state;
    }
    case constants.MATRIX_TOGGLE_DOWNLOADING: {
      return {
        ...state,
        downloading: action.payload,
      };
    }
    case constants.MATRIXSEARCH_BEGIN_SEARCH: {
      return {
        ...state,
        fetching: true,
        isError: false,
        currentConditions: action.payload,
      };
    }
    case constants.MATRIXSEARCH_END_SEARCH: {
      return {
        ...state,
        inheritingSearchConditions: false,
        fetching: false,
      };
    }
    case constants.MATRIXSEARCH_BEGIN_SEARCH_PROGRESS_RATE: {
      return {
        ...state,
        fetchingProgressRate: true,
      };
    }
    case constants.MATRIXSEARCH_END_SEARCH_PROGRESS_RATE: {
      return {
        ...state,
        scheduleProgressRate: action.payload.schedule_progress_rate,
        resultProgressRate: action.payload.result_progress_rate,
        fetchingProgressRate: false,
      };
    }
    case constants.MATRIXSEARCH_BEGIN_SEARCH_PROCESSES: {
      return {
        ...state,
        fetchingProcesses: true,
      };
    }
    case constants.MATRIXSEARCH_END_SEARCH_PROCESSES: {
      return {
        ...state,
        columns: action.payload.matrix_processes || [],
        scheduleRoles: action.payload.schedule_roles || [0, 0, 0],
        fetchingProcesses: false,
      };
    }
    case constants.MATRIXSEARCH_BEGIN_SEARCH_TASKS: {
      return {
        ...state,
        fetchingItemTasks: true,
      };
    }
    case constants.MATRIXSEARCH_END_SEARCH_TASKS: {
      return {
        ...state,
        completeProcessId: action.payload.complete_process_id,
        paramItemId: action.payload.item_id,
        rows: action.payload.list.map((item) => {
          return {
            ...item,
            processing: false,
            error: false,
          };
        }),
        taskRoles: action.payload.roles,
        fetchingItemTasks: false,
        itemNum: action.payload.total_num,
      };
    }
    case constants.MATRIXSEARCH_END_SEARCH_TASKS_CHIBA: {
      return {
        ...state,
        completeProcessId: action.payload.complete_process_id,
        paramItemId: action.payload.item_id,
        rows: action.payload.list.map((item) => {
          return {
            ...item,
            processing: false,
            error: false,
          };
        }),
        taskRoles: action.payload.roles,
        fetchingItemTasks: false,
        itemNum: action.payload.total_num,
      };
    }
    case constants.MATRIXSEARCH_END_SEARCH_TASKS_INFINITE: {
      return {
        ...state,
        rows: [
          ...state.rows,
          ...action.payload.list.map((t) => {
            return {
              ...t,
              processing: false,
              error: false,
            };
          }),
        ],
        fetching: false,
      };
    }
    case constants.MATRIX_CHANGE_SWITCHER_STATUS: {
      return {
        ...state,
        switcherStatus: action.payload,
      };
    }
    case constants.MATRIX_SAVE_APPROVALS: {
      return {
        ...state,
        approvals: action.payload,
      };
    }
    case constants.COMMON_BEGIN_CHANGE_TASK_STATUS: {
      const { item_id, task_id } = action.payload;

      return {
        ...state,
        rows: updateItemTasks(state.rows, item_id, task_id, { processing: true }),
      };
    }
    case constants.COMMON_END_CHANGE_TASK_STATUS: {
      const { item_id, task } = action.payload;

      return {
        ...state,
        rows: updateItemTasks(state.rows, item_id, task.task_id, {
          ...task,
          processing: false,
          error: false,
        }),
      };
    }
    case constants.COMMON_ERROR_CHANGE_TASK_STATUS: {
      const { item_id, task_id } = action.payload;

      return {
        ...state,
        rows: updateItemTasks(state.rows, item_id, task_id, {
          processing: false,
          error: true,
        }),
      };
    }
    case constants.MATRIX_END_BULK_UPDATE_PROCESS: {
      const { column, on } = action.payload;

      return {
        ...state,
        rows: state.rows.map((r) => {
          return {
            ...r,
            tasks: r.tasks.map((t) => {
              if (t.process_id === column.process_id) {
                return {
                  ...t,
                  valid_flg: on,
                };
              }

              return t;
            }),
          };
        }),
      };
    }
    case constants.MATRIX_END_UPDATE_ITEM: {
      const { itemId, data } = action.payload;

      return {
        ...state,
        rows: updateItems(state.rows, itemId, data),
      };
    }
    case constants.MATRIX_ADD_ITEM_PREV: {
      const index = action.payload;
      const emptyRow = util.createEmptyRow(state.rows[index]);

      return {
        ...state,
        rows: [...state.rows].splice(index, 0, emptyRow),
      };
    }
    case constants.MATRIX_ADD_ITEM_NEXT: {
      const index = action.payload;
      const emptyRow = util.createEmptyRow(state.rows[index]);

      return {
        ...state,
        rows: [...state.rows].splice(index + 1, 0, emptyRow),
      };
    }
    case constants.MATRIX_REMOVE_EMPTY_ITEM: {
      return {
        ...state,
        rows: state.rows.filter((r) => r.item_id !== 0),
      };
    }
    case constants.MATRIX_BEGIN_COPY_ITEM: {
      return {
        ...state,
        fetching: true,
      };
    }
    case constants.MATRIX_END_COPY_ITEM: {
      const { response, index } = action.payload;

      const newRows = [...state.rows];
      newRows.splice(index + 1, 0, response);

      return {
        ...state,
        rows: newRows,
        fetching: false,
      };
    }
    case constants.MATRIX_BEGIN_DELETE_ITEM: {
      return {
        ...state,
        fetching: true,
      };
    }
    case constants.MATRIX_END_DELETE_ITEM: {
      return {
        ...state,
        fetching: false,
        rows: state.rows.filter((r) => r.item_id !== action.payload),
      };
    }
    case constants.MATRIX_BEGIN_BULK_UPDATE_ITEM_STATUS: {
      return {
        ...state,
        fetching: true,
      };
    }
    case constants.MATRIX_END_BULK_UPDATE_ITEM_STATUS: {
      return {
        ...state,
        fetching: false,
        rows: state.rows.map((r) => {
          if (r.item_id === action.payload) {
            return {
              ...r,
              tasks: r.tasks.map((t) => {
                return {
                  ...t,
                  valid_flg: false,
                  result_start_date: "",
                  result_end_date: "",
                };
              }),
            };
          }

          return r;
        }),
      };
    }
    case constants.MATRIX_END_UPDATE_PROCESSES: {
      const { processIds, coordinate } = action.payload;

      return {
        ...state,
        columns: state.columns.map((c) => {
          if (processIds.some((processId) => processId === c.process_id)) {
            return {
              ...c,
              coordinate_flg: coordinate,
            };
          }

          return c;
        }),
      };
    }
    case constants.MATRIX_END_UPDATE_PROCESS: {
      const { processId, response } = action.payload;

      return {
        ...state,
        columns: state.columns.map((c) => {
          if (c.process_id === processId) {
            return {
              ...c,
              ...response,
            };
          }

          return c;
        }),
      };
    }
    case constants.MATRIX_END_UPDATE_PROCESS_RECEIVER: {
      const { processId, userId, groupId, userName } = action.payload;

      // 横連携設定時かつ受信者無しの場合警告表示
      const index = state.columns.findIndex((c) => c.process_id === processId);
      let coordinate = false;

      if (index >= 0) {
        coordinate = state.columns[index].coordinate_flg;
      }

      return {
        ...state,
        rows: state.rows.map((r) => {
          return {
            ...r,
            tasks: r.tasks.map((t) => {
              if (t.process_id === processId) {
                return {
                  ...t,
                  user_id: userId,
                  group_id: groupId,
                  user_name: userName,
                };
              }

              return t;
            }),
          };
        }),
      };
    }
    case constants.MATRIX_END_UPDATE_TASK_DETAIL: {
      const { itemId, taskId, data } = action.payload;

      return {
        ...state,
        rows: updateItemTasks(state.rows, itemId, taskId, data),
      };
    }
    case constants.SUMMARY_SELECT_CATEGORY_SUMMARY: {
      return {
        ...state,
        inheritingSearchConditions: true,
      };
    }
    case constants.MATRIX_BEGIN_BULK_UPDATE_TASK_STATUS: {
      return {
        ...state,
      };
    }
    case constants.MATRIX_END_BULK_UPDATE_TASK_STATUS: {
      return {
        ...state,
      };
    }
    case constants.MATRIX_BEGIN_BULK_UPDATE_SPECIAL_PROCESS: {
      return {
        ...state,
        loadingBulkUpdate: true,
      };
    }
    case constants.MATRIX_END_BULK_UPDATE_SPECIAL_PROCESS: {
      return {
        ...state,
        loadingBulkUpdate: false,
      };
    }
    case constants.MATRIX_BEGIN_BULK_UPDATE_PROTECTION: {
      return {
        ...state,
        loadingBulkUpdate: true,
      };
    }
    case constants.MATRIX_END_BULK_UPDATE_PROTECTION: {
      return {
        ...state,
        loadingBulkUpdate: false,
      };
    }
    case constants.APP_SHOW_ERROR: {
      if (action.errorFrom === "matrix") {
        return {
          ...state,
          fetching: false,
          isError: true,
        };
      }

      return state;
    }
    case constants.MATRIX_COLUMN_DRAG_START: {
      return { ...state, dragColumn: action.payload };
    }
    case constants.MATRIX_COLUMN_DRAG_OVER: {
      return { ...state, dropIndex: action.payload };
    }
    case constants.MATRIX_COLUMN_DRAG_SCROLL: {
      return { ...state, dragScroll: action.payload };
    }
    case constants.MATRIX_COLUMN_DRAG_END: {
      return { ...state, dragColumn: undefined, dropIndex: -1, dragScroll: undefined };
    }
    case constants.MATRIX_SET_TITLE_SUBMENU: {
      return { ...state, titleSubmenu: action.payload };
    }
    case constants.MATRIX_CLEAR_TITLE_SUBMENU: {
      return { ...state, titleSubmenu: undefined };
    }
    case constants.MATRIX_SET_COLUMN_SUBMENU: {
      return { ...state, processSubmenu: action.payload };
    }
    case constants.MATRIX_CLEAR_COLUMN_SUBMENU: {
      return { ...state, processSubmenu: undefined };
    }
    case constants.MATRIX_END_FETCH_COLUMN_CONFIG: {
      const response: null | { columns: { [key: string]: 0 | 1 }[]; hide_invalid_rows: 0 | 1 } = action.payload;
      const configColumns: { [key in MatrixColumnKeyType]?: boolean } = {};
      response?.columns?.forEach((c) =>
        Object.entries(c).forEach(([key, value]) => {
          configColumns[key] = !!value;
        })
      );
      const configHideInvalidRows = !!response?.hide_invalid_rows;

      return { ...state, configColumns: !response ? null : configColumns, configHideInvalidRows };
    }
    default:
      return state;
  }
};

export default matrix;
