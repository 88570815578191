import { connect } from "react-redux";

import actions from "../../actions";
import ItemEditor from "../../components/matrix/ItemEditor";

const mapStateToProps = (state) => {
  let extensions = [];
  let fileUpload = true;

  if (state.construction.extensions) {
    extensions = state.construction.extensions.extensions.filter((item) => item.extension_type === 5);
    if (extensions.length > 0) {
      const config = JSON.parse(extensions[0].config);
      fileUpload = config.file_upload.item;
    }
  }

  return {
    masters: state.construction.masters,
    fileUpload,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSubject: (itemId, callback) => dispatch(actions.matrix.fetchSubject(itemId, callback)),
    updateItem: (itemId, data, callback, failedCallback) =>
      dispatch(actions.matrix.updateItem(itemId, data, callback, failedCallback)),
    upload: (data, onProgress, onSuccess, onError) =>
      dispatch(actions.app.upload(data, onProgress, onSuccess, onError)),
    download: (kind, fileId, callback) => dispatch(actions.common.downloadAttachmentFile(kind, fileId, callback)),
    removeFile: (title, messages, okClickHandler) => {
      dispatch(
        actions.app.showConfirm(
          title,
          messages,
          () => {
            okClickHandler();
            dispatch(actions.app.hideConfirm());
          },
          () => {
            dispatch(actions.app.hideConfirm());
          }
        )
      );
    },
    setUnloadAlert: () => dispatch(actions.app.setUnloadAlert()),
    clearUnloadAlert: () => dispatch(actions.app.clearUnloadAlert()),
    showAlert: (title, messages) =>
      dispatch(actions.app.showAlert(title, messages, () => dispatch(actions.app.hideAlert()))),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemEditor);
