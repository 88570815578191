import moment from "moment";
import React, { useMemo, forwardRef } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

import Select from "../common/Select";

import { FormGrid, FormGroup, FormRow, Spacer } from "@/components/common";
import { SearchConditionConfigModal } from "@/components/common/SearchCondition/SearchConditionConfigModal";
import { SearchConditionDisplayButton } from "@/components/common/SearchCondition/SearchConditionDisplayButton";
import { SettingIconButton } from "@/components/common/SettingIconButton";
import { useSearchConditionConfig } from "@/hooks/useSearchConditionConfig";
import {
  Status,
  FileType,
  ErrorState,
  ImportSearchKey,
  ImportSearchKeyType,
  ImportSearchParams,
} from "@/models/import";
import { ConstructionMasters } from "@/models/masters";
import { SearchConditionConfigDefinition, ScreenName } from "@/models/screenConfig";

import "react-datepicker/dist/react-datepicker.css";

type Props = {
  error: ErrorState;
  masters: ConstructionMasters;
  categoryId: number;
  fileName: string;
  statusId: number;
  import_start_date?: string;
  import_end_date?: string;
  userId: number;
  fileType: number;
  statuses: Status[];
  filetypes: FileType[];
};

type DispatchProps = {
  changeUser: (userId: number) => void;
  changeCategory: (categoryId: number) => void;
  resetSearch: () => void;
  changeFilename: (fileName: string) => void;
  changeStatus: (statusId: number) => void;
  changeFiletype: (fileType: number) => void;
  changeDateFrom: (date: string) => void;
  changeDateTo: (date: string) => void;
  handleSearch: (option?: { params?: ImportSearchParams }) => void;
  handleReset: () => void;
};

type SearchPanelProps = Props & DispatchProps;

const SearchPanel = forwardRef<HTMLDivElement, SearchPanelProps>((props, ref) => {
  const { t } = useTranslation();

  // データ取り込み画面の検索条件設定の定義
  const importSearchConditionDefinitions = useMemo<SearchConditionConfigDefinition<ImportSearchKeyType>[]>(
    () => [
      {
        itemsPerLine: 3,
        items: [
          { key: ImportSearchKey.Category, label: t("machines_category") },
          { key: ImportSearchKey.FileName, label: t("file_name") },
          { key: ImportSearchKey.Status, label: t("status") },
          { key: ImportSearchKey.ImportDate, label: t("imported_at") },
          { key: ImportSearchKey.User, label: t("imported_by") },
          { key: ImportSearchKey.FileType, label: t("imported_class") },
        ],
      },
    ],
    []
  );

  // 検索条件設定
  const config = useSearchConditionConfig<ImportSearchKeyType>({
    screenName: ScreenName.IMPORT,
    requiredKeys: importSearchConditionDefinitions.flatMap((v) =>
      v.items.filter((item) => item.required).map((item) => item.key)
    ),
  });

  const handleSearch = (e: React.MouseEvent) => {
    e.preventDefault();

    if (config.showAllConditions) {
      props.handleSearch();
    } else {
      // 検索条件設定で表示している検索条件のみ有効とする
      props.handleSearch({
        params: {
          categoryId: config.map.get(ImportSearchKey.Category) ? props.categoryId : 0,
          fileName: config.map.get(ImportSearchKey.FileName) ? props.fileName : "",
          statusId: config.map.get(ImportSearchKey.Status) ? props.statusId : -1,
          import_start_date: config.map.get(ImportSearchKey.ImportDate) ? props.import_start_date : "",
          import_end_date: config.map.get(ImportSearchKey.ImportDate) ? props.import_end_date : "",
          userId: config.map.get(ImportSearchKey.User) ? props.userId : 0,
          fileType: config.map.get(ImportSearchKey.FileType) ? props.fileType : 0,
        },
      });
    }
  };

  const importStartDate = useMemo(
    () => (props.import_start_date ? moment(props.import_start_date).toDate() : null),
    [props.import_start_date]
  );
  const importEndDate = useMemo(
    () => (props.import_end_date ? moment(props.import_end_date).toDate() : null),
    [props.import_end_date]
  );

  const handleChangeDateFrom = (date: Date | null) => {
    props.changeDateFrom(
      date
        ? date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)
        : ""
    );
  };

  const handleChangeDateTo = (date: Date | null) => {
    props.changeDateTo(
      date
        ? date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)
        : ""
    );
  };

  const {
    error,
    masters,
    categoryId,
    fileName,
    statusId,
    userId,
    fileType,
    statuses,
    filetypes,
    changeCategory,
    changeFilename,
    changeUser,
    changeStatus,
    changeFiletype,
    resetSearch,
    handleReset,
  } = props;

  if (!config.map) return <></>;

  return (
    <div ref={ref}>
      <div className="flex justify-end bg-[#e4e4e4] w-[1200px]">
        <SettingIconButton className="w-[32px] p-[8px]" onClick={config.openSettingModal} />
      </div>
      <div className="search-box">
        <div className="grid">
          <FormGrid
            className="grid-cols-3"
            display={config.show([
              ImportSearchKey.Category,
              ImportSearchKey.FileName,
              ImportSearchKey.Status,
              ImportSearchKey.ImportDate,
              ImportSearchKey.User,
              ImportSearchKey.FileType,
            ])}
          >
            <FormGroup
              title={t("machines_category")}
              className="w-340"
              display={config.show([ImportSearchKey.Category])}
            >
              <Select
                prefix="category"
                isMulti={false}
                isClearable={true}
                options={masters?.categories || []}
                onChange={changeCategory}
                value={categoryId}
              />
            </FormGroup>
            <FormGroup title={t("file_name")} className="w-330" display={config.show([ImportSearchKey.FileName])}>
              <input
                data-test-id="text-import-file-name"
                type="text"
                className="form-control w-240"
                value={fileName}
                onChange={(e) => changeFilename(e.target.value)}
              />
            </FormGroup>
            <FormGroup title={t("status")} className="w-260" display={config.show([ImportSearchKey.Status])}>
              <select
                data-test-id="text-import-status"
                className="form-control w-170"
                value={statusId}
                onChange={(e) => changeStatus(Number(e.target.value))}
              >
                <option key={-1} value={-1}>
                  -----
                </option>
                {statuses.map((status) => (
                  <option key={status.status_id} value={status.status_id}>
                    {status.status_name}
                  </option>
                ))}
              </select>
            </FormGroup>
            <FormGroup title={t("imported_at")} className="w-360" display={config.show([ImportSearchKey.ImportDate])}>
              <div className="d-ib">
                <DatePicker
                  wrapperClassName="w-120"
                  disabled={false}
                  selected={importStartDate}
                  dateFormat="yyyy/MM/dd"
                  locale={t("calender_locale")}
                  onChange={handleChangeDateFrom}
                />
              </div>
              <div className="d-ib w-30 ta-c">&nbsp;〜&nbsp;</div>
              <div className="d-ib">
                <DatePicker
                  wrapperClassName="w-120"
                  disabled={false}
                  selected={importEndDate}
                  dateFormat="yyyy/MM/dd"
                  locale={t("calender_locale")}
                  onChange={handleChangeDateTo}
                />
              </div>
              {error.import_start_date && (
                <div className="form-error w-380">
                  <p className="form-error-message w-300">{error.import_start_date}</p>
                </div>
              )}
            </FormGroup>
            <FormGroup title={t("imported_by")} className="w-330" display={config.show([ImportSearchKey.User])}>
              <Select
                prefix="user"
                isMulti={false}
                isClearable={true}
                options={masters?.users || []}
                onChange={changeUser}
                value={userId}
              />
            </FormGroup>
            <FormGroup title={t("imported_class")} className="w-260" display={config.show([ImportSearchKey.FileType])}>
              <select
                data-test-id="text-import-file-type"
                className="form-control w-170"
                value={fileType}
                onChange={(e) => changeFiletype(Number(e.target.value))}
              >
                <option key={0} value={0}>
                  -----
                </option>
                {filetypes.map((filetype) => (
                  <option key={filetype.type_id} value={filetype.type_id}>
                    {filetype.type_name}
                  </option>
                ))}
              </select>
            </FormGroup>
          </FormGrid>
          <FormRow className="justify-between mt-[16px]">
            <Spacer x="210px" />
            <SearchConditionDisplayButton
              isExpanded={config.showAllConditions}
              onChange={config.toggleDisplayOptionalConditions}
            />
            <div className="flex gap-x-4">
              <button
                data-test-id="button-import-reset"
                className="btn btn-gray"
                onClick={() => {
                  resetSearch();
                  handleReset();
                }}
              >
                {t("reset")}
              </button>
              <button data-test-id="button-import-search" className="btn btn-blue" onClick={handleSearch}>
                {t("search")}
              </button>
            </div>
          </FormRow>
        </div>
      </div>
      {config.showSettingModal && (
        <SearchConditionConfigModal<ImportSearchKeyType>
          title={t("search_condition_config")}
          configMap={config.map}
          definitions={importSearchConditionDefinitions}
          onClose={config.closeSettingModal}
          onSubmit={(c) => config.saveSearchItemConfig(c)}
        />
      )}
    </div>
  );
});

SearchPanel.displayName = "SearchPanel";

export default SearchPanel;
