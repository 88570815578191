import * as constants from "../constants/index";
import environments from "../environments";
import * as util from "../lib/matrix";
import restoreState from "../lib/restoreState";

import actions from "./";

import { CreateProcessInput, SortProcessInput } from "@/actions/types/matrix";
import { DragColumn, MatrixItem, MatrixSearchParams, Process, ProcessSegmentLabel } from "@/models/matrix";
import { Timestamp } from "@/sx-layout/models";
import { RootState } from "@/reducers/types";

export const saveApprovals = (approvals) => {
  return {
    type: constants.MATRIX_SAVE_APPROVALS,
    payload: approvals,
  };
};

export const changeSwitcherStatus = (status) => {
  return {
    type: constants.MATRIX_CHANGE_SWITCHER_STATUS,
    payload: status,
  };
};

export const toggleDownloading = (downloading = false) => ({
  type: constants.MATRIX_TOGGLE_DOWNLOADING,
  payload: downloading,
});

export const bulkUpdateProcess = (
  data: { on: boolean; column: Process; searchParams: MatrixSearchParams },
  callback
) => {
  const endpoint = `${environments.API_BASE_URI}/matrix/process_tasks/update`;

  const { on, column, searchParams } = data;
  const params = {
    process_id: column.process_id,
    valid_flg: on,
    category_id: searchParams.categoryId,
    user_id: searchParams.userIds,
    group_id: searchParams.groupIds,
    area_id: searchParams.areaIds,
    device_id: searchParams.deviceIds,
    facility_management_id: searchParams.facilityManagementIds,
    construction_management_id: searchParams.constructionManagementIds,
    primary_charge_id: [searchParams.primaryChargeId],
    other_id: searchParams.otherIds,
    system_id: searchParams.systemIds,
    process_middle_class_id: searchParams.processMiddleClassIds,
    company_id: searchParams.companyIds,
    item_text: searchParams.itemText,
    process_text: searchParams.processText,
    regulation: searchParams.regulation,
    filter: searchParams.filters,
  };

  const urlState = restoreState();

  if (urlState.item_id) {
    params.item_id = Number(urlState.item_id);
  }

  const confirmMessage = data.on
    ? [`${data.column.process_name}`, "put_all_sure1", "put_all_sure2"]
    : [`${data.column.process_name}`, "*eliminate_all_sure1*", "eliminate_all_sure2", "*eliminate_all_sure3*"];

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(params),
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIX_BEGIN_BULK_UPDATE_PROCESS,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(data);
        }

        return {
          type: constants.MATRIX_END_BULK_UPDATE_PROCESS,
          payload: data,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
    meta: {
      confirm: {
        messages: confirmMessage,
      },
    },
  };
};

export const updateItem = (itemId, data, callback, failedCallback) => {
  const endpoint = `${environments.API_BASE_URI}/items/update/${itemId}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(data),
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIX_BEGIN_UPDATE_ITEM,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.MATRIX_END_UPDATE_ITEM,
          payload: {
            itemId,
            data: response,
          },
        };
      },
      error: (response) => {
        if (failedCallback !== undefined) {
          failedCallback();
        }

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const sortItem = (
  itemId: number,
  target_item_id: number,
  searchParams: MatrixSearchParams & { hideInvalidRows?: boolean },
  callback?: () => void,
  failedCallback?: () => void
) => {
  const endpoint = `${environments.API_BASE_URI}/items/sort/${itemId}`;

  const params = {
    target_item_id,
    category_id: searchParams.categoryId,
    primary_charge_id: searchParams.primaryChargeId,
    area_id: searchParams.areaIds,
    device_id: searchParams.deviceIds,
    facility_management_id: searchParams.facilityManagementIds,
    construction_management_id: searchParams.constructionManagementIds,
    other_id: searchParams.otherIds,
    regulation: searchParams.regulation,
    system_id: searchParams.systemIds,
    user_id: searchParams.userIds,
    group_id: searchParams.groupIds,
    item_text: searchParams.itemText,
    filter: searchParams.filters,
    hide_invalid_rows_flg: searchParams.hideInvalidRows ? true : undefined,
  };

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(params),
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIX_BEGIN_SORT_ITEM,
        };
      },
      success: (response) => {
        callback?.();

        return {
          type: constants.MATRIX_END_SORT_ITEM,
          payload: { itemId, data: response },
        };
      },
      error: (response) => {
        failedCallback?.();

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateItemChiba = (itemId, data, callback, failedCallback) => {
  const endpoint = `${environments.API_BASE_URI}/items_chiba/update/${itemId}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(data),
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIX_BEGIN_UPDATE_ITEM,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.MATRIX_END_UPDATE_ITEM,
          payload: {
            itemId,
            data: response,
          },
        };
      },
      error: (response) => {
        if (failedCallback !== undefined) {
          failedCallback();
        }

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateNote = (itemId, data, callback) => {
  const endpoint = `${environments.API_BASE_URI}/items/note/update/${itemId}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(data),
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIX_BEGIN_UPDATE_ITEM,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.MATRIX_END_UPDATE_ITEM,
          payload: {
            itemId,
            data: response,
          },
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateDelivery = (itemId, data, callback) => {
  const endpoint = `${environments.API_BASE_URI}/items/delivery_flg/update/${itemId}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(data),
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIX_BEGIN_UPDATE_ITEM,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.MATRIX_END_UPDATE_ITEM,
          payload: {
            itemId,
            data: response,
          },
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateAttention = (itemId, data, callback) => {
  const endpoint = `${environments.API_BASE_URI}/items/attention_flg/update/${itemId}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(data),
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIX_BEGIN_UPDATE_ITEM,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.MATRIX_END_UPDATE_ITEM,
          payload: {
            itemId,
            data: response,
          },
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const addItemPrev = (index) => ({
  type: constants.MATRIX_ADD_ITEM_PREV,
  payload: index,
});

export const addItemNext = (index) => ({
  type: constants.MATRIX_ADD_ITEM_NEXT,
  payload: index,
});

export const removeEmptyItem = () => ({
  type: constants.MATRIX_REMOVE_EMPTY_ITEM,
});

export const copyItem = (index, data, callback) => {
  const endpoint = `${environments.API_BASE_URI}/items/copy`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({ item_id: data.item_id }),
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIX_BEGIN_COPY_ITEM,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.MATRIX_END_COPY_ITEM,
          payload: {
            index,
            src: data,
            response,
          },
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
    meta: {
      confirm: {
        messages: [`${data.item_name}`, "copy_sure"],
      },
    },
  };
};

export const copyItemChiba = (index, data, callback) => {
  const endpoint = `${environments.API_BASE_URI}/items_chiba/copy`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({ item_id: data.item_id }),
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIX_BEGIN_COPY_ITEM,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.MATRIX_END_COPY_ITEM,
          payload: {
            index,
            src: data,
            response,
          },
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
    meta: {
      confirm: {
        messages: [`${data.item_name}`, "copy_sure"],
      },
    },
  };
};

export const deleteItem = (itemId, data, callback) => {
  const endpoint = `${environments.API_BASE_URI}/items/delete/${itemId}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({ timestamp: data.timestamp }),
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIX_BEGIN_DELETE_ITEM,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.MATRIX_END_DELETE_ITEM,
          payload: itemId,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
    meta: {
      confirm: {
        messages: [`${data.item_name}`, "remove_sure"],
      },
    },
  };
};

export const bulkUpdateItemStatus = (itemId, data, callback) => {
  const endpoint = `${environments.API_BASE_URI}/matrix/item_tasks/update`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({
      valid_flg: false,
      item_id: itemId,
    }),
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIX_BEGIN_BULK_UPDATE_ITEM_STATUS,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.MATRIX_END_BULK_UPDATE_ITEM_STATUS,
          payload: itemId,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
    meta: {
      confirm: {
        messages: [`${data.item_name}`, "*eliminate_all_sure1*", "eliminate_all_sure2", "*eliminate_all_sure3*"],
      },
    },
  };
};

export const updateProcesses = (processIds, coordinate, callback, failedCallback) => {
  const endpoint = `${environments.API_BASE_URI}/matrix/processes/update`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({
      process_ids: processIds,
      coordinate_flg: coordinate,
    }),
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIX_BEGIN_UPDATE_PROCESSES,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.MATRIX_END_UPDATE_PROCESSES,
          payload: {
            processIds,
            coordinate,
          },
        };
      },
      error: (response) => {
        failedCallback && failedCallback(response);

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const fetchProcess = (processId, callback) => {
  const endpoint = `${environments.API_BASE_URI}/processes/${processId}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIX_BEGIN_FETCH_PROCESS,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.MATRIX_END_FETCH_PROCESS,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const createProcess = (data: CreateProcessInput, callback, failedCallback) => {
  const endpoint = `${environments.API_BASE_URI}/processes/create`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(data),
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIX_BEGIN_CREATE_PROCESS,
        };
      },
      success: (response) => {
        callback?.(response);

        return {
          type: constants.MATRIX_END_CREATE_PROCESS,
          payload: response,
        };
      },
      error: (response) => {
        failedCallback?.(response);

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateProcess = (processId, data, callback, failedCallback) => {
  const endpoint = `${environments.API_BASE_URI}/processes/update/${processId}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(data),
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIX_BEGIN_UPDATE_PROCESS,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.MATRIX_END_UPDATE_PROCESS,
          payload: {
            processId,
            response,
          },
        };
      },
      error: (response) => {
        failedCallback && failedCallback(response);

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const deleteProcess = (processId: number, data: { timestamp: Timestamp }, callback, failedCallback) => {
  const endpoint = `${environments.API_BASE_URI}/processes/delete/${processId}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(data),
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIX_BEGIN_DELETE_PROCESS,
        };
      },
      success: (response) => {
        callback?.(response);

        return {
          type: constants.MATRIX_END_DELETE_PROCESS,
          payload: response,
        };
      },
      error: (response) => {
        failedCallback?.(response);

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const sortProcess = (processId: number, data: SortProcessInput, callback?, failedCallback?) => {
  const endpoint = `${environments.API_BASE_URI}/processes/sort/${processId}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(data),
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIX_BEGIN_SORT_PROCESS,
        };
      },
      success: (response) => {
        callback?.(response);

        return {
          type: constants.MATRIX_END_SORT_PROCESS,
          payload: response,
        };
      },
      error: (response) => {
        failedCallback?.(response);

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const fetchProcessSegment = (
  processId: number,
  callback: (res: { process_id: number; segment: string }) => void,
  fallback?
) => {
  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/processes/${processId}/segment`,
    method: "GET",
    callbacks: {
      success: (response) => {
        callback?.(response);

        return {
          type: constants.MATRIX_END_FETCH_PROCESS_SEGMENT,
        };
      },
      error: (response) => {
        fallback?.();

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateProcessSegment = (processId: number, params: { segment: string }, callback, fallback?) => {
  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/processes/${processId}/segment/update`,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(params),
    callbacks: {
      success: (response) => {
        callback?.(response);

        return {
          type: constants.MATRIX_END_UPDATE_PROCESS_SEGMENT,
        };
      },
      error: (response) => {
        fallback && fallback(response);

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const fetchSegmentLabels = (
  params: { category_id: number; primary_charge_id: number },
  callback: (res: ProcessSegmentLabel[]) => void,
  fallback?: () => void
) => {
  const query = new URLSearchParams();
  query.set("category_id", params.category_id?.toString());
  query.set("primary_charge_id", params.primary_charge_id?.toString());
  const endpoint = `${environments.API_BASE_URI}/segment_labels?${query.toString()}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      success: (res) => {
        const response: ProcessSegmentLabel[] = res.segment_labels.map((l) => ({
          ...l,
          category_id: l.category_id ?? l.categoryId,
          primary_charge_id: l.primary_charge_id ?? l.primaryChargeId,
          segment_label_id: l.segment_label_id ?? l.segmentLabelId,
          segment_label_name: l.segment_label_name ?? l.segmentLabelName,
        }));
        callback?.(response);

        return {
          type: constants.MATRIX_END_FETCH_PROCESS_SEGMENT_LABELS,
        };
      },
      error: (response) => {
        fallback?.();

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateSegmentLabel = (
  params: { category_id: number; primary_charge_id: number; sort: number; segment_label_name: string },
  callback,
  fallback?
) => {
  const endpoint = `${environments.API_BASE_URI}/segment_labels/update`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(params),
    callbacks: {
      success: (res) => {
        const response: ProcessSegmentLabel[] = {
          ...res,
          category_id: res.category_id ?? res.categoryId,
          primary_charge_id: res.primary_charge_id ?? res.primaryChargeId,
          segment_label_id: res.segment_label_id ?? res.segmentLabelId,
          segment_label_name: res.segment_label_name ?? res.segmentLabelName,
        };
        callback?.(response);

        return {
          type: constants.MATRIX_END_UPDATE_PROCESS_SEGMENT_LABELS,
        };
      },
      error: (response) => {
        fallback && fallback(response);

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateProcessReceiver = (process, data, callback, cancelHandler, failedCallback) => {
  return (dispatch, getState) => {
    const state = getState();
    const searchParams = state.matrixSearch;
    const endpoint = `${environments.API_BASE_URI}/matrix/process_tasks/update`;
    const confirmMessage = `${process.process_name}`;
    const payload = {
      process_id: process.process_id,
      ...data,
      category_id: searchParams.categoryId,
      user_id: searchParams.userIds,
      group_id: searchParams.groupIds,
      area_id: searchParams.areaIds,
      device_id: searchParams.deviceIds,
      facility_management_id: searchParams.facilityManagementIds,
      construction_management_id: searchParams.constructionManagementIds,
      primary_charge_id: [searchParams.primaryChargeId],
      other_id: searchParams.otherIds,
      system_id: searchParams.systemIds,
      process_middle_class_id: searchParams.processMiddleClassIds,
      company_id: searchParams.companyIds,
      item_text: searchParams.itemText,
      process_text: searchParams.processText,
      regulation: searchParams.regulation,
      filter: searchParams.filters,
    };

    const urlState = restoreState();

    if (urlState.item_id) {
      payload.item_id = Number(urlState.item_id);
    }

    dispatch({
      type: constants.APP_CALL_API,
      endpoint,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(payload),
      callbacks: {
        begin: () => {
          return {
            type: constants.MATRIX_BEGIN_UPDATE_PROCESS_RECEIVER,
          };
        },
        success: (response) => {
          if (callback !== undefined) {
            callback(response);
          }

          let userName = "";

          if (data.receiver_user_id > 0) {
            const user = state.construction.masters.users.find((d) => d.user_id === data.receiver_user_id);

            if (user) {
              userName = user.user_name;
            }
          } else if (data.receiver_group_id > 0) {
            const group = state.construction.masters.groups.find((d) => d.group_id === data.receiver_group_id);

            if (group) {
              userName = group.group_name;
            }
          }

          return {
            type: constants.MATRIX_END_UPDATE_PROCESS_RECEIVER,
            payload: {
              processId: process.process_id,
              userId: data.receiver_user_id,
              groupId: data.receiver_group_id,
              userName,
            },
          };
        },
        error: (response) => {
          failedCallback && failedCallback();

          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
      meta: {
        confirm: {
          messages: ["overwrite_all_details_sure1", confirmMessage, "overwrite_all_details_sure2"],
          cancelHandler,
        },
      },
    });
  };
};

export const fetchItems = (
  searchParams: MatrixSearchParams & {
    hideInvalidRows?: boolean;
  },
  callback: (response: MatrixItem[]) => void
) => {
  let endpoint = `${environments.API_BASE_URI}/items`;
  const query = util.getQueryByParams(searchParams);
  if (searchParams.hideInvalidRows) {
    query.set("hide_invalid_rows_flg", "true");
  }
  if (query.toString() !== "") {
    endpoint = `${endpoint}?${query.toString()}`;
  }

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIX_BEGIN_FETCH_SUBJECTS,
        };
      },
      success: (response) => {
        const itemList = response?.list ?? [];
        callback?.(itemList);

        return {
          type: constants.MATRIX_END_FETCH_SUBJECTS,
          payload: itemList,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const fetchSubject = (itemId, callback) => {
  const endpoint = `${environments.API_BASE_URI}/items/${itemId}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIX_BEGIN_FETCH_ITEM,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.MATRIX_END_FETCH_ITEM,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const fetchSubjectChiba = (itemId, callback) => {
  const endpoint = `${environments.API_BASE_URI}/items_chiba/${itemId}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIX_BEGIN_FETCH_ITEM,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.MATRIX_END_FETCH_ITEM,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const reloadProgressRate = (callback) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(actions.matrixSearch.searchProgressRate(state.matrixSearch, callback));
  };
};

export const fetchTaskDetail = (taskId, callback) => {
  const endpoint = `${environments.API_BASE_URI}/tasks/${taskId}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIX_BEGIN_FETCH_TASK_DETAIL,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.MATRIX_END_FETCH_TASK_DETAIL,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateTaskDetail = (itemId, taskId, data, callback, failedCallback, reload?: () => void) => {
  const endpoint = `${environments.API_BASE_URI}/tasks/update/${taskId}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(data),
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIX_BEGIN_UPDATE_TASK_DETAIL,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.MATRIX_END_UPDATE_TASK_DETAIL,
          payload: {
            itemId,
            taskId,
            data: response,
          },
        };
      },
      error: (response) => {
        if (failedCallback !== undefined) {
          failedCallback(response);
        }

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
          reload,
        };
      },
    },
  };
};

export const download = (params = null, callback, fallback) => {
  let endpoint = `${environments.API_BASE_URI}/matrix/files`;
  const query = util.getQueryByParams(params);

  endpoint = `${endpoint}?${query.toString()}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      success: (response, fileName) => {
        if (callback !== undefined) {
          callback(response, fileName);
        }

        return {
          type: constants.MATRIX_END_DOWNLOAD,
        };
      },
      error: (response) => {
        fallback && fallback();

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const fetchTaskNum = (scheduleType, aheadDays, callback, fallback) => {
  return (dispatch, getState) => {
    const state = getState();
    const query = util.getQueryByParams(state.matrixSearch);

    let endpoint = `${environments.API_BASE_URI}/matrix/task_num`;
    endpoint += `?valid_flg=true&schedule_type=${scheduleType}&ahead_days=${aheadDays}&status=0&${query.toString()}`;

    dispatch({
      type: constants.APP_CALL_API,
      endpoint,
      method: "GET",
      callbacks: {
        success: (response) => {
          if (callback !== undefined) {
            callback(response);
          }

          return {
            type: constants.MATRIX_END_FETCH_TASK_NUM,
          };
        },
        error: (response) => {
          fallback && fallback();

          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    });
  };
};

export const bulkUpdateTaskStatus = (params, callback, fallback) => {
  return (dispatch, getState) => {
    const state: RootState = getState();
    const searchParams = {
      category_id: state.matrixSearch.categoryId,
      user_id: state.matrixSearch.userIds,
      group_id: state.matrixSearch.groupIds,
      area_id: state.matrixSearch.areaIds,
      device_id: state.matrixSearch.deviceIds,
      facility_management_id: state.matrixSearch.facilityManagementIds,
      construction_management_id: state.matrixSearch.constructionManagementIds,
      primary_charge_id: [state.matrixSearch.primaryChargeId],
      other_id: state.matrixSearch.otherIds,
      system_id: state.matrixSearch.systemIds,
      company_id: state.matrixSearch.companyIds,
      process_middle_class_id: state.matrixSearch.processMiddleClassIds,
      item_text: state.matrixSearch.itemText,
      process_text: state.matrixSearch.processText,
      regulation: state.matrixSearch.regulation,
      filter: state.matrixSearch.filters,
    };

    const body = { ...searchParams, ...params };

    const endpoint = `${environments.API_BASE_URI}/matrix/tasks/update`;

    dispatch({
      type: constants.APP_CALL_API,
      endpoint,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(body),
      callbacks: {
        begin: () => {
          return {
            type: constants.MATRIX_BEGIN_BULK_UPDATE_TASK_STATUS,
          };
        },
        success: (response) => {
          callback?.(response);

          return {
            type: constants.MATRIX_END_BULK_UPDATE_TASK_STATUS,
          };
        },
        error: (response) => {
          fallback?.();

          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    });
  };
};

export const bulkUpdateSpecialProcess = (data, callback, failedCallback) => {
  const endpoint = `${environments.API_BASE_URI}/matrix/special_processes/update`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(data),
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIX_BEGIN_BULK_UPDATE_SPECIAL_PROCESS,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.MATRIX_END_BULK_UPDATE_SPECIAL_PROCESS,
          payload: {
            response,
          },
        };
      },
      error: (response) => {
        failedCallback && failedCallback(response);

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const bulkUpdateProtection = (data, callback, failedCallback) => {
  const endpoint = `${environments.API_BASE_URI}/matrix/protective_equipments/update`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(data),
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIX_BEGIN_BULK_UPDATE_PROTECTION,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.MATRIX_END_BULK_UPDATE_PROTECTION,
          payload: {
            response,
          },
        };
      },
      error: (response) => {
        failedCallback && failedCallback(response);

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateRemark = (itemId, data, callback) => {
  const endpoint = `${environments.API_BASE_URI}/items/remark/update/${itemId}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(data),
    callbacks: {
      begin: () => {
        return {
          type: constants.MATRIX_BEGIN_UPDATE_ITEM,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.MATRIX_END_UPDATE_ITEM,
          payload: {
            itemId,
            data: response,
          },
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const columnDragStart = (column: DragColumn) => {
  return {
    type: constants.MATRIX_COLUMN_DRAG_START,
    payload: column,
  };
};

export const columnDragOver = (index: number) => {
  return {
    type: constants.MATRIX_COLUMN_DRAG_OVER,
    payload: index,
  };
};

export const columnDragScroll = (to: string) => {
  return {
    type: constants.MATRIX_COLUMN_DRAG_SCROLL,
    payload: to,
  };
};

export const columnDragEnd = () => {
  return {
    type: constants.MATRIX_COLUMN_DRAG_END,
    payload: undefined,
  };
};

export const setTitleSubmenu = (info: { x; y; index; item; canCopy; canDelete; canBulkOff; canSort }) => ({
  type: constants.MATRIX_SET_TITLE_SUBMENU,
  payload: info,
});

export const clearTitleSubmenu = () => ({
  type: constants.MATRIX_CLEAR_TITLE_SUBMENU,
  payload: undefined,
});

export const setProcessSubmenu = (info: { x; y; process }) => ({
  type: constants.MATRIX_SET_COLUMN_SUBMENU,
  payload: info,
});

export const clearProcessSubmenu = () => ({
  type: constants.MATRIX_CLEAR_COLUMN_SUBMENU,
  payload: undefined,
});

export const setFetchedColumnConfig = (response) => ({
  type: constants.MATRIX_END_FETCH_COLUMN_CONFIG,
  payload: response,
});
