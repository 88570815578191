import React, { FC } from "react";

import LeftGridRowChibaContainer from "../../containers/matrix/LeftGridRowChibaContainer";
import LeftGridRowContainer from "../../containers/matrix/LeftGridRowContainer";

type Props = {
  rows: any[];
  totalNum: number;
  showItemEditor: (id) => void;
  offset: number;
  maxLength: number;
  isChiba: boolean;
  startPos: number;
};

export const LeftGridBody: FC<Props> = ({
  rows,
  offset,
  maxLength,
  isChiba,
  startPos,
  totalNum,
  showItemEditor,
}: Props) => {
  return (
    <table className={`grid-table grid-table-body grid-table-left${isChiba ? "-chiba" : ""} grid-table-left-body`}>
      <tbody>
        {rows.map((row, index) => {
          return index >= offset && index < offset + maxLength ? (
            isChiba ? (
              <LeftGridRowChibaContainer
                key={index}
                index={index}
                row={row}
                showItemEditor={showItemEditor}
                startPos={startPos}
                totalNum={totalNum}
              />
            ) : (
              <LeftGridRowContainer
                key={index}
                index={index}
                isLastIndex={index === rows.length - 1}
                row={row}
                showItemEditor={showItemEditor}
                startPos={startPos}
                totalNum={totalNum}
              />
            )
          ) : (
            <tr key={index} style={{ height: 80 }} />
          );
        })}
      </tbody>
    </table>
  );
};

export default LeftGridBody;
