import moment from "moment";

import * as constants from "../constants/index";
import environments from "../environments";

import { TaskScheduleType, TaskStatus, TaskSwitcherStatus, TaskSwitcherStatusType, TaskUtil } from "@/models/tasks";

export const changeTaskStatus = (item_id, task, status: TaskSwitcherStatusType) => {
  const endpoint = `${environments.API_BASE_URI}/tasks/status/update/${task.task_id}`;
  const result_end_date = status === TaskSwitcherStatus.DONE ? moment().format("YYYY-MM-DD").toString() : null;
  const ahead_days =
    status === TaskSwitcherStatus.SCHEDULE || status === TaskSwitcherStatus.CONTINUE
      ? 1
      : status === TaskSwitcherStatus.SCHEDULE_2 || status === TaskSwitcherStatus.CONTINUE_2
      ? 2
      : status === TaskSwitcherStatus.SCHEDULE_3 || status === TaskSwitcherStatus.CONTINUE_3
      ? 3
      : null;

  const payload = {
    valid_flg: status !== TaskSwitcherStatus.NO_PROCESS,
    schedule_type:
      status === TaskSwitcherStatus.NO_PROCESS || status === TaskSwitcherStatus.DONE
        ? TaskScheduleType.EMPTY
        : TaskUtil.scheduleTypeBySwitcherStatus(status) ?? task.schedule_type,
    status: status === TaskSwitcherStatus.DONE ? TaskStatus.COMPLETE : TaskStatus.INCOMPLETE,
    result_end_date,
    ahead_days,
    timestamp: task.timestamp,
  };

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(payload),
    callbacks: {
      begin: () => {
        return {
          type: constants.COMMON_BEGIN_CHANGE_TASK_STATUS,
          payload: {
            item_id,
            task_id: task.task_id,
          },
        };
      },
      success: (response) => {
        return {
          type: constants.COMMON_END_CHANGE_TASK_STATUS,
          payload: {
            item_id,
            ahead_days, // TODO Remove it
            task: response,
          },
        };
      },
      error: (response) => {
        return {
          type: constants.COMMON_ERROR_CHANGE_TASK_STATUS,
          payload: {
            item_id,
            task_id: task.task_id,
            response,
          },
        };
      },
    },
  };
};

// TODO actionを分けたほうがいい
export const downloadAttachmentFile = (kind, file_id, callback, options?: { detailFlg?: boolean }) => {
  const params = new URLSearchParams();
  if (options?.detailFlg) {
    params.set("detail_flg", "true");
  }
  const endpoint = `${environments.API_BASE_URI}/${kind}/files/${file_id}${
    0 < params.size ? "?" + params.toString() : ""
  }`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.COMMON_BEGIN_DOWNLOAD_ATTACHMENT_FILE,
        };
      },
      success: (response, filename) => {
        if (callback !== undefined) {
          callback(response, filename);
        }

        return {
          type: constants.COMMON_END_DOWNLOAD_ATTACHMENT_FILE,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const downloadErrorFiles = (kind, error_ids, callback, fallback) => {
  let endpoint;
  let param = "";

  if (error_ids) {
    error_ids.forEach((val) => {
      param = param + "request_ids=" + val + "&";
    });
  }

  endpoint = `${environments.API_BASE_URI}/${kind}/errors?${param.slice(0, -1)}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.COMMON_BEGIN_DOWNLOAD_ATTACHMENT_FILE,
        };
      },
      success: (response, filename) => {
        if (callback !== undefined) {
          callback(response, filename);
        }

        return {
          type: constants.COMMON_END_DOWNLOAD_ATTACHMENT_FILE,
        };
      },
      error: (response) => {
        fallback && fallback();

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const downloadSystemFiles = (kind, ids, filetype, callback, fallback) => {
  let endpoint;
  let param = "";

  if (ids) {
    ids.forEach((val) => {
      param = param + "system_ids=" + val + "&";
    });
  }

  endpoint = `${environments.API_BASE_URI}/${kind}/files?filetype=${filetype}&format=excel&${param.slice(0, -1)}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.COMMON_BEGIN_DOWNLOAD_ATTACHMENT_FILE,
        };
      },
      success: (response, fileName) => {
        if (callback !== undefined) {
          callback(response, fileName);
        }

        return {
          type: constants.COMMON_END_DOWNLOAD_ATTACHMENT_FILE,
        };
      },
      error: (response) => {
        fallback && fallback();

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};
