import clsx from "clsx";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { isValidRole } from "../../lib/roleChecker";

import { omitString } from "@/lib/common";
import { MatrixColumnKey } from "@/models/matrix";
import { RootState } from "@/reducers/types";

type Props = {
  index: number;
  isLastIndex: boolean;
  row: any;
  startPos: number;
  totalNum: number;
  displayRemarks: boolean;
  showItemEditor: (itemId?: string) => void;
  updateNote: (itemId, data, callback?) => void;
  updateRemark: (itemId, data, callback?) => void;
  updateDelivery: (itemId, data, callback?) => void;
  updateAttention: (itemId, data, callback?) => void;
  showAlert: (title, messages) => void;
  setTitleSubmenu: (info: { x; y; index; item; canCopy; canDelete; canBulkOff; canSort }) => void;
  clearProcessSubmenu: () => void;
};

const LeftGridRow: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { configColumns } = useSelector((state: RootState) => state.matrix);
  const show = useCallback((target): boolean => !configColumns || configColumns[target], [configColumns]);

  const [editCommentId, setEditCommentId] = useState(null);
  const [editRemarkId, setEditRemarkId] = useState(null);

  const commentTextArea = useRef<HTMLTextAreaElement>();
  const remarkTextArea = useRef<HTMLTextAreaElement>();

  const focusNote = useMemo(
    () =>
      _.debounce(() => {
        commentTextArea.current?.focus();
      }, 100),
    []
  );

  const focusRemark = useMemo(
    () =>
      _.debounce(() => {
        remarkTextArea.current?.focus();
      }, 100),
    []
  );

  useEffect(() => {
    props.row.item_id === editCommentId && focusNote();
    props.displayRemarks && focusRemark();
  }, [props.displayRemarks, props.row, editCommentId]);

  const beginEditComment = (id) => {
    setEditCommentId(id);
  };

  const endEditComment = (comment, row) => {
    if (100 < (comment ?? "").length) {
      const { showAlert } = props;
      showAlert(t("error"), [t("note") + t("is_too_long")]);
      commentTextArea.current?.focus();

      return;
    }

    const data = {
      note: comment,
      timestamp: row.timestamp,
    };

    props.updateNote(editCommentId, data, () => setEditCommentId(null));
  };

  const beginEditRemark = (id) => {
    setEditRemarkId(id);
  };

  const endEditRemark = (remark, row) => {
    if (100 < (remark ?? "").length) {
      const { showAlert } = props;
      showAlert(t("error"), [t("remarks") + t("is_too_long")]);
      remarkTextArea.current?.focus();

      return;
    }

    const data = {
      remark,
      timestamp: row.timestamp,
    };

    props.updateRemark(editRemarkId, data, () => setEditRemarkId(null));
  };

  const omitText = (text) => {
    if (text && text.length > 40) {
      return `${text.substr(0, 40)}...`;
    }

    return text;
  };

  const handleClickDelivery = (itemId, row) => {
    const data = {
      delivery_flg: !row.delivery_flg,
      timestamp: row.timestamp,
    };

    props.updateDelivery(itemId, data);
  };

  const handleClickAttention = (itemId, row) => {
    const data = {
      attention_flg: !row.attention_flg,
      timestamp: row.timestamp,
    };

    props.updateAttention(itemId, data);
  };

  const { row, index, startPos } = props;
  const canCopy = isValidRole(row.roles, 2);
  const canEdit = isValidRole(row.roles, 0);
  const canDelete = isValidRole(row.roles, 3);
  const canBulkOff = isValidRole(row.roles, 4);
  const canAttentionUpdate = isValidRole(row.roles, 1);

  const handleClickSubmenu = (e) => {
    props.clearProcessSubmenu();
    const menuHeight = 140;
    const canSort = 1 < props.totalNum;
    const menuLength = [canCopy, canDelete, canBulkOff, canSort].filter((v) => !!v).length;
    if (menuLength) {
      props.setTitleSubmenu({
        x: e.clientX + 5,
        y:
          window.innerHeight < e.clientY + menuHeight && 2 < menuLength
            ? window.innerHeight - menuHeight
            : e.clientY - 20,
        index: props.index,
        item: row,
        canCopy,
        canDelete,
        canBulkOff,
        canSort,
      });
    }
    e.stopPropagation();
  };

  return (
    <tr>
      {show(MatrixColumnKey.No) && (
        <td
          className={clsx(
            "col-no",
            row.attention_flg ? "cell-yellow" : "",
            canAttentionUpdate ? "grid-cell-clickable" : ""
          )}
        >
          <div
            className="grid-cell-wrap"
            onClick={() => {
              if (canAttentionUpdate) {
                handleClickAttention(row.item_id, row);
              }
            }}
          >
            <div className="grid-cell-con">
              <div className="grid-cell-inner">{startPos + index}</div>
            </div>
          </div>
        </td>
      )}
      {show(MatrixColumnKey.DeliveryFlg) && (
        <td
          className={clsx(
            "col-delivery-flg grid-cell-delivery",
            row.delivery_flg ? "is-on" : "is-off",
            canAttentionUpdate ? "grid-cell-clickable" : ""
          )}
          onClick={() => {
            if (canAttentionUpdate) {
              handleClickDelivery(row.item_id, row);
            }
          }}
        >
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner">
                <i className="grid-btn-delivery" />
              </div>
            </div>
          </div>
        </td>
      )}
      {show(MatrixColumnKey.ItemComplete) && (
        <td className="col-item-complete">
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner">{row.item_complete ? "●" : ""}</div>
            </div>
          </div>
        </td>
      )}
      {show(MatrixColumnKey.Totaltest) && (
        <td className="col-totaltest">
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner">{row.totaltest ? "●" : ""}</div>
            </div>
          </div>
        </td>
      )}
      {show(MatrixColumnKey.Area) && (
        <td className="col-area">
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner txt-left p-5">{row.area_name}</div>
            </div>
          </div>
        </td>
      )}
      {show(MatrixColumnKey.Device) && (
        <td className="col-device">
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner txt-left p-5">{row.device_name}</div>
            </div>
          </div>
        </td>
      )}
      {show(MatrixColumnKey.PrimaryCharge) && (
        <td className="col-primary-charge">
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner txt-left p-5">{row.primary_charge_name}</div>
            </div>
          </div>
        </td>
      )}
      {show(MatrixColumnKey.DocumentNo) && (
        <td className="col-document-no">
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner txt-left p-5">{row.document_no}</div>
            </div>
          </div>
        </td>
      )}
      <td
        className={clsx("col-item grid-item_name", canEdit ? "grid-cell-clickable" : "")}
        onClick={() => canEdit && props.showItemEditor(row.item_id)}
        onContextMenu={(e) => {
          if (!(canCopy || canDelete || canBulkOff)) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        <div className="grid-cell-wrap" style={{ overflow: "visible" }}>
          {canCopy || canDelete || canBulkOff ? (
            <React.Fragment>
              <div className="grid-cell-con">
                <div className="grid-cell-inner txt-left p-5">
                  {omitString(row.item_name, 35)}
                  <div className="grid-icon-submenu-wrapper" onClick={handleClickSubmenu}>
                    <button className="grid-icon grid-icon-submenu icon-submenu" />
                  </div>
                  {row.file_status && <span className="grid-icon grid-icon-information icon-attach_file"></span>}
                  {canEdit && <button className="grid-icon grid-icon-edit icon-mode_edit"></button>}
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div className="grid-cell-con">
              <div className="grid-cell-inner txt-left p-5">
                {omitString(row.item_name, 35)}
                {row.file_status && <span className="grid-icon grid-icon-information icon-attach_file"></span>}
                {canEdit && <button className="grid-icon grid-icon-edit icon-mode_edit"></button>}
              </div>
            </div>
          )}
        </div>
      </td>
      {show(MatrixColumnKey.Regulation) && (
        <td className="col-regulation">
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner txt-left p-5">{row.regulation}</div>
            </div>
          </div>
        </td>
      )}
      {show(MatrixColumnKey.Systems) && (
        <td className="col-systems">
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner txt-left p-5">
                <ul>
                  {row &&
                    row.systems &&
                    row.systems.map((system, index) => {
                      return <li key={index}>{system.system_name}</li>;
                    })}
                </ul>
              </div>
            </div>
          </div>
        </td>
      )}
      {show(MatrixColumnKey.Note) &&
        (row.item_id === editCommentId ? (
          <td className="col-note no-hover">
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner">
                  <textarea
                    className="grid-textarea txt-left p-5"
                    ref={commentTextArea}
                    onBlur={(e) => endEditComment(e.target.value, row)}
                    defaultValue={row.note}
                  ></textarea>
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            className={clsx("col-note", canAttentionUpdate ? "grid-cell-clickable" : "")}
            onClick={() => {
              if (canAttentionUpdate) {
                beginEditComment(row.item_id);
              }
            }}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner txt-left p-5">
                  {omitText(row.note)}
                  {canAttentionUpdate && <button className="grid-icon grid-icon-edit icon-mode_edit"></button>}
                </div>
              </div>
            </div>
          </td>
        ))}
      {props.displayRemarks &&
        (row.item_id === editRemarkId ? (
          <td style={{ minWidth: 115, maxWidth: 115 }} className="no-hover">
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner">
                  <textarea
                    className="grid-textarea txt-left p-5"
                    ref={remarkTextArea}
                    onBlur={(e) => endEditRemark(e.target.value, row)}
                    defaultValue={row.remark}
                  ></textarea>
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            style={{ minWidth: 115, maxWidth: 115 }}
            className={canAttentionUpdate ? "grid-cell-clickable" : ""}
            onClick={() => {
              if (canAttentionUpdate) {
                beginEditRemark(row.item_id);
              }
            }}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner txt-left p-5">
                  {omitText(row.remark)}
                  {canAttentionUpdate && <button className="grid-icon grid-icon-edit icon-mode_edit"></button>}
                </div>
              </div>
            </div>
          </td>
        ))}
      {show(MatrixColumnKey.User) && (
        <td className="col-user">
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner txt-left p-5">{row.user_name}</div>
            </div>
          </div>
        </td>
      )}
    </tr>
  );
};

export default withTranslation()(LeftGridRow);
