import { connect } from "react-redux";

import actions from "../../actions/index";
import InformationViewer from "../../components/home/InformationViewer";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchItem: (informationId, callback) => dispatch(actions.information.fetchItem(informationId, callback)),
    deleteItem: (informationId, callback, handleCancel) =>
      dispatch(actions.information.deleteItem(informationId, callback, handleCancel)),
    download: (kind, fileId, callback) => dispatch(actions.common.downloadAttachmentFile(kind, fileId, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InformationViewer);
