import PropTypes from "prop-types";
import React, { Component } from "react";
import { createPortal } from "react-dom";
import Carousel, { Modal as Lightbox, ModalGateway } from "react-images";

import * as util from "../lib/common";

class FileItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLightbox: false,
      showPdfModal: false,
      objectUrl: "",
    };

    this.handleCloseLightbox = this.handleCloseLightbox.bind(this);
    this.handleClosePdfModal = this.handleClosePdfModal.bind(this);
    this.handleOpenLightbox = this.handleOpenLightbox.bind(this);
    this.handleOpenPdfModal = this.handleOpenPdfModal.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  handleOpenLightbox() {
    this.setState({
      showLightbox: true,
    });
  }

  handleOpenPdfModal() {
    this.setState({
      showPdfModal: true,
    });
  }

  handleDownload({ showModal = false, format }: { showModal?: boolean; format?: string }) {
    const { kind, file, onDownload } = this.props;

    onDownload(kind, file.file_id, (blob) => {
      const objectUrl = URL.createObjectURL(blob);
      this.setState({ objectUrl }, () => {
        if (showModal) {
          if (format === "img") {
            this.handleOpenLightbox();
          }
          if (format === "pdf") {
            this.handleOpenPdfModal();
          }
        } else {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, file.file_name);
          } else {
            const evt = util.createClickEvent();
            this.btnDownload.dispatchEvent(evt);
          }
        }
      });
    });
  }

  handleCloseLightbox() {
    this.setState({ showLightbox: false });
  }

  handleClosePdfModal() {
    this.setState({ showPdfModal: false });
  }

  render() {
    const { file, readOnly, onDelete, disabled, additionalFilenameClass, additionalImageAreaClass } = this.props;
    let img = null;
    let format = "";

    if (file.file_name.indexOf(".pdf") >= 0) {
      img = <img src="img/icon_pdf.svg" alt="PDF" className="icon" />;
      format = "pdf";
    } else if (file.file_name.indexOf(".xls") >= 0 || file.file_name.indexOf(".xlm") >= 0) {
      img = <img src="img/icon_xls.svg" alt="Excel" className="icon" />;
      format = "xls";
    } else if (file.file_name.indexOf(".jpg") >= 0) {
      img = <img src="img/icon_jpg.svg" alt="Image" className="icon" />;
      format = "img";
    } else if (file.file_name.indexOf(".jpeg") >= 0) {
      img = <img src="img/icon_jpg.svg" alt="Image" className="icon" />;
      format = "img";
    } else if (file.file_name.indexOf(".png") >= 0) {
      img = <img src="img/icon_png.svg" alt="Image" className="icon" />;
      format = "img";
    }

    const isModalPreviewTarget = ["img", "pdf"].includes(format);

    return (
      <React.Fragment>
        <li>
          <a
            className="d-n"
            download={this.props.file.file_name}
            href={this.state.objectUrl}
            ref={(node) => (this.btnDownload = node)}
          >
            download
          </a>
          {file.temp && (file.progress < 100 || !file.temp_file_id) && (
            <span className="progress-container">
              <span className="progress-value" style={{ width: `${file.progress}%` }}></span>
            </span>
          )}
          {file.temp && file.progress === 100 && file.temp_file_id && (
            <React.Fragment>
              <span className={`file-name ${additionalFilenameClass || ""}`}>{file.file_name}</span>

              {disabled ? (
                <span className="icon icon-delete"></span>
              ) : (
                <span className="icon icon-delete" onClick={() => onDelete(file.temp_file_id, true)}></span>
              )}
            </React.Fragment>
          )}
          {!file.temp && (
            <React.Fragment>
              <span className={`img-area ${additionalImageAreaClass || ""}`}>{img}</span>
              <span className={`file-name ${additionalFilenameClass || ""}`}>
                <a onClick={() => this.handleDownload({ showModal: isModalPreviewTarget, format })}>{file.file_name}</a>
              </span>
              {!readOnly && (
                <span
                  disabled={disabled}
                  className="icon icon-delete"
                  onClick={() => onDelete(file.file_id, false)}
                ></span>
              )}
            </React.Fragment>
          )}
        </li>
        <ModalGateway>
          {this.state.showLightbox ? (
            <Lightbox
              onClose={this.handleCloseLightbox}
              styles={{
                blanket: (base, state) => ({ ...base, zIndex: 99999 }),
                positioner: (base, state) => ({ ...base, zIndex: 99999 }),
                dialog: (base, state) => ({ ...base, zIndex: 100000 }),
              }}
            >
              <Carousel
                views={[{ src: this.state.objectUrl }]}
                styles={{
                  footerCount: (base, state) => ({ ...base, display: "none" }),
                }}
              />
            </Lightbox>
          ) : null}
        </ModalGateway>

        {this.state.showPdfModal
          ? createPortal(
              <div className="preview-modal">
                <div className="preview-modal-overlay" onClick={this.handleClosePdfModal}></div>
                <button type="button" className="preview-modal-close-btn" onClick={this.handleClosePdfModal}>
                  <i className="preview-modal-close-btn-icon" />
                </button>
                <div className="preview-modal-body">
                  <iframe src={this.state.objectUrl} title={file.file_name} width="100%" height="100%" />
                </div>
              </div>,
              document.body
            )
          : null}
      </React.Fragment>
    );
  }
}

FileItem.defaultProps = {
  readOnly: true,
  disabled: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function -- 暫定処置でdisableしている
  onDelete: () => {},
};

FileItem.proptypes = {
  additionalFilenameClass: PropTypes.string,
  additionalImageAreaClass: PropTypes.string,
  kind: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  file: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  onDownload: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

export default FileItem;
