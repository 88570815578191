import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Carousel, { Modal as Lightbox, ModalGateway } from "react-images";
import validator from "validator";

import { ACCEPT_UPLOAD_EXTENSIONS } from "../../constants";
import FileItem from "../FileItem";
import Modal from "../Modal";
import { SubmitButton } from "@/components/common/SubmitButton";

class InformationEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      information_id: 0,
      subject: "",
      text: "",
      files: [],
      sending: false,
      error: {},
      showLightbox: false,
      lightboxSrc: "",
      timestamp: null,
    };

    this.handleChangeSubject = this.handleChangeSubject.bind(this);
    this.handleChangeText = this.handleChangeText.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleOpenLightbox = this.handleOpenLightbox.bind(this);
    this.handleCloseLightbox = this.handleCloseLightbox.bind(this);
    this.handleRemoveFile = this.handleRemoveFile.bind(this);
    this.handleChangeFile = this.handleChangeFile.bind(this);
    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.handleUploadProgress = this.handleUploadProgress.bind(this);
    this.handleUploadComplete = this.handleUploadComplete.bind(this);
    this.handleUploadError = this.handleUploadError.bind(this);
  }

  componentDidMount() {
    this.props.setUnloadAlert();
  }

  componentWillUnmount() {
    this.props.clearUnloadAlert();
  }

  // eslint-disable-next-line react/no-deprecated -- 暫定処置でdisableしている
  componentWillReceiveProps(nextProps) {
    const data = nextProps.data;

    if (data && this.state.information_id === 0) {
      const { information_id, subject, text, timestamp } = data;
      const files = data.files.map((f) => {
        f.mode = 0;

        return f;
      });

      this.setState({
        information_id,
        subject,
        text,
        files,
        timestamp,
      });
    }
  }

  handleChangeSubject(e) {
    this.setState({ subject: e.target.value });
  }

  handleChangeText(e) {
    this.setState({ text: e.target.value });
  }

  handleSave() {
    if (this.state.sending) return;
    const error = {};
    const { information_id, text } = this.state;
    const subject = this.state.subject ? this.state.subject.trim() : "";
    const { t } = this.props;

    if (validator.isEmpty(subject)) {
      error.subject = `${t("title")}${t("is_required")}`;
    } else if (subject.length > 100) {
      error.subject = `${t("title")}${t("is_too_long")}`;
    }

    if (validator.isEmpty(text)) {
      error.text = `${t("body")}${t("is_required")}`;
    }

    if (Object.keys(error).length > 0) {
      this.setState({ error });
    } else {
      const payload = {
        subject,
        text,
        files: this.state.files
          // アップロード未完了ファイルはPOSTしない
          .filter((f) => {
            if (f.mode === 1 && f.progress && f.progress < 100) {
              return false;
            }

            return true;
          })
          .map((f) => {
            const file = {
              file_name: f.file_name,
              mode: f.mode,
            };

            if (f.temp) {
              file.temp_file_id = f.temp_file_id;
            } else {
              file.file_id = f.file_id;
              file.timestamp = f.timestamp;
            }

            return file;
          }),
      };

      if (this.state.timestamp !== null) {
        payload.timestamp = this.state.timestamp;
      }

      this.setState({ sending: true });

      if (information_id === 0) {
        this.props.create(payload, this.props.closeHandler, () => this.setState({ sending: false }));
      } else {
        this.props.update(information_id, payload, this.props.closeHandler, () => this.setState({ sending: false }));
      }
    }
  }

  handleOpenLightbox(lightboxSrc) {
    this.setState({
      showLightbox: true,
      lightboxSrc,
    });
  }

  handleCloseLightbox() {
    this.setState({ showLightbox: false });
  }

  handleRemoveFile(fileId, isTemp) {
    const { t } = this.props;
    this.props.removeFile(
      t("delete_file"),
      [t("delete_file_alert")],
      () => {
        this.setState({
          files: this.state.files
            .map((f) => {
              if (isTemp && f.temp_file_id === fileId) {
                f.mode = -1;
              } else if (!isTemp && f.file_id === fileId) {
                f.mode = 2;
              }

              return f;
            })
            .filter((f) => f.mode !== -1),
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function -- 暫定処置でdisableしている
      () => {}
    );
  }

  handleFileSelect() {
    this.file.click();
  }

  handleUploadProgress(key, e) {
    this.setState({
      files: this.state.files.map((file) => {
        if (file.temp && file.key === key) {
          file.progress = (e.loaded / e.total) * 100;
        }

        return file;
      }),
    });
  }

  handleUploadComplete(key, e) {
    this.setState({
      files: this.state.files.map((file) => {
        if (file.temp && file.key === key) {
          file.progress = 100;
          file.temp_file_id = e.temp_file_id;
        }

        return file;
      }),
    });
  }

  handleUploadError(key, e) {
    const { t } = this.props;

    const msgs = e.response.errors?.map((m) => m.err_message).filter((m) => m);
    if (msgs) {
      this.props.showAlert(t("error"), msgs);
    }

    const file = this.state.files.find((f) => f.key === key);

    if (file) {
      this.setState({ files: this.state.files.filter((f) => f.key !== key) });
    }
  }

  handleChangeFile(e) {
    let files = [];

    Array.prototype.forEach.call(e.target.files, (file) => {
      files = [
        ...files,
        {
          key: Symbol(),
          file_name: file.name,
          file_format: file.type,
          mode: 1,
          temp: true,
          progress: 0,
          done: false,
          data: file,
        },
      ];
    });

    if (files.some((v) => 85 < v.file_name.length)) {
      const { t } = this.props;
      this.setState({ error: { ...this.state.error, file: t("file_name_is_too_long") } });
      return;
    } else {
      this.setState({ error: { ...this.state.error, file: null } });
    }

    files.forEach((file) => {
      const formData = new FormData();

      formData.append("target", 1);
      formData.append("uploadfile", file.data);
      this.props.upload(
        formData,
        (e) => this.handleUploadProgress(file.key, e),
        (e) => this.handleUploadComplete(file.key, e),
        (e) => this.handleUploadError(file.key, e)
      );
    });

    this.setState({
      files: [...files, ...this.state.files],
    });
  }

  render() {
    const { closeHandler, fetching, download, fileUpload, t } = this.props;
    const { subject, text, error } = this.state;
    const uploading = this.state.files.some((f) => f.mode === 1 && !f.temp_file_id);

    return (
      <React.Fragment>
        <Modal title={t("edit_board")} closeHandler={closeHandler} loading={fetching}>
          <div className="modal-body w-710 clearfix">
            <div className="modal-body-left w-360">
              <div className="form-row">
                <div className="form-group">
                  <span className="form-label txt-bold">{t("board_subject_shorten")}</span>
                  <input
                    data-test-id="text-information-subject"
                    type="text"
                    className="form-control w-310"
                    value={subject}
                    onChange={this.handleChangeSubject}
                  />
                </div>
                {error.subject && (
                  <div className="form-error w-310 ml-35 ta-u">
                    <p className="form-error-message">{error.subject}</p>
                  </div>
                )}
              </div>
              <div className="form-row">
                <div className="form-group">
                  <span className="form-label txt-bold">{t("body")}</span>
                  <textarea
                    data-test-id="text-information-content"
                    rows="13"
                    className="form-control w-310"
                    value={text}
                    onChange={this.handleChangeText}
                  />
                </div>
                {error.text && (
                  <div className="form-error w-310 ml-35 ta-u">
                    <p className="form-error-message">{error.text}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-body-right w-290">
              <div className="form-row" style={{ marginBottom: "7px" }}>
                <div className="form-group">
                  <span className="form-label txt-bold">{t("upload_file")}</span>
                  <button
                    disabled={!fileUpload}
                    data-test-id="button-information-file_select"
                    className="btn btn-light-blue w-150"
                    onClick={this.handleFileSelect}
                  >
                    {t("browze_file")}
                  </button>
                  <form ref={(node) => (this.form = node)} style={{ display: "none" }} encType={"multipart/form-data"}>
                    <input
                      type="file"
                      name="uploadFiles"
                      accept={ACCEPT_UPLOAD_EXTENSIONS.join(",")}
                      multiple={true}
                      onChange={this.handleChangeFile}
                      onClick={(e) => (e.target.value = null)}
                      ref={(node) => (this.file = node)}
                    />
                  </form>
                </div>
              </div>
              {error.file && (
                <div className="form-error" style={{ textAlign: "left" }}>
                  <p className="form-error-message">{error.file}</p>
                </div>
              )}
              {this.state.files.length > 0 && (
                <div className="file-upload-box">
                  <ul className="form-file-list">
                    {this.state.files
                      .filter((f) => f.mode !== 2)
                      .map((file, index) => (
                        <FileItem
                          key={index}
                          file={file}
                          kind="information"
                          readOnly={false}
                          onDelete={this.handleRemoveFile}
                          onDownload={download}
                          additionalFilenameClass="mw-160"
                        />
                      ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className={"modal-footer"}>
            <button
              data-test-id="button-information-cancel"
              type="button"
              className="btn btn-gray"
              onClick={closeHandler}
            >
              {t("cancel")}
            </button>
            <SubmitButton
              data-test-id="button-information-save"
              onClick={this.handleSave}
              loading={this.state.sending}
              disabled={uploading}
            />
          </div>
        </Modal>
        <ModalGateway>
          {this.state.showLightbox ? (
            <Lightbox
              onClose={this.handleCloseLightbox}
              styles={{
                blanket: (base, state) => ({ ...base, zIndex: 99999 }),
                positioner: (base, state) => ({ ...base, zIndex: 99999 }),
                dialog: (base, state) => ({ ...base, zIndex: 100000 }),
              }}
            >
              <Carousel
                views={[{ src: this.state.lightboxSrc }]}
                styles={{
                  footerCount: (base, state) => ({ ...base, display: "none" }),
                }}
              />
            </Lightbox>
          ) : null}
        </ModalGateway>
      </React.Fragment>
    );
  }
}

InformationEditor.defaultProps = {
  data: null,
};

InformationEditor.propTypes = {
  data: PropTypes.object,
  fetching: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,
  download: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  setUnloadAlert: PropTypes.func.isRequired,
  clearUnloadAlert: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
};

export default withTranslation()(InformationEditor);
