import * as constants from "../constants/index";
import environments from "../environments";
import storageManager from "../lib/storageManager";

import { HistoryColumnType, ScheduleHistory, ScheduleUrl } from "@/models/scheduleChiba";
import { Timestamp } from "@/sx-layout/models";

export const setValidRequired = (valid) => {
  return {
    type: constants.SCHEDULE_CHIBA_SET_VALID_REQUIRED,
    payload: valid,
  };
};

export const changeArea = (items) => ({
  type: constants.SCHEDULE_CHIBA_CHANGE_AREA,
  payload: items,
});

export const changeDevice = (items) => ({
  type: constants.SCHEDULE_CHIBA_CHANGE_DEVICE,
  payload: items,
});

export const changeCategory = (items) => ({
  type: constants.SCHEDULE_CHIBA_CHANGE_CATEGORY,
  payload: items,
});

export const changeFacilityManagement = (items) => ({
  type: constants.SCHEDULE_CHIBA_CHANGE_FACILITY_MANAGEMENT,
  payload: items,
});

export const changeConstructionManagement = (items) => ({
  type: constants.SCHEDULE_CHIBA_CHANGE_CONSTRUCTION_MANAGEMENT,
  payload: items,
});

export const changePrimaryCharge = (items) => ({
  type: constants.SCHEDULE_CHIBA_CHANGE_PRIMARY_CHARGE,
  payload: items,
});

export const changeOther = (items) => ({
  type: constants.SCHEDULE_CHIBA_CHANGE_OTHER,
  payload: items,
});

export const changeConstructionCompany = (items) => ({
  type: constants.SCHEDULE_CHIBA_CHANGE_CONSTRUCTION_COMPANY,
  payload: items,
});

export const changeField1 = (items) => ({
  type: constants.SCHEDULE_CHIBA_CHANGE_FIELD1,
  payload: items,
});

export const changeUser = (items) => ({
  type: constants.SCHEDULE_CHIBA_CHANGE_USER,
  payload: items,
});

export const changeGroup = (items) => ({
  type: constants.SCHEDULE_CHIBA_CHANGE_GROUP,
  payload: items,
});

export const changeApprove = (items) => ({
  type: constants.SCHEDULE_CHIBA_CHANGE_APPROVE_USER,
  payload: items,
});

export const changeCheckpoint1 = (items) => ({ type: constants.SCHEDULE_CHIBA_CHANGE_CHECKPOINT1, payload: items });

export const changeCheckpoint2 = (items) => ({ type: constants.SCHEDULE_CHIBA_CHANGE_CHECKPOINT2, payload: items });

export const changeCheckpoint3 = (items) => ({ type: constants.SCHEDULE_CHIBA_CHANGE_CHECKPOINT3, payload: items });

export const changeCheckpoint4 = (items) => ({ type: constants.SCHEDULE_CHIBA_CHANGE_CHECKPOINT4, payload: items });

export const changeCheckpoint5 = (items) => ({ type: constants.SCHEDULE_CHIBA_CHANGE_CHECKPOINT5, payload: items });

export const changeCheckpoint6 = (items) => ({ type: constants.SCHEDULE_CHIBA_CHANGE_CHECKPOINT6, payload: items });

export const changeFilter = (items) => ({ type: constants.SCHEDULE_CHIBA_CHANGE_FILTER, payload: items });

export const changeRequestUser = (items) => ({ type: constants.SCHEDULE_CHIBA_CHANGE_REQUEST_USER, payload: items });

export const changeField5 = (items) => ({ type: constants.SCHEDULE_CHIBA_CHANGE_FIELD5, payload: items });

export const changeScheduleDate = (scheduleDate) => ({
  type: constants.SCHEDULE_CHIBA_CHANGE_SCHEDULE_DATE,
  payload: scheduleDate,
});

export const clearSearch = (target) => ({
  type: constants.SCHEDULE_CHIBA_CLEAR_SEARCH,
  payload: target,
});

export const revertLocalCondition = () => ({
  type: constants.SCHEDULE_CHIBA_REVERT_CONDITION,
});

export const setValidated = (validated) => ({
  type: constants.SCHEDULE_CHIBA_SET_VALIDATED,
  payload: validated,
});

export const search = (params, callback) => {
  let endpoint = `${environments.API_BASE_URI}/list/schedules_oirec_chiba`;
  const query = new URLSearchParams();

  if (params && params.areaIds && params.areaIds.length > 0) {
    params.areaIds.forEach((param) => query.append("area_id", param));
  }

  if (params && params.deviceIds && params.deviceIds.length > 0) {
    params.deviceIds.forEach((param) => query.append("device_id", param));
  }

  if (params && params.categoryIds && params.categoryIds.length > 0) {
    params.categoryIds.forEach((param) => query.append("category_id", param));
  }

  if (params && params.facilityManagementIds && params.facilityManagementIds.length > 0) {
    params.facilityManagementIds.forEach((param) => query.append("facility_management_id", param));
  }

  if (params && params.constructionManagementIds && params.constructionManagementIds.length > 0) {
    params.constructionManagementIds.forEach((param) => query.append("construction_management_id", param));
  }

  if (params && params.primaryChargeIds && params.primaryChargeIds.length > 0) {
    params.primaryChargeIds.forEach((param) => query.append("primary_charge_id", param));
  }

  if (params && params.otherIds && params.otherIds.length > 0) {
    params.otherIds.forEach((param) => query.append("other_id", param));
  }

  if (params && params.companyParams && params.companyParams.length > 0) {
    params.companyParams.forEach((param) => query.append("company_id", param));
  }

  if (params && params.field1Params && params.field1Params.length > 0) {
    params.field1Params.forEach((param) => query.append("field1", param));
  }

  if (params && params.userParams && params.userParams.length > 0) {
    params.userParams.forEach((param) => query.append("user_id", param));
  }

  if (params && params.groupParams && params.groupParams.length > 0) {
    params.groupParams.forEach((param) => query.append("group_id", param));
  }

  if (params && params.approveParams && params.approveParams.length > 0) {
    params.approveParams.forEach((param) => query.append("approve_user_id", param));
  }
  if (params?.checkpoint1) {
    query.append("checkpoint1", params?.checkpoint1);
  }
  if (params?.checkpoint2) {
    query.append("checkpoint2", params?.checkpoint2);
  }
  if (params?.checkpoint3) {
    query.append("checkpoint3", params?.checkpoint3);
  }
  if (params?.checkpoint4) {
    query.append("checkpoint4", params?.checkpoint4);
  }
  if (params?.checkpoint5) {
    query.append("checkpoint5", params?.checkpoint5);
  }
  if (params?.checkpoint6) {
    query.append("checkpoint6", params?.checkpoint6);
  }
  if (params?.field5) {
    query.append("field5", params?.field5);
  }
  if (params?.filter) {
    query.append("filter", params?.filter);
  }
  if (params?.requestUser) {
    query.append("request_user_id", params?.requestUser);
  }

  if (params && params.scheduleDate) {
    query.set("schedule_date", params.scheduleDate.replace(/\//g, "-"));
  }

  if (params && params.filter && params.filter.length > 0) {
    params.filter.forEach((param) => query.append("filter", param));
  }

  if (params && params.limit) {
    query.set("limit", params.limit);
  }

  if (params && params.start) {
    query.set("start", params.start);
  }

  storageManager.setConstructionItem("scheduleChibaSearchParams", JSON.stringify(params));
  endpoint = `${endpoint}?${query.toString()}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.SCHEDULE_CHIBA_BEGIN_SEARCH,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.SCHEDULE_CHIBA_END_SEARCH,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const download = (params = null, filetype, callback, fallback) => {
  let endpoint = `${environments.API_BASE_URI}/list/schedule_oirec_chiba/files`;

  const query = new URLSearchParams();

  query.set("filetype", filetype);
  query.set("format", "excel");

  if (params && params.areaIds && params.areaIds.length > 0) {
    params.areaIds.forEach((param) => query.append("area_id", param));
  }

  if (params && params.deviceIds && params.deviceIds.length > 0) {
    params.deviceIds.forEach((param) => query.append("device_id", param));
  }

  if (params && params.categoryIds && params.categoryIds.length > 0) {
    params.categoryIds.forEach((param) => query.append("category_id", param));
  }

  if (params && params.facilityManagementIds && params.facilityManagementIds.length > 0) {
    params.facilityManagementIds.forEach((param) => query.append("facility_management_id", param));
  }

  if (params && params.constructionManagementIds && params.constructionManagementIds.length > 0) {
    params.constructionManagementIds.forEach((param) => query.append("construction_management_id", param));
  }

  if (params && params.primaryChargeIds && params.primaryChargeIds.length > 0) {
    params.primaryChargeIds.forEach((param) => query.append("primary_charge_id", param));
  }

  if (params && params.otherIds && params.otherIds.length > 0) {
    params.otherIds.forEach((param) => query.append("other_id", param));
  }

  if (params && params.companyParams && params.companyParams.length > 0) {
    params.companyParams.forEach((param) => query.append("company_id", param));
  }

  if (params && params.field1Params && params.field1Params.length > 0) {
    params.field1Params.forEach((param) => query.append("field1", param));
  }

  if (params && params.userParams && params.userParams.length > 0) {
    params.userParams.forEach((param) => query.append("user_id", param));
  }

  if (params && params.groupParams && params.groupParams.length > 0) {
    params.groupParams.forEach((param) => query.append("group_id", param));
  }

  if (params && params.approveParams && params.approveParams.length > 0) {
    params.approveParams.forEach((param) => query.append("approve_user_id", param));
  }
  if (params?.checkpoint1) {
    query.append("checkpoint1", params?.checkpoint1);
  }
  if (params?.checkpoint2) {
    query.append("checkpoint2", params?.checkpoint2);
  }
  if (params?.checkpoint3) {
    query.append("checkpoint3", params?.checkpoint3);
  }
  if (params?.checkpoint4) {
    query.append("checkpoint4", params?.checkpoint4);
  }
  if (params?.checkpoint5) {
    query.append("checkpoint5", params?.checkpoint5);
  }
  if (params?.checkpoint6) {
    query.append("checkpoint6", params?.checkpoint6);
  }
  if (params?.field5) {
    query.append("field5", params?.field5);
  }
  if (params?.filter) {
    query.append("filter", params?.filter);
  }
  if (params?.requestUser) {
    query.append("request_user_id", params?.requestUser);
  }
  if (params && params.scheduleDate) {
    query.set("schedule_date", params.scheduleDate.replace(/\//g, "-"));
  }

  if (params && params.filter && params.filter.length > 0) {
    params.filter.forEach((param) => query.append("filter", param));
  }

  if (params && params.limit) {
    query.set("limit", params.limit);
  }

  if (params && params.start) {
    query.set("start", params.start);
  }

  endpoint = `${endpoint}?${query.toString()}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      success: (response, fileName) => {
        if (callback !== undefined) {
          callback(response, fileName);
        }

        return {
          type: constants.SCHEDULE_CHIBA_END_DOWNLOAD,
        };
      },
      error: (response) => {
        fallback && fallback();

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateItem = (data, callback, failedCallback) => {
  return (dispatch, getState) => {
    const { schedule_id, target, value, timestamp } = data;
    const body =
      target == "request"
        ? { schedule_id, request_user_id: value, timestamp }
        : target == "approve"
        ? { schedule_id, approve_user_id: value, timestamp }
        : { schedule_id, target, value, timestamp };

    const action = {
      type: constants.APP_CALL_API,
      endpoint: `${environments.API_BASE_URI}/list/schedules_oirec_chiba/${
        target == "request" || target == "approve" ? target : "update"
      }/${schedule_id}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(body),
      callbacks: {
        success: (response) => {
          if (callback !== undefined) {
            callback(response);
          }

          return {
            type: constants.SCHEDULE_CHIBA_END_UPDATE_ITEM,
            payload: response,
          };
        },
        error: (response) => {
          if (failedCallback !== undefined) {
            failedCallback(response);
          }

          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    };

    dispatch(action);
  };
};

export const updateItemBundle = (data, callback, failedCallback) => {
  return (dispatch, getState) => {
    const { target, value, note, scheduleIds, start } = data;

    const update = {
      target,
      value:
        target == "note1" ||
        target == "note2" ||
        target == "note3" ||
        target == "special_process_text" ||
        target == "protective_equipment_text" ||
        target == "work_start_hour" ||
        target == "work_end_hour"
          ? note
          : value,
      schedule_id: scheduleIds,
      start: start,
    };
    const request = { request_user_id: value, schedule_id: scheduleIds, start };
    const approve = { approve_user_id: value, schedule_id: scheduleIds, start };
    const body = target == "request" ? request : target == "approve" ? approve : update;

    const action = {
      type: constants.APP_CALL_API,
      endpoint: `${environments.API_BASE_URI}/list/schedules_oirec_chiba/${
        target == "request" || target == "approve" ? target : "update"
      }`,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(body),
      callbacks: {
        success: (response) => {
          if (callback !== undefined) {
            callback(response);
          }

          return {
            type: constants.SCHEDULE_CHIBA_END_UPDATE_ITEM_BUNDLE,
            payload: response,
          };
        },
        error: (response) => {
          if (failedCallback !== undefined) {
            failedCallback(response);
          }

          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    };

    dispatch(action);
  };
};

export const getOptions = (callback) => {
  const endpoint = `${environments.API_BASE_URI}/list/schedules_oirec_chiba/options`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.SCHEDULE_CHIBA_BEGIN_GET_OPTIONS,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.SCHEDULE_CHIBA_END_GET_OPTIONS,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const createSchedule = (data, callback, failedCallback) => {
  return (dispatch, getState) => {
    const { categoryId, scheduleDate } = data;
    const action = {
      type: constants.APP_CALL_API,
      endpoint: `${environments.API_BASE_URI}/list/schedules_oirec_chiba/create`,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        category_id: categoryId,
        schedule_date: scheduleDate,
      }),
      callbacks: {
        success: (response) => {
          if (callback !== undefined) {
            callback(response);
          }

          return {
            type: constants.SCHEDULE_CHIBA_END_CREATE_SCHEDULE,
            payload: response,
          };
        },
        error: (response) => {
          if (failedCallback !== undefined) {
            failedCallback(response);
          }

          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    };

    dispatch(action);
  };
};

export const permitTableDownload = (scheduleDate, callback, fallback) => {
  const endpoint =
    `${environments.API_BASE_URI}/list/schedule_oirec_chiba/files/permit_table?schedule_date=` + scheduleDate;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      success: (response, filename) => {
        if (callback !== undefined) {
          callback(response, filename);
        }

        return {
          type: constants.SCHEDULE_CHIBA_PERMIT_TABLE_DOWNLOAD,
        };
      },
      error: (response) => {
        fallback && fallback();

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const getScheduleUrls = (itemId: number, callback: (res: { list: ScheduleUrl[]; roles: [number] }) => void) => {
  const endpoint = `${environments.API_BASE_URI}/list/schedules_oirec_chiba/item_urls?item_id=${itemId}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      success: (res) => {
        // TODO: 設計書と異なるのでとりあえずフォーマットしておく
        const response: { list: ScheduleUrl[]; roles: [number] } = {
          list: res.list.map((v) => ({
            ...v,
            item_id: v.item_id ?? v.itemId,
            item_url_id: v.item_url_id ?? v.itemUrlId,
          })),
          roles: res.roles,
        };
        callback?.(response);

        return {
          type: constants.SCHEDULE_CHIBA_END_GET_SCHEDULE_URL,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const createScheduleUrl = (
  data: { item_id: number; label: string; url: string },
  callback: (res: ScheduleUrl) => void,
  failedCallback?: (_) => void
) => {
  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/list/schedules_oirec_chiba/item_urls/create`,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(data),
    callbacks: {
      success: (res) => {
        const response: ScheduleUrl = {
          ...res,
          item_id: res.item_id ?? res.itemId,
          item_url_id: res.item_url_id ?? res.itemUrlId,
        };
        callback?.(response);

        return {
          type: constants.SCHEDULE_CHIBA_END_CREATE_SCHEDULE_URL,
          payload: response,
        };
      },
      error: (response) => {
        failedCallback?.(response);

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateScheduleUrl = (
  itemUrlId: number,
  data: Pick<ScheduleUrl, "label" | "url" | "timestamp">,
  callback: (res: ScheduleUrl) => void,
  failedCallback?: (_) => void
) => {
  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/list/schedules_oirec_chiba/item_urls/update/${itemUrlId}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(data),
    callbacks: {
      success: (res) => {
        const response: ScheduleUrl = {
          ...res,
          item_id: res.item_id ?? res.itemId,
          item_url_id: res.item_url_id ?? res.itemUrlId,
        };
        callback?.(response);

        return {
          type: constants.SCHEDULE_CHIBA_END_UPDATE_SCHEDULE_URL,
          payload: response,
        };
      },
      error: (response) => {
        failedCallback?.(response);

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const deleteScheduleUrl = (
  itemUrlId: number,
  data: { timestamp: Timestamp },
  callback: () => void,
  failedCallback?: (_) => void
) => {
  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/list/schedules_oirec_chiba/item_urls/delete/${itemUrlId}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(data),
    callbacks: {
      success: () => {
        callback?.();

        return {
          type: constants.SCHEDULE_CHIBA_END_DELETE_SCHEDULE_URL,
        };
      },
      error: (response) => {
        failedCallback?.(response);

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
    meta: {
      confirm: {
        messages: ["link_will_be_removed", "", "is_it_ok"],
      },
    },
  };
};

export const updateScheduleUrlNum = (itemId: number, urlNum: number) => {
  return {
    type: constants.SCHEDULE_CHIBA_END_EDIT_SCHEDULE_URL,
    payload: { itemId, urlNum },
  };
};

export const getScheduleHistories = (
  scheduleId: number,
  targetColumn: HistoryColumnType,
  callback: (res: { total_num: number; list: ScheduleHistory[] }) => void
) => {
  const endpoint = `${environments.API_BASE_URI}/list/schedules_oirec_chiba/${scheduleId}/${targetColumn}/histories`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      success: (response) => {
        callback?.(response);

        return {
          type: constants.SCHEDULE_CHIBA_END_GET_HISTORIES,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const setConditions = (conditions) => {
  return {
    type: constants.SCHEDULE_CHIBA_SET_CONDITIONS,
    payload: conditions,
  };
};
