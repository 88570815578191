import Immutable from "immutable";

import restoreState from "../lib/restoreState";

import * as util from "./common";

export const getMarkText = (validFlg, status, scheduleType) => {
  const markNumber = getMarkNumber(validFlg, status, scheduleType);

  return util.getStatusText(markNumber);
};

export const getMarkNumber = (validFlg, status, scheduleType) => {
  let result = 0;

  if (validFlg > 0) {
    if (status === 0) {
      switch (scheduleType) {
        case 0:
          result = 1;

          break;
        case 1:
          result = 2;

          break;
        case 2:
          result = 3;

          break;
        case 3:
          result = 4;

          break;
        case 4:
          result = 5;

          break;
      }
    } else if (status === 1) {
      result = 8;
    }
  }

  return result;
};

export const getPropsByStatusNumber = (statusNumber) => {
  switch (statusNumber) {
    case 0:
      return { valid_flg: 0, status: 0, schedule_type: 0 };
    case 1:
      return { valid_flg: 1, status: 0, schedule_type: 0 };
    case 2:
      return { valid_flg: 1, status: 0, schedule_type: 1 };
    case 3:
      return { valid_flg: 1, status: 0, schedule_type: 2 };
    case 4:
      return { valid_flg: 1, status: 0, schedule_type: 3 };
    case 5:
      return { valid_flg: 1, status: 0, schedule_type: 4 };
    case 8:
      return { valid_flg: 1, status: 1, schedule_type: 0 };
  }
};

export const createEmptyRow = (srcRow) => {
  let newRow = Immutable.fromJS(srcRow);

  newRow = newRow.merge({
    no: 0,
    systems: [],
    device_id: 0,
    document_no: "",
    totaltest: false,
    item_id: 0,
    error: false,
    primary_charge_id: 0,
    construction_management_id: 0,
    other_id: 0,
    regulation: "",
    facility_management_id: 0,
    area_id: 0,
    //tasks: [],
    user_id: 0,
    note: "",
    processing: false,
    device_name: "",
    file_status: false,
    timestamp: null,
    delivery_flg: false,
    item_name: "",
    weight: 0,
    item_complete: false,
    construction_management_name: "",
    primary_charge_name: "",
    other_name: "",
    facility_management_name: "",
    group_id: 0,
    category_id: 0,
    //roles: [],
    area_name: "",
    sort: 0,
    user_name: "",
  });

  newRow = newRow.update("tasks", (tasks) =>
    tasks.map((task) =>
      task.merge({
        schedule_type: 0,
        document_no: "",
        process_id: 0,
        checkpoints: [],
        result_start_date: "",
        display_status: "",
        user_id: 0,
        warning_messages: [],
        background_color: 0,
        status: 0,
        schedule_start_date: "",
        comments: [],
        result_end_date: "",
        file_status: false,
        task_id: 0,
        timestamp: null,
        schedule_end_date: "",
        group_id: 0,
        //roles: [],
        warning_flg: false,
        valid_flg: false,
        user_name: "",
      })
    )
  );

  return newRow.toJS();
};

export const getQueryByParams = (params) => {
  const query = new URLSearchParams();

  if (params && params.categoryId) {
    query.set("category_id", params.categoryId);
  }
  if (params && params.primaryChargeId) {
    query.set("primary_charge_id", params.primaryChargeId);
  }

  if (params && params.areaIds.length > 0) {
    params.areaIds.forEach((param) => query.append("area_id", param));
  }

  if (params && params.deviceIds.length > 0) {
    params.deviceIds.forEach((param) => query.append("device_id", param));
  }

  if (params && params.facilityManagementIds.length > 0) {
    params.facilityManagementIds.forEach((param) => query.append("facility_management_id", param));
  }

  if (params && params.constructionManagementIds.length > 0) {
    params.constructionManagementIds.forEach((param) => query.append("construction_management_id", param));
  }

  if (params && params.primaryChargeIds?.length > 0) {
    params.primaryChargeIds.forEach((param) => query.append("primary_charge_id", param));
  }

  if (params && params.otherIds.length > 0) {
    params.otherIds.forEach((param) => query.append("other_id", param));
  }

  if (params && params.systemIds.length > 0) {
    params.systemIds.forEach((param) => query.append("system_id", param));
  }

  if (params && params.userIds.length > 0) {
    params.userIds.forEach((param) => query.append("user_id", param));
  }

  if (params && params.groupIds.length > 0) {
    params.groupIds.forEach((param) => query.append("group_id", param));
  }

  if (params && params.companyIds.length > 0) {
    params.companyIds.forEach((param) => query.append("company_id", param));
  }

  if (params && params.processMiddleClassIds.length > 0) {
    params.processMiddleClassIds.forEach((param) => query.append("process_middle_class_id", param));
  }

  if (params && params.itemText) {
    query.set("item_text", params.itemText);
  }

  if (params && params.processText) {
    query.set("process_text", params.processText);
  }

  if (params && params.regulation) {
    query.set("regulation", params.regulation);
  }

  if (params && params.filters) {
    params.filters.forEach((filter) => query.append("filter", filter));
  }

  if (params && params.limit) {
    query.set("limit", params.limit);
  }

  if (params && params.start) {
    query.set("start", params.start);
  }

  if (params && params.filetype) {
    query.set("filetype", params.filetype);
  }

  if (params && params.format) {
    query.set("format", params.format);
  }

  if (params && params.fromScheduleDate) {
    query.set("from_schedule_date", params.fromScheduleDate);
  }

  if (params && params.toScheduleDate) {
    query.set("to_schedule_date", params.toScheduleDate);
  }

  const urlState = restoreState();

  if (urlState.item_id) {
    query.set("item_id", urlState.item_id);
  }

  return query;
};

export const removeItemIdParam = () => {
  const hash = location.hash;
  const pattern = /[&?]?item_id=[1-9]+/g;
  const newHash = hash.replace(pattern, "");

  history.replaceState(undefined, undefined, newHash);
};

export const removeProcessIdParam = () => {
  const hash = location.hash;
  const pattern = /[&?]?process_id=[1-9]+/g;
  const newHash = hash.replace(pattern, "");

  history.replaceState(undefined, undefined, newHash);
};
