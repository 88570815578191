import clsx from "clsx";
import React, { FC } from "react";

type Props = {
  title: string;
  children: React.ReactNode;
  className?: string;
  titleClassName?: string;
  display?: boolean;
};

export const FormGroup: FC<Props> = ({ title, children, className, titleClassName, display }) => {
  return (
    <div className={clsx("flex items-center", display === false ? "hidden" : "", className)}>
      <span className={clsx("whitespace-nowrap", titleClassName ?? "w-[90px] min-w-[90px]", "text-end px-[8px]")}>
        {title}
      </span>
      <div className="flex-grow">{children}</div>
    </div>
  );
};
