import PropTypes from "prop-types";
import React, { Component, createRef } from "react";
import { withTranslation } from "react-i18next";

import ApprovalSearchPanel from "../../containers/list/ApprovalSearchPanelContainer";
import TaskEditorContainer from "../../containers/list/TaskEditorContainer";
import { addHeightResizeListener, removeHeightResizeListener, getTableBodyHeight } from "../../lib/common";
import restoreState from "../../lib/restoreState";
import storageManager from "../../lib/storageManager";
import StatusSwitcher from "../common/StatusSwitcher";

import ApprovalGridBody from "./ApprovalGridBody";
import ApprovalGridHeader from "./ApprovalGridHeader";

import "react-datepicker/dist/react-datepicker.css";

import { FooterPager } from "@/components/common/FooterPager";
import { ApprovalSearchParams } from "@/models/approval";
import { TaskSwitcherStatus } from "@/models/tasks";

class Approval extends Component {
  searchBox;
  constructor(props) {
    super(props);
    this.searchBox = createRef();
    this.state = {
      showSearch: true,
      showTable: false,
      showTaskEditor: false,
      currentRowIndex: -1,
      limit: 20,
      start: 1,
      end: false,
      isEmpty: false,
      editingItem: null,
      tableBodyMaxHeight: window.innerHeight - 420,
      linkageInfo: null,
      currentPage: 1,
      maxPage: 0,
      requestParam: {},
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleSearchBoxHeightChange = this.handleSearchBoxHeightChange.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.fillList = this.fillList.bind(this);
  }

  componentDidMount() {
    this.props.changeSwitcherStatus(-1);
    this.resizeTimer = 0;
    window.addEventListener("resize", this.handleResize);

    const urlState = restoreState();
    const { validated } = this.props;
    if (
      validated &&
      (storageManager.getConstructionItem("approvalSearchParams") !== null || (urlState && urlState.hasQuery))
    ) {
      this.handleSearch();
    }

    setTimeout(() => {
      this.handleSearchBoxHeightChange();
      addHeightResizeListener(this.searchBox.current, this.handleSearchBoxHeightChange);
    }, 300);
  }

  componentDidUpdate(prevProps) {
    const { validated } = this.props;
    if (!validated) {
      return;
    }
    const urlState = restoreState();
    const notFirst =
      storageManager.getConstructionItem("approvalSearchParams") !== null || (urlState && urlState.hasQuery);
    if (!prevProps.validated && validated && notFirst) {
      this.handleSearch();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    removeHeightResizeListener(this.searchBox.current);

    this.props.revertLocalCondition();
  }

  handleSearchBoxHeightChange() {
    const maxHeight = getTableBodyHeight("approval", this.searchBox.current, this.theader);
    this.setState({ tableBodyMaxHeight: maxHeight });
  }

  handleResize() {
    if (this.resizeTimer > 0) {
      clearTimeout(this.resizeTimer);
    }

    const callback = () => {
      this.handleSearchBoxHeightChange();
      this.fillList();
    };

    this.resizeTimer = setTimeout(callback, 200);
  }

  toggleSearch() {
    const callback = () => {
      this.handleSearchBoxHeightChange();
      addHeightResizeListener(this.searchBox.current, this.handleSearchBoxHeightChange);
      this.fillList();
    };
    this.setState({ showSearch: !this.state.showSearch }, () => setTimeout(callback, 100));
  }

  showTable(callback) {
    this.setState({ showTable: true }, () => {
      if (callback !== undefined) {
        // コールバックにしてもsetState直後はDOMに反映していない
        // ことがあるため、時間差で実行する
        setTimeout(callback, 100);
      }
    });
  }

  showTaskEditor(item, linkageInfo) {
    this.setState({
      editingItem: item,
      showTaskEditor: true,
      linkageInfo: linkageInfo,
    });
  }

  hideTaskEditor() {
    this.setState({ showTaskEditor: false });
  }

  fillList() {
    const { showTable, end } = this.state;

    if (!showTable || end) {
      return;
    }
  }

  handleSearch(option?: { params?: ApprovalSearchParams }) {
    this.setState({ isEmpty: true });

    const {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      processMajorClassIds,
      processMiddleClassIds,
      companyIds,
      scheduleEndDate,
      checkpointIds,
      itemText,
      processText,
      filter,
      search,
      setConditions,
    } = this.props;

    const params = {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      processMajorClassIds,
      processMiddleClassIds,
      companyIds,
      scheduleEndDate,
      checkpointIds,
      itemText,
      processText,
      filter,
      start: 1,
      limit: this.state.limit,
      ...option?.params,
    };

    option?.params && setConditions(option.params);

    search(params, (data) => {
      this.setState(
        {
          start: data.length + 1,
          end: data.length < this.state.limit,
          isEmpty: data.length === 0,
          maxPage: Math.ceil(data.total_num / this.state.limit),
          currentPage: Math.ceil(params.start / this.state.limit),
          requestParam: { ...params },
        },
        () => {
          this.showTable(this.fillList);
        }
      );
    });
  }

  handleSearchPager(startPos = 1) {
    this.setState({ isEmpty: true });

    const { search } = this.props;

    const params = {
      ...this.state.requestParam,
      start: startPos,
      limit: this.state.limit,
    };

    search(params, (data) => {
      this.setState(
        {
          start: data.length + 1,
          end: data.length < this.state.limit,
          isEmpty: data.length === 0,
          maxPage: Math.ceil(data.total_num / this.state.limit),
          currentPage: Math.ceil(params.start / this.state.limit),
        },
        () => {
          this.showTable(this.fillList);
        }
      );
    });
  }

  handleNextPage() {
    const { currentPage, limit, maxPage } = this.state;
    if (currentPage < maxPage) {
      const startPos = 1 + currentPage * limit;
      this.handleSearchPager(startPos);
    }
  }

  handlePrevPage() {
    const { currentPage, limit } = this.state;
    if (currentPage > 1) {
      const startPos = 1 + (currentPage - 2) * limit;
      this.handleSearchPager(startPos);
    }
  }

  handleJumpPage(page) {
    const startPos = 1 + (page - 1) * this.state.limit;
    this.handleSearchPager(startPos);
  }

  handleClear() {
    this.props.clearSearch();
    this.setState({ start: 1, showTable: false });

    const params = {
      areaIds: [],
      deviceIds: [],
      categoryId: 0,
      facilityManagementIds: [],
      constructionManagementIds: [],
      primaryChargeIds: [],
      otherIds: [],
      processMajorClassIds: [],
      processMiddleClassIds: [],
      companyIds: [],
      scheduleEndDate: "",
      checkpointIds: [],
      itemText: "",
      processText: "",
      filter: [],
      start: 1,
      limit: this.state.limit,
    };

    this.props.search(params, (data) => {
      this.setState(
        {
          start: data.length + 1,
          end: data.length < this.state.limit,
          isEmpty: data.length === 0,
          maxPage: Math.ceil(data.total_num / this.state.limit),
          currentPage: Math.ceil(params.start / this.state.limit),
          requestParam: { ...params },
        },
        () => {
          this.showTable(this.fillList);
        }
      );
    });
  }

  render() {
    const titleClassName = `toggle icon-keyboard_arrow_up ${!this.state.showSearch ? "closed" : ""}`;
    const {
      masters,
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      companyIds,
      processMajorClassIds,
      processMiddleClassIds,
      scheduleEndDate,
      checkpointIds,
      itemText,
      processText,
      filter,
      changeArea,
      changeDevice,
      changeFacilityManagement,
      changeCategory,
      changeConstructionManagement,
      changePrimaryCharge,
      changeOther,
      changeProcessMajorClass,
      changeProcessMiddleClass,
      changeCompany,
      changeScheduleEndDate,
      changeCheckpoint,
      changeItemText,
      changeProcessText,
      changeFilter,
      t,
      items,
      fetching,
      linkageTasks,
    } = this.props;

    const { currentPage, limit } = this.state;

    return (
      <div>
        <div className="contents">
          <div className="inner">
            <h1 className="page-ttl">
              {t("plan_approval_list")}
              <span
                data-test-id="button-approval-toggle-search"
                className={titleClassName}
                onClick={() => this.toggleSearch()}
              ></span>
            </h1>
            {this.state.showSearch && (
              <ApprovalSearchPanel
                ref={this.searchBox}
                handleSearch={this.handleSearch}
                handleClear={this.handleClear}
              />
            )}
            {this.state.showTable && !this.state.isEmpty && !!items && !!items.length && (
              <div className="tbl-top-area clearfix">
                <div className="tbl-top-left">
                  <StatusSwitcher
                    value={this.props.switcherStatus}
                    onChange={(value) => this.props.changeSwitcherStatus(value)}
                  />
                </div>
              </div>
            )}
            <div className={`tbl-approvals ${fetching ? "loading loading--list" : ""}`}>
              {this.state.isEmpty || !items || !items.length
                ? this.state.showTable && !fetching && <p className="empty-message">{t("no_data")}</p>
                : this.state.showTable && (
                    <React.Fragment>
                      <div
                        className="tbl-area tbl-approvals-header tbl-head-adjusted"
                        ref={(node) => (this.theader = node)}
                      >
                        <ApprovalGridHeader userRole={masters.userRole} />
                      </div>
                      <div
                        className={`tbl-area tbl-approvals-body ${!this.state.showSearch ? "is-large" : ""} ${
                          this.props.switcherStatus === TaskSwitcherStatus.OFF
                            ? ""
                            : `cursor-${this.props.switcherStatus}`
                        }`}
                        ref={(node) => (this.tbody = node)}
                        style={{ maxHeight: this.state.tableBodyMaxHeight }}
                      >
                        <ApprovalGridBody
                          no={currentPage * limit - limit + 1}
                          rows={items}
                          switcherStatus={this.props.switcherStatus}
                          showTaskEditor={(item, linkageInfo) => this.showTaskEditor(item, linkageInfo)}
                          linkageTasks={linkageTasks}
                        />
                      </div>
                    </React.Fragment>
                  )}
            </div>
          </div>
        </div>
        {this.state.maxPage > 0 && (
          <FooterPager
            currentPage={this.state.currentPage}
            maxPage={this.state.maxPage}
            onPrev={() => this.handlePrevPage()}
            onNext={() => this.handleNextPage()}
            onJump={(page) => this.handleJumpPage(page)}
          />
        )}
        {this.state.showTaskEditor && (
          <TaskEditorContainer
            taskId={this.state.editingItem.task_id}
            itemName={this.state.editingItem.item_name}
            processName={this.state.editingItem.process_name}
            categoryId={this.state.editingItem.category_id}
            closeHandler={() => this.hideTaskEditor()}
            saveHandler={() => this.hideTaskEditor()}
            linkageInfo={this.state.linkageInfo}
          />
        )}
      </div>
    );
  }
}

Approval.propTypes = {
  areaIds: PropTypes.array.isRequired,
  deviceIds: PropTypes.array.isRequired,
  categoryId: PropTypes.number.isRequired,
  facilityManagementIds: PropTypes.array.isRequired,
  constructionManagementIds: PropTypes.array.isRequired,
  primaryChargeIds: PropTypes.array.isRequired,
  otherIds: PropTypes.array.isRequired,
  processMajorClassIds: PropTypes.array.isRequired,
  scheduleEndDate: PropTypes.string.isRequired,
  checkpointIds: PropTypes.array.isRequired,
  itemText: PropTypes.string.isRequired,
  processText: PropTypes.string.isRequired,
  filter: PropTypes.array.isRequired,
  masters: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  changeArea: PropTypes.func.isRequired,
  changeDevice: PropTypes.func.isRequired,
  changeFacilityManagement: PropTypes.func.isRequired,
  changeCategory: PropTypes.func.isRequired,
  changeConstructionManagement: PropTypes.func.isRequired,
  changePrimaryCharge: PropTypes.func.isRequired,
  changeOther: PropTypes.func.isRequired,
  changeProcessMajorClass: PropTypes.func.isRequired,
  changeScheduleEndDate: PropTypes.func.isRequired,
  changeCheckpoint: PropTypes.func.isRequired,
  changeItemText: PropTypes.func.isRequired,
  changeProcessText: PropTypes.func.isRequired,
  changeFilter: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  changeSwitcherStatus: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  revertLocalCondition: PropTypes.func.isRequired,
  validated: PropTypes.bool.isRequired,
  setConditions: PropTypes.func.isRequired,
};

export default withTranslation()(Approval);
