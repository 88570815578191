import { connect } from "react-redux";

import actions from "../../actions";
import LeftGridRowChiba from "../../components/matrix/LeftGridRowChiba";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateItem: (itemId, data, callback) => dispatch(actions.matrix.updateItem(itemId, data, callback)),
    updateNote: (itemId, data, callback) => dispatch(actions.matrix.updateNote(itemId, data, callback)),
    updateDelivery: (itemId, data, callback) => dispatch(actions.matrix.updateDelivery(itemId, data, callback)),
    updateAttention: (itemId, data, callback) => dispatch(actions.matrix.updateAttention(itemId, data, callback)),
    showAlert: (title, messages) =>
      dispatch(actions.app.showAlert(title, messages, () => dispatch(actions.app.hideAlert()))),
    setTitleSubmenu: (info: { x; y; index; item; canCopy; canDelete; canBulkOff; canSort }) =>
      dispatch(actions.matrix.setTitleSubmenu(info)),
    clearProcessSubmenu: () => dispatch(actions.matrix.clearProcessSubmenu()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftGridRowChiba);
