import * as constants from "../constants/index";
import environments from "../environments";
import storageManager from "../lib/storageManager";

import { ImportSearchParams } from "@/models/import";

export const changeCategory = (categoryId) => ({
  type: constants.IMPORT_CHANGE_CATEGORY,
  payload: categoryId !== null ? categoryId : 0,
});

export const changeFilename = (fileName) => ({
  type: constants.IMPORT_CHANGE_FILENAME,
  payload: fileName,
});

export const changeStatus = (statusId) => ({
  type: constants.IMPORT_CHANGE_STATUS,
  payload: statusId,
});

export const changeDateFrom = (import_start_date) => ({
  type: constants.IMPORT_CHANGE_DATE_FROM,
  payload: import_start_date,
});

export const changeDateTo = (import_end_date) => ({
  type: constants.IMPORT_CHANGE_DATE_TO,
  payload: import_end_date,
});

export const changeCompany = (companyId) => ({
  type: constants.IMPORT_CHANGE_COMPANY,
  payload: companyId,
});

export const changeUser = (userId) => ({
  type: constants.IMPORT_CHANGE_USER,
  payload: userId !== null ? userId : 0,
});

export const changeFiletype = (fileType) => ({
  type: constants.IMPORT_CHANGE_FILETYPE,
  payload: fileType,
});

export const importFiletype = (impFileType) => ({
  type: constants.IMPORT_IMPORT_FILETYPE,
  payload: impFileType,
});

export const setConditions = (params: ImportSearchParams) => ({
  type: constants.IMPORT_SET_CONDITIONS,
  payload: params,
});

export const resetSearch = () => {
  storageManager.removeConstructionItem("importSearchParams");

  return {
    type: constants.IMPORT_RESET_SEARCH,
  };
};

export const setValidated = (validated) => ({
  type: constants.IMPORT_SET_VALIDATED,
  payload: validated,
});

export const revertLocalCondition = () => ({
  type: constants.IMPORT_REVERT_CONDITION,
});

export const toggleDownloading = (downloading = false) => ({
  type: constants.IMPORT_TOGGLE_DOWNLOADING,
  payload: downloading,
});

export const fetchItems = () => {
  const endpoint = `${environments.API_BASE_URI}/import/files`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.IMPORT_BEGIN_FETCH_ITEMS,
        };
      },
      success: (response) => {
        return {
          type: constants.IMPORT_END_FETCH_ITEMS,
          payload: response.import,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const searchItems = (params = null, callback) => {
  let endpoint = `${environments.API_BASE_URI}/import/files`;
  const query = new URLSearchParams();

  if (params && params.categoryId) {
    query.set("category_id", params.categoryId);
  }

  if (params && params.fileName) {
    query.set("file_name", params.fileName);
  }

  if (params && params.statusId != null) {
    if (params.statusId > -1) {
      query.set("status", params.statusId);
    }
  }

  if (params && params.import_start_date) {
    query.set("import_date_from", params.import_start_date);
  }

  if (params && params.import_end_date) {
    query.set("import_date_to", params.import_end_date);
  }

  if (params && params.userId) {
    query.set("user_id", params.userId);
  }

  if (params && params.fileType) {
    query.set("file_type", params.fileType);
  }

  if (params && params.limit) {
    query.set("limit", params.limit);
  }

  if (params && params.start) {
    query.set("start", params.start);
  }

  if (query.toString() === "") {
    storageManager.removeConstructionItem("importSearchParams");
  } else {
    storageManager.setConstructionItem("importSearchParams", JSON.stringify(params));
    endpoint = `${endpoint}?${query.toString()}`;
  }

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.IMPORT_BEGIN_SEARCH_ITEMS,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.IMPORT_END_SEARCH_ITEMS,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const exportErrorFile = (params = null, callback) => {
  let endpoint = `${environments.API_BASE_URI}/import/errors`;
  const query = new URLSearchParams();

  if (params && params.request_id) {
    query.set("request_ids", params.request_id);
  }

  if (query.toString() === "") {
    storageManager.removeConstructionItem("importSearchParams");
  } else {
    storageManager.setConstructionItem("importSearchParams", JSON.stringify(params));
    endpoint = `${endpoint}?${query.toString()}`;
  }

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.IMPORT_END_ERRORS_ITEM,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const uploadFile = (data, onProgress, onSuccess, callback) => {
  const endpoint = `${environments.API_BASE_URI}/import/files/upload`;

  return {
    type: constants.APP_FILE_UPLOAD,
    endpoint,
    method: "POST",
    body: data,
    callbacks: {
      progress: (response) => {
        onProgress(response);
      },
      success: (response) => {
        onSuccess(response);

        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.IMPORT_END_UPLOAD_FILE,
          payload: response,
        };
      },
      error: (response, status) => {
        if (status === 400) {
          if (callback !== undefined) {
            let msg = response;

            if (response.errors && response.errors.length > 0) {
              msg = response.errors[0].err_message;
            }

            callback(msg, true);
          }

          return {
            type: constants.IMPORT_ERROR_400,
          };
        }

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const downloadFormatFiles = (filetype, callback) => {
  let endpoint;

  endpoint = `${environments.API_BASE_URI}/import/download?filetype=${filetype}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.COMMON_BEGIN_DOWNLOAD_ATTACHMENT_FILE,
        };
      },
      success: (response, filename) => {
        if (callback !== undefined) {
          callback(response, filename);
        }

        return {
          type: constants.COMMON_END_DOWNLOAD_ATTACHMENT_FILE,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};
