import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import FileItemAsset from "./FileItemAsset";

import { Asset } from "@/models/asset";

type Props = {
  item: Asset;
  handleDelete: (field, target, fileName) => void;
  download: (kind, fileId, callback) => void;
};

const AssetGridRow: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { item, handleDelete, download } = props;

  const regDate = new Date(item.insert_date);
  const regDateConv =
    regDate.getFullYear() +
    "/" +
    ("0" + (regDate.getMonth() + 1)).slice(-2) +
    "/" +
    ("0" + regDate.getDate()).slice(-2) +
    " " +
    ("0" + regDate.getHours()).slice(-2) +
    ":" +
    ("0" + regDate.getMinutes()).slice(-2) +
    ":" +
    ("0" + regDate.getSeconds()).slice(-2);
  const upload = [
    { id: 1, name: t("board") },
    { id: 2, name: t("title") },
    { id: 3, name: t("detail") },
  ];
  const uploadName = upload.filter((value) => value.id === item.upload_target)[0].name;
  const fileId =
    item.upload_target === 1
      ? item.information_file_id
      : item.upload_target === 2
      ? item.item_file_id
      : item.task_file_id;
  const kind = item.upload_target === 1 ? "information" : item.upload_target === 2 ? "item" : "task";
  const format = item.file_name.split(".").slice(-1)[0];

  return (
    <tr>
      <td className="w-120 txt-center">{uploadName}</td>
      {item.upload_target === 1 && <td colSpan="3">{item.title}</td>}
      {item.upload_target === 2 && (
        <React.Fragment>
          <td>{item.category_name}</td>
          <td>{item.item_name}</td>
          <td>{"-----"}</td>
        </React.Fragment>
      )}
      {item.upload_target === 3 && (
        <React.Fragment>
          <td>{item.category_name}</td>
          <td>{item.item_name}</td>
          <td>{item.process_name}</td>
        </React.Fragment>
      )}
      <td className="w-180 txt-center">{regDateConv}</td>
      <td className="w-110">{item.insert_user_name}</td>
      <FileItemAsset fileId={fileId} fileName={item.file_name} kind={kind} format={format} onDownload={download} />
      <td className="w-70 txt-center">
        {item.roles[0] === 1 && (
          <a onClick={() => handleDelete(fileId, item.upload_target, item.file_name)}>{t("remove")}</a>
        )}
      </td>
    </tr>
  );
};

export default AssetGridRow;
